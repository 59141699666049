import BgImg from "../../assets/images/background/page-header-bg-1-1.jpg";
import { Link } from "react-router-dom";

const ServicesBreadcumb = ({ Title, Breadcumb }) => {
  return (
    <div className="page-header">
      <div
        className="page-header__bg"
        style={{ backgroundImage: `url(${BgImg})` }}
      ></div>
      <div className="container">
        <ul className="thm-breadcrumb list-unstyled">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>{Breadcumb && Breadcumb}</li>
        </ul>
        <h2 className="page-header__title">{Title && Title}</h2>
      </div>
    </div>
  );
};

export default ServicesBreadcumb;
