import AT from "../../../assets/images/At.jpg";
import Breadcumb from "../../Breadcumb/Main";
import CDBCI from "../../../assets/images/CDBCI.jpg";
import CharityI from "../../../assets/images/CharityI.jpg";
import DI from "../../../assets/images/SecureD.jpg";
import FNT from "../../../assets/images/Fnt.jpg";
import HRFIEDI from "../../../assets/images/HRFIEDI.jpg";
import { Link } from "react-router-dom";
import MoneyI from "../../../assets/images/MoneyI.jpg";
import NFTI from "../../../assets/images/NftI.jpg";
import OwlCarousel from "react-owl-carousel";
import SDI2 from "../../../assets/images/projects/SDI2.jpg";
import SecureI from "../../../assets/images/SecureI.jpg";
import TC from "../../../assets/images/TokenI.jpg";
import W from "../../../assets/images/W.jpg";

const SDI = () => {
  const options = {
    loop: true,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    smartSpeed: 700,
    items: 1,
    margin: 0,
    nav: false,
    dots: false,
    responsive: {
      0: {
        items: 1,
        margin: 0,
      },
      576: {
        items: 2,
        margin: 30,
      },
      992: {
        items: 3,
        margin: 30,
      },
    },
  };
  return (
    <>
      <Breadcumb Title="Secure Digital Identity" Breadcumb="PROJECT" />
      <section className="project-details section-padding--bottom section-padding--top">
        <div className="container">
          <div className="project-details__image">
            <img src={SDI2} alt="" />
          </div>
          <div className="project-details__info">
            <div className="project-details__info__item">
              <h4 className="project-details__info__title">Client:</h4>
              <p className="project-details__info__text">PHQTI</p>
            </div>

            <div className="project-details__info__item">
              <h4 className="project-details__info__title">Category:</h4>
              <p className="project-details__info__text">
                <Link to="#">PHQSDI</Link> <Link to="#"></Link>
              </p>
            </div>
            <div className="project-details__info__item">
              <h4 className="project-details__info__title">Year:</h4>
              <p className="project-details__info__text">2023</p>
            </div>
            <div className="project-details__info__item">
              <h4 className="project-details__info__title">Value:</h4>
              <p className="project-details__info__text">USD 14,5000</p>
            </div>
            <div className="project-details__info__item">
              <div className="project-details__info__social">
                <Link to="#">
                  <i className="fab fa-twitter"></i>
                </Link>
                <Link to="#">
                  <i className="fab fa-facebook"></i>
                </Link>
                <Link to="#">
                  <i className="fab fa-instagram"></i>
                </Link>
              </div>
            </div>
          </div>
          <h3 className="project-details__title">
            Secure Digital Identity (SDI)
          </h3>
          <div className="project-details__content">
            <p>
              PHQ SDI is a single-point platform to create an ultra-secured
              digital identity for humans around the world. The system records
              biometrics (face recognition, thumb impression, eye scan), voice
              recording, self-video uploading, blood group, distinguishing
              marks, etc. All identity details will be uploaded in one single QR
              code. Options of sharing individual / all identity details. All
              identity records pass through the PHQ blockchain and the user gets
              a unique transaction ID &amp; Block hash for every identity added
              to the blockchain. Anyone can cross-check the identity
              transactions after putting the block hash or transaction ID in the
              search box of PHQ blockchain explorer{" "}
              <a href="https://explorer.phq.com" target="_blank">
                https://explorer.phq.com.{" "}
              </a>
              Every identity record is maintained on the PHQ blockchain and
              available to every connected / validator’s node. No gas fees are
              to be paid by the users as there is zero gas fees for any kind of
              transaction on PHQ Blockchain. Fastest SDI creation and sharing.
              Most user- friendly SDI-creating software/tool in the world. Do
              not have to remember tough passwords or private keys. Login
              through biometrics possible
            </p>
            <br />
            <h4 className="project-details__title" style={{ fontSize: "30px" }}>
              Process of creating the SDI
            </h4>
            <p style={{ marginBottom: "15px" }}>
              <span
                className="project-details__title "
                style={{ fontSize: "20px" }}
              >
                Signup ;{" "}
              </span>
              <span>
                {" "}
                Use your existing email as user name, choose a password, and re-
                enter the password. The verification email will go to your email
                entered in the username box. Verify your email.
              </span>
            </p>
            <p class="pl" style={{ marginBottom: "15px" }}>
              <span
                className="project-details__title"
                style={{ fontSize: "20px" }}
              >
                Login ;{" "}
              </span>
              <span>
                {" "}
                Enter your registered email. Enter password. Enter the OTP sent
                to your registered email id.
              </span>
            </p>
            <p class="pl" style={{ marginBottom: "15px" }}>
              <span
                className="project-details__title"
                style={{ fontSize: "20px" }}
              >
                Dashboard ;{" "}
              </span>
              <span>
                {" "}
                After secure login, the dashboard page will appear. Click Create
                Secure digital identity button. You will be directed to the
                information form. Fill in all the required information from
                ‘’name to distinguished mark’’. Further upload biometrics and
                other details. Add your address/other IDs issued by the
                government departments. Upload them one by one. The name of the
                added proof/certificate will appear. Send the
                proofs/certificates to government departments/entities for
                validation. Enter the Create button. A preview box will appear.
                Check preview details and if found to be ok, press confirms
                button.
              </span>
            </p>
            <p class="pl" style={{ marginBottom: "15px" }}>
              <span
                className="project-details__title"
                style={{ fontSize: "20px" }}
              >
                Blockchain response ;{" "}
              </span>
              <span>
                {" "}
                After you click on the confirm button, all the details will pass
                through the secured PHQ blockchain. Blockchain will generate
                block hash and transaction ID. The user’s profile will be
                generated. All the details in the form of a QR code will be
                created. The added certificates and government proofs will
                appear below the QR code.
              </span>
            </p>
            <p class="pl" style={{ marginBottom: "15px" }}>
              <span
                className="project-details__title"
                style={{ fontSize: "20px" }}
              >
                Share SDI ;{" "}
              </span>
              <span>
                {" "}
                Share the QR code if you wish to provide all your identity
                details. You also have the option of sharing any one/all the
                certificate/s
              </span>
            </p>
            <p
              class=""
              style={{
                marginTop: "30px",
                marginBottom: "15px",
                color: "black",
                fontSize: "20px",
              }}
            >
              BENEFITS OF PHQ SDI CREATION SYSTEM:
            </p>

            <ul className="project-details__list">
              <li>
                <i className="fa fa-check-circle"></i>
                Any individual can create SDI
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                Possible to use the PHQ SDI system worldwide
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                ID-adding features are included for all the countries
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                Easy onboarding for users
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                All transactions will be recorded on the PHQ blockchain
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                There is zero gas fee for any kind of transaction on the PHQ
                blockchain
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                One SDI QR code can be used in any kind of check-in
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                Users have a choice to share one multi-QR code or individual
                identities as per the need
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                World’s fastest and most unique kind of SDI system
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                Fastest SDI creation possible
              </li>
            </ul>
          </div>
        </div>
      </section>

      <div className="project-nav">
        <div className="container">
          <div className="project-nav__inner">
            <Link to="/token_creation_tool">
              <i className="icon-left-arrow"></i>
              Previous
            </Link>
            <Link to="/hrfied">
              Next
              <i className="icon-right-arrow"></i>
            </Link>
          </div>
        </div>
      </div>
      <section className="project-details section-padding--bottom footer-widget__newsletter mc-form">
        <center>
          <a href="#" target="_blank" className="thm-btn about-four__btn">
            <span>Whitepaper</span>
          </a>
        </center>
      </section>
      <section className="section-padding--top section-padding--bottom">
        <div className="container">
          <div className="section-title text-center">
            <p className="section-title__text">Other PHQ Products</p>
            <h2 className="section-title__title">
              Check What We Have <br /> Developed?
            </h2>
          </div>

          <OwlCarousel className="thm-owl__carousel" {...options}>
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={SecureI} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text">PHQ.SEMS.V1.2023</p>
                    <h3 className="project-card-one__title">
                      <Link to="/secured-email-system">
                        Secured Email System
                      </Link>
                    </h3>
                    <Link
                      to="/secured-email-system"
                      className="project-card-one__more"
                    >
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={TC} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text">PHQ.TCT.V1.2023</p>
                    <h3 className="project-card-one__title">
                      <Link to="/token_creation_tool">Token Creation Tool</Link>
                    </h3>
                    <Link
                      to="/token_creation_tool"
                      className="project-card-one__more"
                    >
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={FNT} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text">PHQ.FNT.V1.2023</p>
                    <h3 className="project-card-one__title">
                      <Link to="/fntasy">Fntasy Gaming</Link>
                    </h3>
                    <Link to="/fntasy" className="project-card-one__more">
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="item">
							<div className="project-card-one">
								<div className="project-card-one__image">
									<img src={DI} alt="" />
								</div>
								<div className="project-card-one__content">
									<div className="project-card-one__content__inner">
										<p className="project-card-one__text">PHQ.SDI.V1.2023</p>
										<h3 className="project-card-one__title"><Link to="/secure-digital-identity">Secured Digital Identity</Link></h3>
										<Link to="/secure-digital-identity" className="project-card-one__more">
											<i className="fa fa-angle-right"></i>
										</Link>
									</div>
								</div>
							</div>
						</div> */}
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={AT} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text">PHQ.ATS.V1.2023</p>
                    <h3 className="project-card-one__title">
                      <Link to="/asset_tokenization">
                        Asset Tokenization System
                      </Link>
                    </h3>
                    <Link
                      to="/asset_tokenization"
                      className="project-card-one__more"
                    >
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={HRFIEDI} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text"> PHQ.HRF.V1.2023</p>
                    <h3 className="project-card-one__title">
                      <Link to="/hrfied">Hrfied</Link>
                    </h3>
                    <Link to="/hrfied" className="project-card-one__more">
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={CDBCI} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text"> PHQ.CDBC.V1.2023</p>
                    <h3 className="project-card-one__title">
                      <Link to="/central_bank_digital_currency">
                        Central Bank Digital Currency
                      </Link>
                    </h3>
                    <Link
                      to="/central_bank_digital_currency"
                      className="project-card-one__more"
                    >
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={W} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text">
                      {" "}
                      PHQ.WALLET.V1.2023
                    </p>
                    <h3 className="project-card-one__title">
                      <Link to="/cryptocurrency-wallet">
                        Cryptocurrency Wallet
                      </Link>
                    </h3>
                    <Link
                      to="/cryptocurrency-wallet"
                      className="project-card-one__more"
                    >
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={MoneyI} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text"> PHQ.MTRN.V1.2023</p>
                    <h3 className="project-card-one__title">
                      <Link to="/money-transfer">Money Transfer</Link>
                    </h3>
                    <Link
                      to="/money-transfer"
                      className="project-card-one__more"
                    >
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={CharityI} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text">
                      {" "}
                      PHQ.Charity.V1.2023
                    </p>
                    <h3 className="project-card-one__title">
                      <Link to="/charity">Charity</Link>
                    </h3>
                    <Link to="/charity" className="project-card-one__more">
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={NFTI} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text"> PHQ.NFT.V1.2023</p>
                    <h3 className="project-card-one__title">
                      <Link to="/nft-market-place">NFT Marketplace</Link>
                    </h3>
                    <Link
                      to="/nft-market-place"
                      className="project-card-one__more"
                    >
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </OwlCarousel>
        </div>
      </section>
    </>
  );
};

export default SDI;
