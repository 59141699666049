import AT from "../../../assets/images/At.jpg";
import Breadcumb from "../../Breadcumb/Main";
import CDBCI from "../../../assets/images/CDBCI.jpg";
import CharityI from "../../../assets/images/CharityI.jpg";
import DI from "../../../assets/images/SecureD.jpg";
import FNT from "../../../assets/images/Fnt.jpg";
import HRFIEDI from "../../../assets/images/HRFIEDI.jpg";
import { Link } from "react-router-dom";
import MoneyI from "../../../assets/images/MoneyI.jpg";
import NFTI from "../../../assets/images/NftI.jpg";
import OwlCarousel from "react-owl-carousel";
import Sec4 from "../../../assets/images/Sec4.jpg";
import SecureI from "../../../assets/images/SecureI.jpg";
import TC from "../../../assets/images/TokenI.jpg";
import Token1 from "../../../assets/images/projects/Token1.jpg";
import W from "../../../assets/images/W.jpg";

const TokenCreationTool = () => {
  const options = {
    loop: true,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    smartSpeed: 700,
    items: 1,
    margin: 0,
    nav: false,
    dots: false,
    responsive: {
      0: {
        items: 1,
        margin: 0,
      },
      576: {
        items: 2,
        margin: 30,
      },
      992: {
        items: 3,
        margin: 30,
      },
    },
  };
  return (
    <>
      <Breadcumb Title="Token Creation Tool" Breadcumb="PROJECT" />
      <section className="project-details section-padding--bottom section-padding--top">
        <div className="container">
          {/* <div className="project-details__image">
            <img src={Token1} alt="" />
          </div> */}
          <div className="project-details__info">
            <div className="project-details__info__item">
              <h4 className="project-details__info__title">Client:</h4>
              <p className="project-details__info__text">PHQTI</p>
            </div>

            <div className="project-details__info__item">
              <h4 className="project-details__info__title">Category:</h4>
              <p className="project-details__info__text">
                <Link to="#">PHQTCT</Link>
                <Link to="#"></Link>
              </p>
            </div>
            <div className="project-details__info__item">
              <h4 className="project-details__info__title">Year:</h4>
              <p className="project-details__info__text">2023</p>
            </div>
            <div className="project-details__info__item">
              <h4 className="project-details__info__title">Value:</h4>
              <p className="project-details__info__text">USD 1,020,000 </p>
            </div>
            <div className="project-details__info__item">
              <div className="project-details__info__social">
                <Link to="#">
                  <i className="fab fa-twitter"></i>
                </Link>
                <Link to="#">
                  <i className="fab fa-facebook"></i>
                </Link>
                <Link to="#">
                  <i className="fab fa-instagram"></i>
                </Link>
              </div>
            </div>
          </div>
          <h3 className="project-details__title">Token Creation Tool (TCT)</h3>
          <div className="project-details__content">
            <p>
              Crypto tokens are digital assets created/operating on PHQ native
              coin’s parent blockchain, i.e., PHQ Blockchain. These tokens
              represent an investor’s stake in an organization and can fulfil
              economic purposes. In other words, one can use them to purchase
              goods and services or trade them like financial instruments to
              make financial gains. These tokens perform the same function as
              physical tokens, such as the UAE Dirham, American Dollar, and
              British Pound. They are units of value that one person can pass on
              to another decentralized wallet. Such digital assets are of
              various types, for example, platform, transactional, security,
              etc. First introduced on the Ethereum blockchain, they play a
              vital role in making payments faster and easier. A crypto token is
              a digital asset that does not have its blockchain, unlike a crypto
              coin. However, they are programmable, permissionless, and offer
              transparency.{" "}
            </p>
            <br />
            <h4 className="project-details__title" style={{ fontSize: "30px" }}>
              How to use TCT for creating tokens
            </h4>
            <span>Open the token creation window</span>
            <span>Choose token name</span>
            <br />
            <span>Input token symbol</span>
            <br />
            <span>Decide on the number of decimals</span>
            <br />
            <span>Decide on what kind of token to be created</span>
            <br />
            <span>
              Generic or mintable, burnable, pausable, expandable, linkable, etc
            </span>
            <br />
            <span>Click on submit/confirm button</span>
            <br />
            <span>
              The contract will be created by the PHQ Blockchain. You do not
              have to waste your time writing lengthy smart contracts.
            </span>
            <br />
            <span>
              After the contract is created by the blockchain, the token owner
              can mint the number of tokens required
            </span>
            <br />
            <span>
              After minting, the token will be transferred to the owner’s wallet
            </span>
            <br />
            <span>Now the owner is ready for onward transfers</span>
            <br />
            <p
              class=""
              style={{
                marginTop: "30px",
                marginBottom: "15px",
                color: "black",
                fontSize: "20px",
              }}
            >
              BENEFITS OF PHQ TOKEN CREATION TOOL:
            </p>
            <ul className="project-details__list pl">
              <li>
                <i className="fa fa-check-circle"></i>
                World’s fastest custom token creation
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                Blockchain is smart enough to understand the requirements of
                token creators
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                Fastest validation by nodes
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                Fastest wallet creation and transfer of the minted tokens
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                Highly secured TCT system
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                User-friendly and easily operable
              </li>
              <li>
                <i className="fa fa-check-circle"></i>A 5-year-old kid can also
                create and launch his/her own token and transfer it to friends
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="project-details section-padding--bottom footer-widget__newsletter mc-form">
        <center>
          <a href="#" target="_blank" className="thm-btn about-four__btn">
            <span>Whitepaper</span>
          </a>
        </center>
      </section>
      <div className="project-nav">
        <div className="container">
          <div className="project-nav__inner">
            <Link to="/secured-email-system">
              <i className="icon-left-arrow"></i>
              Previous
            </Link>
            <Link to="/secure-digital-identity">
              Next
              <i className="icon-right-arrow"></i>
            </Link>
          </div>
        </div>
      </div>

      <section className="section-padding--top section-padding--bottom">
        <div className="container">
          <div className="section-title text-center">
            <p className="section-title__text">Other PHQ Products</p>
            <h2 className="section-title__title">
              Check What We Have <br /> Developed?
            </h2>
          </div>

          <OwlCarousel className="thm-owl__carousel" {...options}>
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={SecureI} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text">PHQ.SEMS.V1.2023</p>
                    <h3 className="project-card-one__title">
                      <Link to="/secured-email-system">
                        Secured Email System
                      </Link>
                    </h3>
                    <Link
                      to="/secured-email-system"
                      className="project-card-one__more"
                    >
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="item">
							<div className="project-card-one">
								<div className="project-card-one__image">
									<img src={TC} alt="" />
								</div>
								<div className="project-card-one__content">
									<div className="project-card-one__content__inner">
										<p className="project-card-one__text">PHQ.TCT.V1.2023</p>
										<h3 className="project-card-one__title"><Link to="/token_creation_tool">Token Creation Tool</Link></h3>
										<Link to="/token_creation_tool" className="project-card-one__more">
											<i className="fa fa-angle-right"></i>
										</Link>
									</div>
								</div>
							</div>
						</div> */}
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={FNT} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text">PHQ.FNT.V1.2023</p>
                    <h3 className="project-card-one__title">
                      <Link to="/fntasy">Fntasy Gaming</Link>
                    </h3>
                    <Link to="/fntasy" className="project-card-one__more">
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={DI} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text">PHQ.SDI.V1.2023</p>
                    <h3 className="project-card-one__title">
                      <Link to="/secure-digital-identity">
                        Secured Digital Identity
                      </Link>
                    </h3>
                    <Link
                      to="/secure-digital-identity"
                      className="project-card-one__more"
                    >
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={AT} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text">PHQ.ATS.V1.2023</p>
                    <h3 className="project-card-one__title">
                      <Link to="/asset_tokenization">
                        Asset Tokenization System
                      </Link>
                    </h3>
                    <Link
                      to="/asset_tokenization"
                      className="project-card-one__more"
                    >
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={HRFIEDI} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text"> PHQ.HRF.V1.2023</p>
                    <h3 className="project-card-one__title">
                      <Link to="/hrfied">Hrfied</Link>
                    </h3>
                    <Link to="/hrfied" className="project-card-one__more">
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={CDBCI} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text"> PHQ.CDBC.V1.2023</p>
                    <h3 className="project-card-one__title">
                      <Link to="/central_bank_digital_currency">
                        Central Bank Digital Currency
                      </Link>
                    </h3>
                    <Link
                      to="/central_bank_digital_currency"
                      className="project-card-one__more"
                    >
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={W} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text">
                      {" "}
                      PHQ.WALLET.V1.2023
                    </p>
                    <h3 className="project-card-one__title">
                      <Link to="/cryptocurrency-wallet">
                        Cryptocurrency Wallet
                      </Link>
                    </h3>
                    <Link
                      to="/cryptocurrency-wallet"
                      className="project-card-one__more"
                    >
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={MoneyI} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text"> PHQ.MTRN.V1.2023</p>
                    <h3 className="project-card-one__title">
                      <Link to="/money-transfer">Money Transfer</Link>
                    </h3>
                    <Link
                      to="/money-transfer"
                      className="project-card-one__more"
                    >
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={CharityI} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text">
                      {" "}
                      PHQ.Charity.V1.2023
                    </p>
                    <h3 className="project-card-one__title">
                      <Link to="/charity">Charity</Link>
                    </h3>
                    <Link to="/charity" className="project-card-one__more">
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={NFTI} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text"> PHQ.NFT.V1.2023</p>
                    <h3 className="project-card-one__title">
                      <Link to="/nft-market-place">NFT Marketplace</Link>
                    </h3>
                    <Link
                      to="/nft-market-place"
                      className="project-card-one__more"
                    >
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </OwlCarousel>
        </div>
      </section>
    </>
  );
};

export default TokenCreationTool;
