import Blockchain from "../../assets/Docs/Blockchain.pdf";
import Breadcumb from "../Breadcumb/Main";
import Cyber2 from "../../assets/images/services/Cyber2.jpg";
import Cyber3 from "../../assets/images/services/Cyber3.jpg";
import { Link } from "react-router-dom";
import WP from "../../assets/images/Wp.gif";
import { useState } from "react";

const Cyber = () => {
  const [clicked, setClicked] = useState(0);
  const faqs = [
    {
      question: "What is Malware?",
      answer: (
        <div>
          Malware is malicious software. One of the most common cyber threats,
          malware is software that a cybercriminal or hacker has created to
          disrupt or damage a legitimate user’s computer. Often spread via an
          unsolicited email attachment or legitimate- looking download, malware
          may be used by cybercriminals to make money or in politically
          motivated cyber-attacks.
        </div>
      ),
    },
    {
      question: "What are the different types of malware?",
      answer: (
        <div>
          <p>
            <span>Virus : </span>
            <span>
              {" "}
              A self-replicating program that attaches itself to a clean file
              and spreads throughout a computer system, infecting files with
              malicious code.
            </span>
          </p>
          <br />
          <p>
            <span>Trojans : </span>
            <span>
              {" "}
              A type of malware that is disguised as legitimate software.
              Cybercriminals trick users into uploading Trojans onto their
              computers where they cause damage or collect data.
            </span>
          </p>
          <br />
          <p>
            <span>Spyware : </span>
            <span>
              {" "}
              A program that secretly records what a user does, so that
              cybercriminals can make use of this information. For example,
              spyware could capture credit card details.
            </span>
          </p>
          <br />
          <p>
            <span>Ransomware : </span>
            <span>
              {" "}
              Malware that locks down a user’s files and data, with the threat
              of erasing it unless a ransom is paid.
            </span>
          </p>
          <br />

          <p>
            <span>Adware : </span>
            <span>
              {" "}
              Advertising software that can be used to spread malware.
            </span>
          </p>
          <br />
          <p>
            <span>Botnets : </span>
            <span>
              {" "}
              Networks of malware-infected computers that cybercriminals use to
              perform tasks online without the user’s permission.
            </span>
          </p>
        </div>
      ),
    },
    {
      question: "How to protect your systems against cyberattacks?",
      answer: (
        <div>
          <p>
            <span>Update your software and operating system : </span>
            <span>
              {" "}
              This means you benefit from the latest security patches.
            </span>
          </p>
          <br />
          <p>
            <span>Use anti-virus software : </span>
            <span>
              {" "}
              Security solutions like Kaspersky Total Security will detect and
              removes threats. Keep your software updated for the best level of
              protection.
            </span>
          </p>
          <br />

          <p>
            <span>Use strong passwords : </span>
            <span> Ensure your passwords are not easily guessable.</span>
          </p>
          <br />
          <p>
            <span>Do not open email attachments from unknown senders : </span>
            <span> These could be infected with malware.</span>
          </p>
          <br />
          <p>
            <span>
              Do not click on links in emails from unknown senders or unfamiliar
              websites :{" "}
            </span>
            <span> This is a common way that malware is spread.</span>
          </p>
          <br />
          <p>
            <span>
              Avoid using unsecured Wi-Fi networks in public places :{" "}
            </span>
            <span>
              {" "}
              Unsecured networks leave you vulnerable to man-in-the- middle
              attacks.
            </span>
          </p>
        </div>
      ),
    },
    {
      question: "Which projects can be developed by PHQ?",
      answer: (
        <div>
          <span>Packet Sniffing</span>
          <br />
          <span>Keylogging </span>
          <br />
          <span>Decrypting Caesar Cipher </span>
          <br />
          <span>Bug Bounties </span>
          <br />
          <span>RFID Blocking </span>
          <br />
          <span>Encrypting Images</span>
          <br />
          <span>Web Application Firewall</span>
          <br />
          <span>Website Scraper</span>
          <br />
          <span>Log Analyzer</span>
          <br />
          <span>Antivirus</span>
          <br />
          <span>Malware Analysis Sandbox</span>
          <br />
          <span>A Secure Erasure Code-Based Cloud Storage System</span>
          <br />
          <span>Facial Authentication System for the Web</span>
          <br />
          <span>Scanner for Security</span>
        </div>
      ),
    },
  ];
  return (
    <>
      <Breadcumb Title="Cyber Security" Breadcumb="SERVICES" />
      <section className="section-padding--bottom section-padding--top service-details--page">
        <div className="container">
          <div className="row ">
            <div className="col-lg-8">
              <div className="service-details__image">
                <img src={Cyber2} alt="" />
              </div>
              <h3 className="service-details__title">Cyber Security</h3>
              <div className="service-details__content">
                <p>
                  The arrangement of technologies, protocols, and methods
                  referred to as &quot;cyber security&quot; is meant to guard
                  against attacks, damage, malware, viruses, hacking, data
                  theft, and unauthorized access to networks, devices, programs,
                  and data. The basic goal of cyber security is to protect the
                  confidentiality of all business data from external and
                  internal threats as well as disruptions brought on by natural
                  disasters.
                </p>
                <h3 className="service-details__title">
                  Types of Cyber Threats
                </h3>
                <span>
                  The threats countered by cyber-security are three-fold:
                </span>
                <span>
                  Cybercrime includes single actors or groups targeting systems
                  for financial gain or to cause disruption.
                </span>
                <br />
                <span>
                  Cyber-attack often involves politically motivated information
                  gathering.
                </span>
                <br />
                <span>
                  Cyberterrorism is intended to undermine electronic systems to
                  cause panic or fear.
                </span>
              </div>
              <div className="row gutter-y-30 service-details__box-wrapper">
                <div className="col-md-6 col-sm-12">
                  <div className="service-details__box">
                    <i className="service-details__box__icon icon-consulting"></i>
                    <div className="service-details__box__content">
                      <h3 className="service-details__box__title">
                        <a href={Blockchain} target="_blank">
                          Blockchain{" "}
                        </a>
                      </h3>
                      <p className="service-details__box__text">
                        Faster Solutions
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <div className="service-details__box">
                    <i className="service-details__box__icon icon-chip"></i>
                    <div className="service-details__box__content">
                      <h3 className="service-details__box__title">
                        <Link to="/all-services">IT Specialist</Link>
                      </h3>
                      <p className="service-details__box__text">
                        Smarter Solutions
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row gutter-y-30">
                <div className="col-md-6 col-sm-12">
                  <img
                    src={Cyber3}
                    className="service-details__sub-image"
                    alt=""
                  />
                </div>
                <div className="col-md-6 col-sm-12">
                  <div className="service-details__caption">
                    <h3 className="service-details__caption__title">
                      Our Benefits
                    </h3>
                    <p className="service-details__caption__text">
                      Our highly talented workforce is callable of handling the
                      following cybersecurity cats:
                    </p>
                    <ul className="service-details__caption__list">
                      <li>
                        <i className="fa fa-check-circle"></i>
                        Network & application security
                      </li>
                      <li>
                        <i className="fa fa-check-circle"></i>
                        Operational security, end-user education
                      </li>
                      <li>
                        <i className="fa fa-check-circle"></i>
                        Disaster recovery and business continuity
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div
                    className="accrodion-grp service-details__accrodion"
                    data-grp-name="service-details__accrodion-1"
                  >
                    {faqs.map((item, index) => (
                      <div
                        className={`accrodion wow fadeInUp ${
                          index === clicked && "active"
                        }`}
                        key={index}
                        data-wow-delay="0ms"
                      >
                        <div className="accrodion-title">
                          <h4 onClick={() => setClicked(index)}>
                            {item.question}
                            <span className="accrodion-icon"></span>
                          </h4>
                        </div>
                        {index === clicked && (
                          <div className="accrodion-content">
                            <div className="inner">
                              <p>{item.answer}</p>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 sidebar-column">
              <div className="sidebar">
                <div className="sidebar__item sidebar__item--category">
                  <h3 className="sidebar__title">Categories</h3>
                  <ul className="sidebar__category">
                    <li>
                      <Link to="/bass">Bass</Link>
                    </li>
                    <li>
                      <Link to="/artificial_intelligence">AI</Link>
                    </li>
                    {/* <li><Link to="/cyber-security">Cyber Security</Link></li> */}
                    <li>
                      <Link to="/it-management">IT Management</Link>
                    </li>
                    <li>
                      <Link to="/qa-testing">QA & Testing</Link>
                    </li>
                    <li>
                      <Link to="/big-data">Big Data</Link>
                    </li>
                    <li>
                      <Link to="/web3">Web3</Link>
                    </li>
                    <li>
                      <Link to="/data-analytics">Data Analytics</Link>
                    </li>
                  </ul>
                </div>
                <div className="sidebar__item sidebar__item--cta">
                  <div className="sidebar__cta">
                    <a
                      href="https://api.whatsapp.com/send?phone=+919873221469&text=Hi,"
                      target="_blank"
                      className=""
                      style={{ minHeight: "12vh" }}
                    >
                      <img
                        src={WP}
                        style={{ height: "10vh", marginRight: "10px" }}
                      />
                      {/* <i className="sidebar__cta__icon icon-phone-ringing"></i> */}
                    </a>
                    <h3 className="sidebar__cta__title">
                      Have Tech Problems? Contact Us
                    </h3>
                    <p className="sidebar__cta__text">
                      Whatsapp Anytime <br /> +91 9873221469{" "}
                    </p>
                  </div>
                </div>
                <div className="sidebar__item sidebar__item--btn">
                  <Link to="#" className="thm-btn sidebar__btn dark__button">
                    <span>Download our flyers</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Cyber;
