import BlogSidebar from "../BlogSidebar/Main";
import Breadcumb from "../../Breadcumb/Main";
import { Link } from "react-router-dom";
import asset1 from "../../../assets/images/blog/asset1.jpg";

const Asset = () => {
  return (
    <>
      <Breadcumb Title="Blog Details" Breadcumb="BLOG" />
      <section className="section-padding--bottom section-padding--top">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="blog-details__image">
                <img src={asset1} alt="" />
              </div>
              <div className="blog-card-one__meta">
                <div className="blog-card-one__date">
                  <i className="fa fa-calendar-alt" aria-hidden="true"></i>
                  Feb 14, 2023
                </div>
                <Link to="#" className="blog-card-one__category">
                  PHQTI
                </Link>
              </div>
              <h3 className="blog-card-one__title blog-details__title">
                Asset tokenization and fractional investment
              </h3>
              <div className="blog-details__content">
                <p>
                  Asset tokenization is a process that simplifies the ownership
                  of assets, such as real estate, gold, bonds, antiques, and
                  trophy assets. With this process, these assets can be
                  converted into digital tokens of small fractions, allowing for
                  more small-size investors to buy fractional ownership in the
                  assets and record each transaction on the PHQ blockchain. All
                  transactions on the PHQ blockchain are performed with zero gas
                  fees. Additionally, asset tokenization can improve
                  accessibility, liquidity, and fractional ownership of these
                  assets.{" "}
                </p>
                <p>
                  First, the asset must be converted into a digital token, also
                  known as tokenization. This involves transforming a real-
                  world asset into a digital asset, which is then stored on a
                  blockchain-based system. This aligns the asset’s value with a
                  certain cryptocurrency, such as PHQ COIN, Ethereum, or
                  Bitcoin, and ensures that all information associated with the
                  token is stored permanently and securely on the ledger.
                  Investors will also have the option to buy these tokens with
                  fiat money using credit cards or any other payment transfer
                  medium.
                </p>
                <p>
                  Once the asset is tokenized, it can be exchanged for various
                  currencies and transferred between different users without the
                  need for middlemen. This allows for faster, more secure
                  transactions.
                </p>
                <p>
                  Additionally, asset tokenization unlocks the potential for
                  fractional ownership by allowing the asset to be divided into
                  smaller digital tokens that can be sold or traded. This makes
                  it easier and more efficient for non-accredited investors to
                  invest in a wider range of assets.
                </p>
                <p>
                  Finally, asset tokenization enables the tracking of assets by
                  creating a chain of ownership for each asset. This chain,
                  which is stored in a distributed ledger, contains transfer
                  records and authentication information. This ensures that
                  ownership is secure, immutable, and transparent.
                </p>
                <p>
                  In summary, asset tokenization is a process that simplifies
                  the ownership of assets such as real estate, gold, bonds,
                  antiques, and trophy assets by converting them into digital
                  tokens and eliminating the need for gas fees. It also unlocks
                  the potential for fractional ownership and easier, more secure
                  transactions between users, as well as the tracking of assets
                  through an immutable, distributed ledger.
                </p>
                <p>
                  Is asset tokenization the secure future for small investors?
                </p>
              </div>
              <div className="blog-details__meta">
                <div className="blog-details__tags">
                  <span>Tags</span>
                  <Link to="#">TOKENIZATION</Link>
                  <Link to="#">REAL ESTATE</Link>

                  <Link to="#">GOLD</Link>
                  <Link to="#">BONDS</Link>

                  <Link to="#">ANTIQUE</Link>
                  <Link to="#">METAL</Link>
                </div>
                <ul className="blog-details__share">
                  <li>
                    <Link to="#">
                      <i className="fab fa-twitter"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="fab fa-facebook"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="fab fa-pinterest-p"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="fab fa-instagram"></i>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="author-one">
                <div className="author-one__image">
                  <img src="assets/images/blog/author-1-1.jpg" alt="" />
                </div>
                <div className="author-one__content">
                  <h3 className="author-one__title">PHQTI</h3>
                  <p className="author-one__text"></p>
                </div>
              </div>
              <div className="comments-one">
                <h3 className="comments-one__title">2 Comments</h3>
                <div className="comments-one__item">
                  <div className="comments-one__item__image">
                    <img src="assets/images/blog/comment-1-1.png" alt="" />
                  </div>
                  <div className="comments-one__item__content">
                    <h3 className="comments-one__item__title">Kevin Martin</h3>

                    <p className="comments-one__item__text">
                      It has survived not only five centuries, but also the leap
                      into electronic typesetting simply fee text aunchanged. It
                      was popularised in the sheets containing lorem ipsum is
                      simply free text.
                    </p>

                    <Link to="#" className="thm-btn comments-one__item__btn">
                      <span>Reply</span>
                    </Link>
                  </div>
                </div>
                <div className="comments-one__item">
                  <div className="comments-one__item__image">
                    <img src="assets/images/blog/comment-1-2.png" alt="" />
                  </div>
                  <div className="comments-one__item__content">
                    <h3 className="comments-one__item__title">Sarah Albert</h3>

                    <p className="comments-one__item__text">
                      It has survived not only five centuries, but also the leap
                      into electronic typesetting simply fee text aunchanged. It
                      was popularised in the sheets containing lorem ipsum is
                      simply free text.
                    </p>

                    <Link to="#" className="thm-btn comments-one__item__btn">
                      <span>Reply</span>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="comment-form">
                <h3 className="comment-form__title">Leave a Comment</h3>
                <form
                  action="assets/inc/sendemail.php"
                  className="contact-one__form contact-form-validated comment-form__form"
                >
                  <div className="row ">
                    <div className="col-lg-6 col-md-12">
                      <input type="text" placeholder="Your name" name="name" />
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <input
                        type="email"
                        placeholder="Email address"
                        name="email"
                      />
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <textarea
                        name="message"
                        placeholder="Write message"
                      ></textarea>
                    </div>
                    <div className="col-md-12">
                      <button
                        className="thm-btn contact-one__btn"
                        type="submit"
                      >
                        <span>Submit comment</span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="sidebar">
                <div className="sidebar__item sidebar__item--search">
                  <form action="#" className="sidebar__search">
                    <label for="search" className="sr-only">
                      search here
                    </label>
                    <input type="text" placeholder="Search Here" />
                    <button
                      type="submit"
                      aria-label="search submit"
                      className="thm-btn"
                    >
                      <span>
                        <i className="icon-magnifying-glass"></i>
                      </span>
                    </button>
                  </form>
                </div>
                <div className="sidebar__item sidebar__item--posts">
                  <h3 className="sidebar__title">Latest Posts</h3>
                  <ul className="sidebar__posts">
                    <li>
                      <div className="sidebar__posts__image">
                        <img src="assets/images/blog/blog-s-1-1.png" alt="" />
                      </div>
                      <div className="sidebar__posts__content">
                        <div className="sidebar__posts__date">
                          <i className="fa fa-comments"></i>2 Comments
                        </div>
                        <h3 className="sidebar__posts__title">
                          <Link to="/blog-details">
                            Tech experiences that connect us
                          </Link>
                        </h3>
                      </div>
                    </li>
                    <li>
                      <div className="sidebar__posts__image">
                        <img src="assets/images/blog/blog-s-1-2.png" alt="" />
                      </div>
                      <div className="sidebar__posts__content">
                        <div className="sidebar__posts__date">
                          <i className="fa fa-comments"></i>2 Comments
                        </div>
                        <h3 className="sidebar__posts__title">
                          <Link to="/blog-details">
                            Tech experiences that connect us
                          </Link>
                        </h3>
                      </div>
                    </li>
                    <li>
                      <div className="sidebar__posts__image">
                        <img src="assets/images/blog/blog-s-1-3.png" alt="" />
                      </div>
                      <div className="sidebar__posts__content">
                        <div className="sidebar__posts__date">
                          <i className="fa fa-comments"></i>2 Comments
                        </div>
                        <h3 className="sidebar__posts__title">
                          <Link to="/blog-details">
                            Tech experiences that connect us
                          </Link>
                        </h3>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="sidebar__item sidebar__item--category">
                  <h3 className="sidebar__title">Categories</h3>
                  <ul className="sidebar__category">
                    <li>
                      <Link to="/bass">Bass</Link>
                    </li>
                    <li>
                      <Link to="/cyber-security">Cyber Security</Link>
                    </li>
                    <li>
                      <Link to="/it-management">IT Management</Link>
                    </li>
                    <li>
                      <Link to="/qa-testing">QA & Testing</Link>
                    </li>
                    <li>
                      <Link to="/infrastructure-plan">Big Data</Link>
                    </li>
                    <li>
                      <Link to="/web3">Web3</Link>
                    </li>
                    <li>
                      <Link to="/data-analytics">Data Analytics</Link>
                    </li>
                  </ul>
                </div>
                <div className="sidebar__item sidebar__item--tags">
                  <h3 className="sidebar__title">Tags</h3>
                  <div className="sidebar__tags">
                    <Link to="#">TOKENIZATION</Link>
                    <Link to="#">REAL ESTATE</Link>
                    <Link to="#">GOLD</Link>
                    <Link to="#">BONDS</Link>
                    <Link to="#">RARE</Link>
                    <Link to="#">ANTIQUE</Link>
                    <Link to="#">METAL</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Asset;
