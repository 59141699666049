import Aii from "../../assets/images/services/All/Aii.jpg";
import BassI from "../../assets/images/services/All/bassI.jpg";
import BigI from "../../assets/images/services/All/BigI.jpg";
import Breadcumb from "../Breadcumb/ServicesBreadcumb";
import CyberI from "../../assets/images/services/All/CyberI.jpg";
import DataI from "../../assets/images/services/All/DataI.jpg";
import Iti from "../../assets/images/services/All/Iti.jpg";
import { Link } from "react-router-dom";
import QaI from "../../assets/images/services/All/QaI.jpg";
import WebI from "../../assets/images/services/All/WebI.jpg";

const AllServices = () => {
  return (
    <>
      <Breadcumb Title="All Services" Breadcumb="SERVICES" />
      <section className="section-padding--bottom section-padding--top">
        <div className="container">
          <div className="row gutter-y-30">
            <div
              className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp"
              data-wow-duration="1500ms"
              data-wow-delay="000ms"
            >
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={BassI} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text">PHQ.BASS.2023</p>
                    <h3 className="project-card-one__title">
                      <Link to="/bass">Blockchain as a Service</Link>
                    </h3>
                    <Link to="/bass" className="project-card-one__more">
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp"
              data-wow-duration="1500ms"
              data-wow-delay="100ms"
            >
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={Aii} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text">PHQ.AI.2023</p>
                    <h3 className="project-card-one__title">
                      <Link to="/artificial_intelligence">
                        Artificial Intelligence
                      </Link>
                    </h3>
                    <Link
                      to="/artificial_intelligence"
                      className="project-card-one__more"
                    >
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp"
              data-wow-duration="1500ms"
              data-wow-delay="200ms"
            >
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={CyberI} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text">PHQ.CS.2023</p>
                    <h3 className="project-card-one__title">
                      <Link to="/cyber-security">Cyber Security</Link>
                    </h3>
                    <Link
                      to="/cyber-security"
                      className="project-card-one__more"
                    >
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp"
              data-wow-duration="1500ms"
              data-wow-delay="300ms"
            >
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={Iti} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text">PHQ.ITM.2023</p>
                    <h3 className="project-card-one__title">
                      <Link to="/it-management">IT Management</Link>
                    </h3>
                    <Link
                      to="/it-management"
                      className="project-card-one__more"
                    >
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp"
              data-wow-duration="1500ms"
              data-wow-delay="400ms"
            >
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={QaI} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text">PHQ.QA&T.2023</p>
                    <h3 className="project-card-one__title">
                      <Link to="/qa-testing">QA Testing</Link>
                    </h3>
                    <Link to="/qa-testing" className="project-card-one__more">
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp"
              data-wow-duration="1500ms"
              data-wow-delay="400ms"
            >
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={BigI} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text">PHQ.BIGDATA.2023</p>
                    <h3 className="project-card-one__title">
                      <Link to="/asset_tokenization">Big Data</Link>
                    </h3>
                    <Link
                      to="/asset_tokenization"
                      className="project-card-one__more"
                    >
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp"
              data-wow-duration="1500ms"
              data-wow-delay="500ms"
            >
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={WebI} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text">PHQ.WEB3.2023</p>
                    <h3 className="project-card-one__title">
                      <Link to="/web3">Web3</Link>
                    </h3>
                    <Link to="/web3" className="project-card-one__more">
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp"
              data-wow-duration="1500ms"
              data-wow-delay="300ms"
            >
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={DataI} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text">PHQ.DA.2023</p>
                    <h3 className="project-card-one__title">
                      <Link to="/data-analytics">Data Analytics</Link>
                    </h3>
                    <Link
                      to="/data-analytics"
                      className="project-card-one__more"
                    >
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-duration="1500ms"
						data-wow-delay="400ms">
						<div className="project-card-one">
							<div className="project-card-one__image">
								<img src={W} alt=""/>
							</div>
							<div className="project-card-one__content">
								<div className="project-card-one__content__inner">
								<p className="project-card-one__text">PHQ.WALLET.V1.2023</p>
                                        <h3 className="project-card-one__title"><Link to="/cryptocurrency-wallet">Cryptocurrency wallet</Link></h3>
									<Link to="/project-details" className="project-card-one__more">
										<i className="fa fa-angle-right"></i>
									</Link>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-duration="1500ms"
						data-wow-delay="500ms">
						<div className="project-card-one">
							<div className="project-card-one__image">
								<img src="assets/images/projects/project-1-6.jpg" alt=""/>
							</div>
							<div className="project-card-one__content">
								<div className="project-card-one__content__inner">
								<p className="project-card-one__text">PHQ.MTRN.V1.2023</p>
                                        <h3 className="project-card-one__title"><Link to="/money-transfer">Money Transfer</Link></h3>
									<Link to="/project-details" className="project-card-one__more">
										<i className="fa fa-angle-right"></i>
									</Link>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-duration="1500ms"
						data-wow-delay="400ms">
						<div className="project-card-one">
							<div className="project-card-one__image">
								<img src="assets/images/projects/project-1-5.jpg" alt=""/>
							</div>
							<div className="project-card-one__content">
								<div className="project-card-one__content__inner">
								<p className="project-card-one__text">PHQ.Charity.V1.2023</p>
                                        <h3 className="project-card-one__title"><Link to="/charity">Charity</Link></h3>
									<Link to="/project-details" className="project-card-one__more">
										<i className="fa fa-angle-right"></i>
									</Link>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-duration="1500ms"
						data-wow-delay="500ms">
						<div className="project-card-one">
							<div className="project-card-one__image">
								<img src="assets/images/projects/project-1-6.jpg" alt=""/>
							</div>
							<div className="project-card-one__content">
								<div className="project-card-one__content__inner">
								<p className="project-card-one__text">PHQ.NFT.V1.2023</p>
                                        <h3 className="project-card-one__title"><Link to="/nft-market-place">NFT Marketplace</Link></h3>
									<Link to="/project-details" className="project-card-one__more">
										<i className="fa fa-angle-right"></i>
									</Link>
								</div>
							</div>
						</div>
					</div> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default AllServices;
