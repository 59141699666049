import AT from "../../../assets/images/At.jpg";
import Breadcumb from "../../Breadcumb/Main";
import CDBCI from "../../../assets/images/CDBCI.jpg";
import CharityI from "../../../assets/images/CharityI.jpg";
import DI from "../../../assets/images/SecureD.jpg";
import FNT from "../../../assets/images/Fnt.jpg";
import HRFIEDI from "../../../assets/images/HRFIEDI.jpg";
import { Link } from "react-router-dom";
import MoneyI from "../../../assets/images/MoneyI.jpg";
import NFTI from "../../../assets/images/NftI.jpg";
import OwlCarousel from "react-owl-carousel";
import Public1 from "../../../assets/images/services/Public1.jpg";
import Sec4 from "../../../assets/images/Sec4.jpg";
import SecureI from "../../../assets/images/SecureI.jpg";
import TC from "../../../assets/images/TokenI.jpg";
import W from "../../../assets/images/W.jpg";

const PublicProject = () => {
  const options = {
    loop: true,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    smartSpeed: 700,
    items: 1,
    margin: 0,
    nav: false,
    dots: false,
    responsive: {
      0: {
        items: 1,
        margin: 0,
      },
      576: {
        items: 2,
        margin: 30,
      },
      992: {
        items: 3,
        margin: 30,
      },
    },
  };
  return (
    <>
      <Breadcumb
        Title="Public Projects on PHQ Blockchain"
        Breadcumb="PROJECT"
      />
      <section className="project-details section-padding--bottom section-padding--top">
        <div className="container">
          {/* <div className="project-details__image">
            <img src={Public1} alt="" />
          </div> */}
          <div className="project-details__info">
            <div className="project-details__info__item">
              <h4 className="project-details__info__title">Client:</h4>
              <p className="project-details__info__text">Open</p>
            </div>

            <div className="project-details__info__item">
              <h4 className="project-details__info__title">Category:</h4>
              <p className="project-details__info__text">
                <Link to="#">IT</Link>, <Link to="#">Blockchain IT</Link>
              </p>
            </div>
            <div className="project-details__info__item">
              <h4 className="project-details__info__title">Year:</h4>
              <p className="project-details__info__text">Continuous</p>
            </div>
            <div className="project-details__info__item">
              <h4 className="project-details__info__title">Value:</h4>
              <p className="project-details__info__text">Unlimited</p>
            </div>
            <div className="project-details__info__item">
              <div className="project-details__info__social">
                <Link to="#">
                  <i className="fab fa-twitter"></i>
                </Link>
                <Link to="#">
                  <i className="fab fa-facebook"></i>
                </Link>
                <Link to="#">
                  <i className="fab fa-instagram"></i>
                </Link>
              </div>
            </div>
          </div>
          <h3 className="project-details__title">
            Public Projects on PHQ Blockchain
          </h3>
          <div className="project-details__content">
            <p>
              PHQ Blockchain Technology makes use of blocks with cryptography to
              ensure a secure and reliable system. PHQ Blockchain Technology is
              rapidly finding use in government, Healthcare, Industrial,
              Banking, Defence, and other sectors. You may create/integrate your
              projects for Supply Chain, Healthcare, Banking &amp; Government
              Sectors with customized development solutions.
            </p>
            <br />

            <ul className="project-details__list">
              <li>
                <i className="fa fa-check-circle"></i>
                Evidence Protection System Using Blockchain Technology
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                Blockchain Shipment Management Tracking System
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                Identifying Fake Products Through A Barcode-Based Blockchain
                System
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                Medical Report Management & Distribution System on Blockchain
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                Real Estate Booking System using Smart Contracts
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                Trusted Crowdfunding Platform Using a Smart Contract
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                Blockchain based Loan Running System with Smart Contracts
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                Blockchain Rental Property System with Smart Contracts
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                Anti-Money Laundering System using Blockchain
              </li>
              {/* <li>
								<i className="fa fa-check-circle"></i>
                                Blockchain-based
							</li> */}
              <li>
                <i className="fa fa-check-circle"></i>
                Blockchain based Cloud File Sharing System
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                Blockchain based Cross Border Payment System
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                Blockchain based Merchant Payment System
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                Organization Finance Tracking System using Blockchain
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                Blockchain based Personal Identity Security System
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                Blockchain Tender/Contract Management System in Python
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                Blockchain based Disk Space Rental System
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                Blockchain Based Crowdfunding Platform
              </li>

              <li>
                <i className="fa fa-check-circle"></i>
                Blockchain Based Antiques Verification System
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                Property Registration Management System using Blockchain
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                Patient Data Management System using Blockchain
              </li>

              <li>
                <i className="fa fa-check-circle"></i>
                Electronic Voting System using Blockchain
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                Loyalty Points Exchange System using Blockchain
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                NGO Management System using Blockchain
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                Smart Tender Management System Using Blockchain
              </li>

              <li>
                <i className="fa fa-check-circle"></i>
                Blockchain based Transaction & Settlement System
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                Blockchain Based Cloud Storage System
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                Medical Report Management & Distribution System over Blockchain
                Technology
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                Android Document Sharing System using Blockchain Technology
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                Android Document Sharing System using Blockchain Technology
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                Government Fund Allocation &amp; Tracking System over Blockchain
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                Blockchain Project For Supply Chain Management System
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                Blockchain based Advanced Banking Software System
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                Automotive ERP System over Blockchain Technology
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                Industrial ERP System over Blockchain Technology
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                Retail Distribution Outlet Management System using Blockchain
                Technology
              </li>
            </ul>
          </div>
        </div>
      </section>

      {/* <div className="project-nav">
				<div className="container">
					<div className="project-nav__inner">
						<Link to="/hrfied">
							<i className="icon-left-arrow"></i>
							Previous
						</Link>
						<Link to="/cryptocurrency-wallet">
							Next
							<i className="icon-right-arrow"></i>
						</Link>
					</div>
				</div>
			</div> */}

      <section className=" section-padding--bottom">
        <div className="container">
          <div className="section-title text-center">
            <p className="section-title__text">Other PHQ Products</p>
            <h2 className="section-title__title">
              Check What We Have <br /> Developed?
            </h2>
          </div>

          <OwlCarousel className="thm-owl__carousel" {...options}>
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={SecureI} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text">PHQ.SEMS.V1.2023</p>
                    <h3 className="project-card-one__title">
                      <Link to="/secured-email-system">
                        Secured Email System
                      </Link>
                    </h3>
                    <Link
                      to="/secured-email-system"
                      className="project-card-one__more"
                    >
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={TC} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text">PHQ.TCT.V1.2023</p>
                    <h3 className="project-card-one__title">
                      <Link to="/token_creation_tool">Token Creation Tool</Link>
                    </h3>
                    <Link
                      to="/token_creation_tool"
                      className="project-card-one__more"
                    >
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={FNT} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text">PHQ.FNT.V1.2023</p>
                    <h3 className="project-card-one__title">
                      <Link to="/fntasy">Fntasy Gaming</Link>
                    </h3>
                    <Link to="/fntasy" className="project-card-one__more">
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={DI} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text">PHQ.SDI.V1.2023</p>
                    <h3 className="project-card-one__title">
                      <Link to="/secure-digital-identity">
                        Secured Digital Identity
                      </Link>
                    </h3>
                    <Link
                      to="/secure-digital-identity"
                      className="project-card-one__more"
                    >
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={AT} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text">PHQ.ATS.V1.2023</p>
                    <h3 className="project-card-one__title">
                      <Link to="/asset_tokenization">
                        Asset Tokenization System
                      </Link>
                    </h3>
                    <Link
                      to="/asset_tokenization"
                      className="project-card-one__more"
                    >
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={HRFIEDI} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text"> PHQ.HRF.V1.2023</p>
                    <h3 className="project-card-one__title">
                      <Link to="/hrfied">Hrfied</Link>
                    </h3>
                    <Link to="/hrfied" className="project-card-one__more">
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={CDBCI} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text"> PHQ.CDBC.V1.2023</p>
                    <h3 className="project-card-one__title">
                      <Link to="/central_bank_digital_currency">
                        Central Bank Digital Currency
                      </Link>
                    </h3>
                    <Link
                      to="/central_bank_digital_currency"
                      className="project-card-one__more"
                    >
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={W} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text">
                      {" "}
                      PHQ.WALLET.V1.2023
                    </p>
                    <h3 className="project-card-one__title">
                      <Link to="/cryptocurrency-wallet">
                        Cryptocurrency Wallet
                      </Link>
                    </h3>
                    <Link
                      to="/cryptocurrency-wallet"
                      className="project-card-one__more"
                    >
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={MoneyI} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text"> PHQ.MTRN.V1.2023</p>
                    <h3 className="project-card-one__title">
                      <Link to="/money-transfer">Money Transfer</Link>
                    </h3>
                    <Link
                      to="/money-transfer"
                      className="project-card-one__more"
                    >
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={CharityI} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text">
                      {" "}
                      PHQ.Charity.V1.2023
                    </p>
                    <h3 className="project-card-one__title">
                      <Link to="/charity">Charity</Link>
                    </h3>
                    <Link to="/charity" className="project-card-one__more">
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={NFTI} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text"> PHQ.NFT.V1.2023</p>
                    <h3 className="project-card-one__title">
                      <Link to="/nft-market-place">NFT Marketplace</Link>
                    </h3>
                    <Link
                      to="/nft-market-place"
                      className="project-card-one__more"
                    >
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </OwlCarousel>
        </div>
      </section>
    </>
  );
};

export default PublicProject;
