import { useEffect, useState } from "react";

import AT from "../../assets/images/At.jpg";
import AnimatedNumber from "animated-number-react";
import BGImg from "../../assets/images/background/cta-two-bg-1-1.jpg";
import BGImg1 from "../../assets/images/background/slider-1-1.jpg";
import BGImg2 from "../../assets/images/background/slider-1-2.jpg";
import BGImg3 from "../../assets/images/shapes/service-four-bg-1-1.png";
import BGImg4 from "../../assets/images/shapes/funfact-one-bg.png";
import BGImg5 from "../../assets/images/shapes/testi-bg-1-1.png";
import BGImg6 from "../../assets/images/shapes/team-home-bg-1-1.jpg";
import BGImg7 from "../../assets/images/shapes/about-three-s-1.png";
import Benifit from "../../assets/images/Benifit.jpg";
import CDBCI from "../../assets/images/CDBCI.jpg";
import DI from "../../assets/images/SecureD.jpg";
import Fnt from "../../assets/images/Fnt.jpg";
import HRFIEDI from "../../assets/images/HRFIEDI.jpg";
import IconB from "../../assets/images/IconB.png";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import Pankaj from "../../assets/images/Pankaj.jpg";
import { Parallax } from "react-parallax";
import Sec4 from "../../assets/images/Sec5.jpg";
import SecureI from "../../assets/images/SecureI.jpg";
import TC from "../../assets/images/TokenI.jpg";
import W from "../../assets/images/W.jpg";
import img1 from "../../assets/images/resources/about-five-1-1.jpg";
import img2 from "../../assets/images/resources/about-five-1-2.jpg";
import { useTheme } from "../../utils/useTheme";

const Home = () => {
  const [currentTheme, toggleTheme] = useTheme();
  const redirect = () => {
    console.log("hi");
    window.location.href = "/team";
  };
  const [ytShow, setytShow] = useState(false);
  const options = {
    loop: false,
    margin: 0,
    responsiveClass: true,
    nav: false,
    dots: false,
    autoplay: false,
    items: 1,
    smartSpeed: 700,
    responsive: {
      0: {
        margin: 0,
        items: 1,
      },
      576: {
        margin: 30,
        items: 2,
      },
      768: {
        margin: 30,
        items: 3,
      },
      992: {
        margin: 30,
        items: 4,
      },
      1200: {
        margin: 30,
        items: 5,
      },
    },
  };
  const sliderOptions = {
    loop: true,
    items: 1,
    navText: [
      '<span class="fa-solid fa-angle-left"></span>',
      '<span class="fa-solid fa-angle-right"></span>',
    ],
    margin: 0,
    dots: true,
    nav: true,
    animateOut: "slideOutDown",
    animateIn: "fadeIn",
    smartSpeed: 1000,
    autoplay: true,
    autoplayTimeout: 7000,
    autoplayHoverPause: false,
  };
  const projectOptions = {
    loop: true,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    nav: true,
    navText: [
      '<span class="fa-solid fa-angle-left"></span>',
      '<span class="fa-solid fa-angle-right"></span>',
    ],
    dots: false,
    margin: 0,
    items: 1,
    smartSpeed: 700,
    responsive: {
      0: {
        margin: 0,
        items: 1,
      },
      768: {
        margin: 30,
        items: 2,
      },
      992: {
        margin: 30,
        items: 3,
      },
    },
  };
  const logoOptions = {
    loop: true,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    nav: true,
    navText: [
      '<span class="fa-solid fa-angle-left"></span>',
      '<span class="fa-solid fa-angle-right"></span>',
    ],
    dots: false,
    margin: 30,
    items: 2,
    smartSpeed: 700,
    responsive: {
      0: {
        margin: 30,
        items: 2,
      },
      375: {
        margin: 30,
        items: 2,
      },
      575: {
        margin: 30,
        items: 3,
      },
      767: {
        margin: 50,
        items: 4,
      },
      991: {
        margin: 40,
        items: 5,
      },
      1199: {
        margin: 80,
        items: 5,
      },
    },
  };

  return (
    <>
      <div className="slider-one">
        <OwlCarousel
          className="slider-one__carousel owl-theme thm-owl__carousel"
          {...sliderOptions}
        >
          <div className="item slider-one__slide-1">
            <div
              className="slider-one__bg"
              style={{ backgroundImage: `url(${BGImg1})` }}
            ></div>
            <div className="slider-one__line"></div>
            <div className="slider-one__shape-1"></div>
            <div className="slider-one__shape-2"></div>
            <div className="slider-one__shape-3"></div>
            <div className="container">
              <div className="slider-one__content ">
                <div className="slider-one__floated lettering-text">
                  technology
                </div>
                <p className="slider-one__text">UNLOCK NEW DIGITAL HORIZONS</p>
                <div className="slider-one__title-wrapper">
                  <h2 className="slider-one__title">
                    AI-Driven DLT: Where Speed & Smart Solutions Converge{" "}
                  </h2>
                </div>
                <div className="slider-one__btns">
                  <Link to="/projects" className="thm-btn slider-one__btn">
                    <span>Learn More</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="item slider-one__slide-2">
            <div
              className="slider-one__bg"
              style={{ backgroundImage: `url(${BGImg2})` }}
            ></div>
            <div className="slider-one__line"></div>
            <div className="slider-one__shape-1"></div>
            <div className="slider-one__shape-2"></div>
            <div className="slider-one__shape-3"></div>
            <div className="container">
              <div className="slider-one__content ">
                <div className="slider-one__floated lettering-text">
                  technology
                </div>
                <p className="slider-one__text">
                  FUTURE-FORWARD TECHNOLOGY SOLUTIONS{" "}
                </p>
                <div className="slider-one__title-wrapper">
                  <h2 className="slider-one__title">
                    Tech Company That Understands Your Vision{" "}
                  </h2>
                </div>
                <div className="slider-one__btns">
                  <Link to="/all-services" className="thm-btn slider-one__btn">
                    <span>Learn More</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </OwlCarousel>
      </div>

      <section className="section-padding--bottom section-padding--top about-five">
        <div className="container">
          <div className="row gutter-y-60">
            <div className="col-lg-6">
              <div className="about-five__images">
                <div
                  className="about-five__images__shape"
                  style={{
                    mixBlendMode: currentTheme === "dark" ? "luminosity" : null,
                  }}
                ></div>
                <img
                  src={img1}
                  className="wow fadeInUp"
                  data-wow-duration="1500ms"
                  alt=""
                />
                <img src={img2} alt="" />
                <div className="about-five__video">
                  {/* <Link to="#" onClick={() => setytShow(true)} className="video-popup" > */}
                  <i className="fa fa-play"></i>
                  {/* </Link> */}
                </div>
                <div
                  className="about-five__images__caption count-box wow fadeInUp"
                  data-wow-duration="1500ms"
                >
                  <span
                    className="count-text"
                    style={{ color: currentTheme === "dark" ? "white" : " " }}
                  >
                    <AnimatedNumber
                      value={28}
                      duration={2500}
                      formatValue={(v) => Math.round(v)}
                    />
                  </span>
                  Years of
                  <br />
                  Existence
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-five__content">
                <div className="section-title ">
                  <p className="section-title__text">About PHQ</p>
                  <h2 className="section-title__title">
                    The World’s Real AI-DLT Company
                  </h2>
                </div>
                <div className="about-five__text">
                  <b
                    style={{
                      fontWeight: "",
                      color: currentTheme !== "dark" ? "black" : "white",
                    }}
                  >
                    Vision :
                  </b>{" "}
                  We aim to remain at the forefront of technology and reimagine
                  the scope of human civilization.
                </div>
                <div className="about-five__text">
                  <b
                    style={{
                      fontWeight: "",
                      color: currentTheme !== "dark" ? "black" : "white",
                    }}
                  >
                    Mission :
                  </b>{" "}
                  Our mission is to provide real-world solutions to businesses
                  and communities alike through continuous innovation.
                </div>
                <ul className="about-five__box">
                  <li className="about-five__box__item gray-bg">
                    <i className="about-five__box__icon icon-consulting"></i>
                    <div className="about-five__box__content">
                      <h3 className="about-five__box__title">
                        <a href="https://explorer.phq.com/" target="_blank">
                          PHQ Explorer
                        </a>
                      </h3>
                      <p className="about-five__box__text">Zero Gas Fees</p>
                    </div>
                  </li>
                  <li className="about-five__box__item gray-bg">
                    <i className="about-five__box__icon icon-chip"></i>
                    <div className="about-five__box__content">
                      <h3 className="about-five__box__title">
                        <a href="https://wallet.phq.com/" target="_blank">
                          PHQ Wallet
                        </a>
                      </h3>
                      <p className="about-five__box__text">Fastest Transfer</p>
                    </div>
                  </li>
                </ul>
                <div className="about-four__meta">
                  <div className="about-four__author">
                    <img src={Pankaj} alt="" />
                    <div className="about-four__author__content">
                      <h3 className="about-four__author__title">
                        Pankaj Tomar
                      </h3>
                      <div className="about-four__author__designation">
                        President
                      </div>
                    </div>
                  </div>
                  <a
                    href="https://www.linkedin.com/in/pankajtomar/"
                    target="_blank"
                    className="thm-btn about-four__btn"
                  >
                    <span>Profile</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className=" section-padding--top service-four gray-bg section-padding-xl--bottom section-has-bottom-margin background-repeat-no background-position-top-right"
        style={{
          backgroundImage: currentTheme !== "dark" ? `url(${BGImg3})` : "none",
        }}
      >
        <div className="container">
          <div className="section-title text-center">
            <p className="section-title__text">Company Services</p>
            <h2 className="section-title__title">
              We Provide Best <br />
              IT Solutions
            </h2>
          </div>
          <OwlCarousel
            className="thm-owl__carousel thm-owl__carousel--with-shadow service-four__carousel"
            {...options}
          >
            <div className="item">
              <div className="service-card-three">
                <div className="service-card-three__icon">
                  <i className="icon-new-product"></i>
                  {/* <center>
                                    <img class="" src={IconB} style={{height:'6vh',width:'3vw'}}/>
                                    </center> */}
                </div>
                <div className="service-card-three__content">
                  <h3 className="service-card-three__title">
                    <Link to="/bass">DLT as a Service</Link>
                  </h3>
                  <div className="service-card-three__text">
                    DLT solutions for people and businesses
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="service-card-three">
                <div className="service-card-three__icon">
                  <i className="icon-new-product"></i>
                </div>
                <div className="service-card-three__content">
                  <h3 className="service-card-three__title">
                    <Link to="/artificial_intelligence">
                      Artificial <br />
                      Intelligence
                    </Link>
                  </h3>
                  <div className="service-card-three__text">
                    Providing the best AI solutions
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="service-card-three">
                <div className="service-card-three__icon">
                  <i className="icon-protection"></i>
                </div>
                <div className="service-card-three__content">
                  <h3 className="service-card-three__title">
                    <Link to="/cyber-security">
                      Cyber <br />
                      security
                    </Link>
                  </h3>
                  <div className="service-card-three__text">
                    We secure your businesses against attacks
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="service-card-three">
                <div className="service-card-three__icon">
                  <i className="icon-web-development"></i>
                </div>
                <div className="service-card-three__content">
                  <h3 className="service-card-three__title">
                    <Link to="/big-data"> Big Data</Link>
                  </h3>
                  <div className="service-card-three__text">
                    Integrated solutions for big data requirements
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="service-card-three">
                <div className="service-card-three__icon">
                  <i className="icon-analysis"></i>
                </div>
                <div className="service-card-three__content">
                  <h3 className="service-card-three__title">
                    <Link to="/web3">Web3</Link>
                  </h3>
                  <div className="service-card-three__text">
                    Providing the solutions for web3 projects
                  </div>
                </div>
              </div>
            </div>
          </OwlCarousel>
        </div>
      </section>

      <section className="funfact-one">
        <div className="container">
          <div
            className="funfact-one__inner wow fadeInUp background-size-cover"
            data-wow-duration="1500ms"
            style={{
              backgroundImage:
                currentTheme === "dark" ? "none" : `url(${BGImg4})`,
              backgroundColor: "#202020",
            }}
          >
            <ul className="funfact-one__list">
              <li className="funfact-one__list__item">
                <h3 className="funfact-one__list__title count-box">
                  <span
                    data-stop="1995"
                    data-speed="2500"
                    className="count-text"
                  >
                    <AnimatedNumber
                      value={1995}
                      duration={2500}
                      formatValue={(v) => Math.round(v)}
                    />
                  </span>
                </h3>
                <p
                  className="funfact-one__list__text"
                  style={{
                    color: currentTheme === "dark" ? "#1da1f5" : "",
                  }}
                >
                  PHQ.Com was Born
                </p>
              </li>
              <li className="funfact-one__list__item">
                <h3 className="funfact-one__list__title count-box">
                  <span
                    data-stop="2017"
                    data-speed="2500"
                    className="count-text"
                  >
                    <AnimatedNumber
                      value={2017}
                      duration={2500}
                      formatValue={(v) => Math.round(v)}
                    />
                  </span>
                </h3>
                <p
                  className="funfact-one__list__text"
                  style={{
                    color: currentTheme === "dark" ? "#1da1f5" : "",
                  }}
                >
                  DLT Conceptualized
                </p>
              </li>
              <li className="funfact-one__list__item">
                <h3 className="funfact-one__list__title count-box">
                  <span
                    data-stop="2020"
                    data-speed="2500"
                    className="count-text"
                  >
                    <AnimatedNumber
                      value={2020}
                      duration={2500}
                      formatValue={(v) => Math.round(v)}
                    />
                  </span>
                </h3>
                <p
                  className="funfact-one__list__text"
                  style={{
                    color: currentTheme === "dark" ? "#1da1f5" : "",
                  }}
                >
                  Development Started
                </p>
              </li>
              <li className="funfact-one__list__item">
                <h3 className="funfact-one__list__title count-box">
                  <span
                    data-stop="2023"
                    data-speed="2500"
                    className="count-text"
                  >
                    <AnimatedNumber
                      value={2023}
                      duration={2500}
                      formatValue={(v) => Math.round(v)}
                    />
                  </span>
                </h3>
                <p
                  className="funfact-one__list__text"
                  style={{
                    color: currentTheme === "dark" ? "#1da1f5" : "",
                  }}
                >
                  PHQ AI-DLT Launched
                </p>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section className="section-padding--bottom section-padding--top project-one">
        <div className="container">
          <div className="project-one__top">
            <div className="row">
              <div className="col-lg-7">
                <div className="section-title ">
                  <p className="section-title__text">PHQ products</p>
                  <h2 className="section-title__title">
                    Hightech Projects Developed <br /> by PHQ Group
                  </h2>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="project-one__top__content">
                  <p className="project-one__top__text">
                    Projects being developed by PHQ developers
                  </p>
                  <div className="project-one__top__btns">
                    <Link
                      to="/projects"
                      className="thm-btn project-one__top__btn"
                    >
                      <span>View All Projects</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <OwlCarousel
            className="thm-owl__carousel project-one__carousel thm-owl__carousel--with-shadow"
            {...projectOptions}
          >
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={SecureI} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text">PHQ.SEMS.V1.2023</p>
                    <h3 className="project-card-one__title">
                      <Link to="/secured-email-system">
                        Secured Email System
                      </Link>
                    </h3>
                    <Link
                      to="/secured-email-system"
                      className="project-card-one__more"
                    >
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={TC} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text">PHQ.TCT.V1.2023</p>
                    <h3 className="project-card-one__title">
                      <Link to="/token_creation_tool">Token Creation Tool</Link>
                    </h3>
                    <Link
                      to="/token_creation_tool"
                      className="project-card-one__more"
                    >
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={Fnt} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text">PHQ.FNT.V1.2023</p>
                    <h3 className="project-card-one__title">
                      <Link to="/fntasy">Fntasy Gaming</Link>
                    </h3>
                    <Link to="/fntasy" className="project-card-one__more">
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={DI} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text">PHQ.SDI.V1.2023</p>
                    <h3 className="project-card-one__title">
                      <Link to="/secure-digital-identity">
                        Secured Digital Identity
                      </Link>
                    </h3>
                    <Link
                      to="/secure-digital-identity"
                      className="project-card-one__more"
                    >
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={AT} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text">PHQ.ATS.V1.2023</p>
                    <h3 className="project-card-one__title">
                      <Link to="/asset_tokenization">
                        Asset Tokenization System
                      </Link>
                    </h3>
                    <Link
                      to="/asset_tokenization"
                      className="project-card-one__more"
                    >
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="item">
                            <div className="project-card-one">
                                <div className="project-card-one__image">
                                    <img src="assets/images/projects/project-1-6.jpg" alt="" />
                                </div>
                                <div className="project-card-one__content">
                                    <div className="project-card-one__content__inner">
                                        <p className="project-card-one__text">IT Technology Solution</p>
                                        <h3 className="project-card-one__title"><Link to="/project-details">Data Recovery
                                            Analysis</Link></h3>
                                        <Link to="/project-details" className="project-card-one__more">
                                            <i className="fa fa-angle-right"></i>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div> */}
          </OwlCarousel>
        </div>
      </section>

      <section class="black-bg cta-two">
        <Parallax
          bgImage={Sec4}
          bgImageAlt=""
          className="section-padding-lg--top section-padding-lg--bottom"
          strength={1000}
        >
          <div className="container">
            <div className="cta-two__inner">
              <h3 className="cta-two__title">
                What you can develop on PHQ
                <span
                  style={{
                    backgroundImage: currentTheme === "dark" ? "none" : "",
                  }}
                >
                  {" "}
                  AI-DLT?{" "}
                </span>
              </h3>
              <Link to="/contact" className="thm-btn cta-two__btn">
                <span>LEarn More</span>
              </Link>
            </div>
          </div>
        </Parallax>
      </section>

      {/* <section
                className="section-padding--bottom section-padding--top testimonials-two background-repeat-no background-position-top-center"
                style={{ backgroundImage: `url(${BGImg5})` }}>
                <div className="container">
                    <div className="row gutter-y-60">
                        <div className="col-lg-5">
                            <div className="testimonials-two__content">
                                <div className="section-title ">
                                    <p className="section-title__text">Testimomials</p>
                                    <h2 className="section-title__title">We Are Trusted Worldwide.</h2>
                                </div>
                                <div className="testimonials-two__content__text">Sed ut perspiciatis unde omnis natus error sit
                                    voluptatem accusa ntium doloremque laudantium totam rem aperiamea queipsa quae abillo
                                    inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</div>
                                <div className="testimonials-two__content__text">Pellentesque gravida lectus vitae nisi luctus,
                                    Finibus aliquet ligula ultrices.</div>
                                <Link to="/about" className="thm-btn testimonials-two__content__btn"><span>View All
                                    feedbacks</span></Link>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="testimonials-two__items">
                                <div className="row gutter-y-30">
                                    <div className="col-lg-12">
                                        <div className="testimonials-one-card">
                                            <div className="testimonials-one-card__image">
                                                <img src="assets/images/resources/testi-1-1.jpg" alt="" />
                                            </div>
                                            <div className="testimonials-one-card__content">
                                                <div className="testimonials-one-card__text">On the other hand denounc with
                                                    ghteo
                                                    indignation and dislike men who so beguiled and demoralized the charms
                                                    of
                                                    pleasure
                                                    the momen blinded by desire cannot foresee the pain and trouble.</div>
                                                <h3 className="testimonials-one-card__title">Michal Rahul</h3>
                                                <p className="testimonials-one-card__designation">Ul - UX Designer</p>

                                                <i className="icon-right-quote testimonials-one-card__icon"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="testimonials-one-card">
                                            <div className="testimonials-one-card__image">
                                                <img src="assets/images/resources/testi-1-2.jpg" alt="" />
                                            </div>
                                            <div className="testimonials-one-card__content">
                                                <div className="testimonials-one-card__text">On the other hand denounc with
                                                    ghteo
                                                    indignation and dislike men who so beguiled and demoralized the charms
                                                    of
                                                    pleasure
                                                    the momen blinded by desire cannot foresee the pain and trouble.</div>
                                                <h3 className="testimonials-one-card__title">Jessica Brown</h3>
                                                <p className="testimonials-one-card__designation">Ul - UX Designer</p>
                                                <i className="icon-right-quote testimonials-one-card__icon"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
      {/* <div className="client-carousel client-carousel--two">
                <div className="container">
                    <OwlCarousel className="thm-owl__carousel" {...logoOptions}>
                        <div className="item">
                            <img src="assets/images/resources/client-1-1.png" alt="" />
                        </div>
                        <div className="item">
                            <img src="assets/images/resources/client-1-1.png" alt="" />
                        </div>
                        <div className="item">
                            <img src="assets/images/resources/client-1-1.png" alt="" />
                        </div>
                        <div className="item">
                            <img src="assets/images/resources/client-1-1.png" alt="" />
                        </div>
                        <div className="item">
                            <img src="assets/images/resources/client-1-1.png" alt="" />
                        </div>
                        <div className="item">
                            <img src="assets/images/resources/client-1-1.png" alt="" />
                        </div>
                        <div className="item">
                            <img src="assets/images/resources/client-1-1.png" alt="" />
                        </div>
                        <div className="item">
                            <img src="assets/images/resources/client-1-1.png" alt="" />
                        </div>
                        <div className="item">
                            <img src="assets/images/resources/client-1-1.png" alt="" />
                        </div>
                        <div className="item">
                            <img src="assets/images/resources/client-1-1.png" alt="" />
                        </div>
                        <div className="item">
                            <img src="assets/images/resources/client-1-1.png" alt="" />
                        </div>
                        <div className="item">
                            <img src="assets/images/resources/client-1-1.png" alt="" />
                        </div>
                        <div className="item">
                            <img src="assets/images/resources/client-1-1.png" alt="" />
                        </div>
                        <div className="item">
                            <img src="assets/images/resources/client-1-1.png" alt="" />
                        </div>
                        <div className="item">
                            <img src="assets/images/resources/client-1-1.png" alt="" />
                        </div>
                        <div className="item">
                            <img src="assets/images/resources/client-1-1.png" alt="" />
                        </div>
                        <div className="item">
                            <img src="assets/images/resources/client-1-1.png" alt="" />
                        </div>
                        <div className="item">
                            <img src="assets/images/resources/client-1-1.png" alt="" />
                        </div>
                        <div className="item">
                            <img src="assets/images/resources/client-1-1.png" alt="" />
                        </div>
                        <div className="item">
                            <img src="assets/images/resources/client-1-1.png" alt="" />
                        </div>
                    </OwlCarousel>
                </div>
            </div> */}
      {/* <section className="section-padding--bottom section-padding--top gray-bg background-size-cover"
                style={{ backgroundImage: `url(${BGImg6})` }}>
                <div className="container">
                    <div className="section-title text-center">
                        <p className="section-title__text">Team members</p>
                        <h2 className="section-title__title">Our Expert Advisors And <br/> Team Members</h2>
                    </div>
                    <div className="row gutter-y-30">
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="team-card-one wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="000ms">
                                <div className="team-card-one__image">
                                    <img src="assets/images/team/team-2-1.jpg" alt="" />
                                    <div className="team-card-one__social">
                                        <ul className="team-card-one__social__links">
                                            <li><Link to="#"><i className="fab fa-twitter"></i></Link></li>
                                            <li><Link to="#"><i className="fab fa-facebook"></i></Link></li>
                                            <li><Link to="#"><i className="fab fa-pinterest-p"></i></Link></li>
                                            <li><Link to="#"><i className="fab fa-instagram"></i></Link></li>
                                        </ul>
                                        <div className="team-card-one__social__icon">
                                            <i className="fa fa-share-alt"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="team-card-one__content">
                                    <h3 className="team-card-one__title"><Link to="#">Michelle Monaghan</Link></h3>

                                    <p className="team-card-one__designation">Designer</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="team-card-one wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="100ms">
                                <div className="team-card-one__image">
                                    <img src="assets/images/team/team-2-2.jpg" alt="" />
                                    <div className="team-card-one__social">
                                        <ul className="team-card-one__social__links">
                                            <li><Link to="#"><i className="fab fa-twitter"></i></Link></li>
                                            <li><Link to="#"><i className="fab fa-facebook"></i></Link></li>
                                            <li><Link to="#"><i className="fab fa-pinterest-p"></i></Link></li>
                                            <li><Link to="#"><i className="fab fa-instagram"></i></Link></li>
                                        </ul>
                                        <div className="team-card-one__social__icon">
                                            <i className="fa fa-share-alt"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="team-card-one__content">
                                    <h3 className="team-card-one__title"><Link to="#">Jessica Brown</Link></h3>

                                    <p className="team-card-one__designation">Manager</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="team-card-one wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="200ms">
                                <div className="team-card-one__image">
                                    <img src="assets/images/team/team-2-3.jpg" alt="" />
                                    <div className="team-card-one__social">
                                        <ul className="team-card-one__social__links">
                                            <li><Link to="#"><i className="fab fa-twitter"></i></Link></li>
                                            <li><Link to="#"><i className="fab fa-facebook"></i></Link></li>
                                            <li><Link to="#"><i className="fab fa-pinterest-p"></i></Link></li>
                                            <li><Link to="#"><i className="fab fa-instagram"></i></Link></li>
                                        </ul>
                                        <div className="team-card-one__social__icon">
                                            <i className="fa fa-share-alt"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="team-card-one__content">
                                    <h3 className="team-card-one__title"><Link to="#">Kevin Martin</Link></h3>

                                    <p className="team-card-one__designation">Developer</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="team-card-one wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="300ms">
                                <div className="team-card-one__image">
                                    <img src="assets/images/team/team-2-4.jpg" alt="" />
                                    <div className="team-card-one__social">
                                        <ul className="team-card-one__social__links">
                                            <li><Link to="#"><i className="fab fa-twitter"></i></Link></li>
                                            <li><Link to="#"><i className="fab fa-facebook"></i></Link></li>
                                            <li><Link to="#"><i className="fab fa-pinterest-p"></i></Link></li>
                                            <li><Link to="#"><i className="fab fa-instagram"></i></Link></li>
                                        </ul>
                                        <div className="team-card-one__social__icon">
                                            <i className="fa fa-share-alt"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="team-card-one__content">
                                    <h3 className="team-card-one__title"><Link to="#">Sarah Albert</Link></h3>

                                    <p className="team-card-one__designation">Director</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

      <section className=" section-padding--top about-three about-three--home-one">
        <div
          className="about-three__shape wow fadeInRight"
          data-wow-duration="1500ms"
          style={{
            backgroundImage: `url(${BGImg7})`,
            mixBlendMode: currentTheme === "dark" ? "luminosity" : null,
          }}
        ></div>
        <div className="container">
          <div className="row gutter-y-60">
            <div className="col-lg-6">
              <div className="about-three__image">
                <img
                  src={Benifit}
                  className="wow fadeInUp"
                  data-wow-duration="1500ms"
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-three__content">
                <div className="section-title ">
                  <p className="section-title__text">Company Benefits</p>
                  <h2 className="section-title__title">
                    Benefits of PHQ AI-DLT!
                  </h2>
                </div>
                <div className="about-three__text">
                  PHQ team is available to support partners, integrators and
                  businesses
                </div>
                <ul className="about-three__list">
                  <li className="about-three__list__item">
                    <div className="about-three__list__icon">
                      <i className="icon-cloud"></i>
                    </div>
                    <div className="about-three__list__content">
                      <h3 className="about-three__list__title">
                        <Link to="cyber-security">World's Real AI-DLT</Link>
                      </h3>
                      <p className="about-three__list__text">
                        Option for doing free transactions. PHQ AI-DLT is the
                        easiest to integrate.
                      </p>
                    </div>
                  </li>
                  <li className="about-three__list__item">
                    <div className="about-three__list__icon">
                      <i className="icon-group"></i>
                    </div>
                    <div
                      className="about-three__list__content"
                      onClick={redirect}
                    >
                      <a href="/team">
                        {" "}
                        <h3
                          className="about-three__list__title"
                          style={{ zIndex: "999" }}
                        >
                          Experts
                        </h3>
                      </a>
                      <p className="about-three__list__text">
                        Company recruits the best
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-padding--top section-padding--bottom">
        {/* <div className="container">
                    <div className="section-title text-center">
                        <p className="section-title__text">Direct from the Blog Posts</p>
                        <h2 className="section-title__title">Checkout Our Latest <br />
                            News & Articles</h2>
                    </div>
                    <div className="row gutter-y-30">
                        <div className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-duration="1500ms"
                            data-wow-delay="000ms">
                            <div className="blog-card-one">
                                <div className="blog-card-one__image">
                                    <img src="assets/images/blog/blog-1-1.jpg" alt="" />
                                    <Link to="/blog-details"></Link>
                                </div>
                                <div className="blog-card-one__content">
                                    <div className="blog-card-one__meta">
                                        <div className="blog-card-one__date">
                                            <i className="fa fa-calendar-alt" aria-hidden="true"></i>
                                            July, 25, 2022
                                        </div>
                                        <Link to="/blog" className="blog-card-one__category">Designer</Link>
                                    </div>
                                    <h3 className="blog-card-one__title"><Link to="/blog-details">Web design done Delightful
                                        Visualization Examples</Link></h3>
                                    <Link to="/blog-details" className="blog-card-one__more">
                                        Read More
                                        <i className="fa fa-arrow-right"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-duration="1500ms"
                            data-wow-delay="100ms">
                            <div className="blog-card-one">
                                <div className="blog-card-one__image">
                                    <img src="assets/images/blog/blog-1-2.jpg" alt="" />
                                    <Link to="/blog-details"></Link>
                                </div>
                                <div className="blog-card-one__content">
                                    <div className="blog-card-one__meta">
                                        <div className="blog-card-one__date">
                                            <i className="fa fa-calendar-alt" aria-hidden="true"></i>
                                            July, 25, 2022
                                        </div>
                                        <Link to="/blog" className="blog-card-one__category">Graphic</Link>
                                    </div>
                                    <h3 className="blog-card-one__title"><Link to="/blog-details">Technology Support Allows
                                        Erie non-profit to Serve</Link></h3>
                                    <Link to="/blog-details" className="blog-card-one__more">
                                        Read More
                                        <i className="fa fa-arrow-right"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-duration="1500ms"
                            data-wow-delay="200ms">
                            <div className="blog-card-one">
                                <div className="blog-card-one__image">
                                    <img src="assets/images/blog/blog-1-3.jpg" alt="" />
                                    <Link to="/blog-details"></Link>
                                </div>
                                <div className="blog-card-one__content">
                                    <div className="blog-card-one__meta">
                                        <div className="blog-card-one__date">
                                            <i className="fa fa-calendar-alt" aria-hidden="true"></i>
                                            July, 25, 2022
                                        </div>
                                        <Link to="/blog" className="blog-card-one__category">SEO</Link>
                                    </div>
                                    <h3 className="blog-card-one__title"><Link to="/blog-details">Software Makes Your Profit
                                        Double if You Scale Properly</Link></h3>
                                    <Link to="/blog-details" className="blog-card-one__more">
                                        Read More
                                        <i className="fa fa-arrow-right"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
      </section>

      <section className="cta-one">
        <div className="container">
          <div
            className="cta-one__inner text-center wow fadeInUp"
            data-wow-duration="1500ms"
            style={{
              backgroundImage:
                currentTheme === "dark"
                  ? "linear-gradient(0deg, #101010 0%, #252525 100%) "
                  : "linear-gradient(0deg, #ab32f2 0%, #6a2dec 100%)",
            }}
          >
            <div className="cta-one__circle"></div>
            <div className="section-title ">
              <p className="section-title__text">
                Need Any Technology Solution?
              </p>
              <h2 className="section-title__title section-title__title--light">
                Let’s Work Together on Your Project
              </h2>
            </div>
            <Link
              to="/contact"
              className="thm-btn thm-btn--light cta-one__btn dark__button"
            >
              <span>Explore</span>
            </Link>
          </div>
        </div>
      </section>
      {ytShow && (
        <div className="YouTubePopUp-Wrap">
          <div className="YouTubePopUp-Content">
            <span
              className="YouTubePopUp-Close"
              onClick={() => setytShow(false)}
            ></span>
            <iframe
              src="https://www.youtube.com/embed/rzfmZC3kg3M?autoplay=1"
              title="ytvideo"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      )}
    </>
  );
};

export default Home;
