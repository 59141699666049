import Breadcumb from "../Breadcumb/Main";
import { Link } from "react-router-dom";

const Team = () => {
  return (
    <>
      <Breadcumb Title="Team" Breadcumb="TEAM" />
      <section className="section-padding--bottom section-padding--top">
        <div className="container">
          <div className="section-title text-center">
            <p className="section-title__text">Team</p>
            <h2 className="section-title__title">
              Our Experts and <br /> Team Members
            </h2>
          </div>
          <div className="row gutter-y-30">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div
                className="team-card-one wow fadeInUp"
                data-wow-duration="1500ms"
                data-wow-delay="000ms"
              >
                <div className="team-card-one__image">
                  <img src="assets/images/team/team-1-1.jpg" alt="" />
                  <div className="team-card-one__social">
                    <ul className="team-card-one__social__links">
                      <li>
                        <Link to="#">
                          <i className="fab fa-twitter"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-facebook"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-pinterest-p"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-instagram"></i>
                        </Link>
                      </li>
                    </ul>
                    <div className="team-card-one__social__icon">
                      <i className="fa fa-share-alt"></i>
                    </div>
                  </div>
                </div>
                <div className="team-card-one__content">
                  <h3 className="team-card-one__title">
                    <a
                      href="https://www.linkedin.com/in/pankajtomar/	"
                      target="_blank"
                    >
                      Pankaj Tomar
                    </a>
                  </h3>

                  <Link to="">
                    {" "}
                    <p className="team-card-one__designation">President</p>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div
                className="team-card-one wow fadeInUp"
                data-wow-duration="1500ms"
                data-wow-delay="100ms"
              >
                <div className="team-card-one__image">
                  <img src="assets/images/team/team-1-2.jpg" alt="" />
                  <div className="team-card-one__social">
                    <ul className="team-card-one__social__links">
                      <li>
                        <Link to="#">
                          <i className="fab fa-twitter"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-facebook"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-pinterest-p"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-instagram"></i>
                        </Link>
                      </li>
                    </ul>
                    <div className="team-card-one__social__icon">
                      <i className="fa fa-share-alt"></i>
                    </div>
                  </div>
                </div>
                <div className="team-card-one__content">
                  <h3 className="team-card-one__title">
                    <a href="https://www.linkedin.com/in/shorya-singh-397779202/	">
                      Shorya Singh
                    </a>
                  </h3>

                  <p className="team-card-one__designation">
                    Head of Technology (London)
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div
                className="team-card-one wow fadeInUp"
                data-wow-duration="1500ms"
                data-wow-delay="200ms"
              >
                <div className="team-card-one__image">
                  <img src="assets/images/team/team-1-3.jpg" alt="" />
                  <div className="team-card-one__social">
                    <ul className="team-card-one__social__links">
                      <li>
                        <Link to="#">
                          <i className="fab fa-twitter"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-facebook"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-pinterest-p"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-instagram"></i>
                        </Link>
                      </li>
                    </ul>
                    <div className="team-card-one__social__icon">
                      <i className="fa fa-share-alt"></i>
                    </div>
                  </div>
                </div>
                <div className="team-card-one__content">
                  <h3 className="team-card-one__title">
                    <a href="https://www.linkedin.com/in/adya-tom-3b9145248/	">
                      Adya Tom
                    </a>
                  </h3>

                  <p className="team-card-one__designation">
                    Director (Fntasy Ltd, London)
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div
                className="team-card-one wow fadeInUp"
                data-wow-duration="1500ms"
                data-wow-delay="300ms"
              >
                <div className="team-card-one__image">
                  <img src="assets/images/team/team-1-4.jpg" alt="" />
                  <div className="team-card-one__social">
                    <ul className="team-card-one__social__links">
                      <li>
                        <Link to="#">
                          <i className="fab fa-twitter"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-facebook"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-pinterest-p"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-instagram"></i>
                        </Link>
                      </li>
                    </ul>
                    <div className="team-card-one__social__icon">
                      <i className="fa fa-share-alt"></i>
                    </div>
                  </div>
                </div>
                <div className="team-card-one__content">
                  <h3 className="team-card-one__title">
                    <a href="https://www.linkedin.com/in/rooturaj/	">
                      Rooturaj Pattanaik
                    </a>
                  </h3>

                  <p className="team-card-one__designation">
                    {" "}
                    Manager Internet Technology
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div
                className="team-card-one wow fadeInUp"
                data-wow-duration="1500ms"
                data-wow-delay="400ms"
              >
                <div className="team-card-one__image">
                  <img src="assets/images/team/team-1-5.jpg" alt="" />
                  <div className="team-card-one__social">
                    <ul className="team-card-one__social__links">
                      <li>
                        <Link to="#">
                          <i className="fab fa-twitter"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-facebook"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-pinterest-p"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-instagram"></i>
                        </Link>
                      </li>
                    </ul>
                    <div className="team-card-one__social__icon">
                      <i className="fa fa-share-alt"></i>
                    </div>
                  </div>
                </div>
                <div className="team-card-one__content">
                  <h3 className="team-card-one__title">
                    <a href="https://www.linkedin.com/in/rakshit-rastogi/">
                      Rakshit Rastogi
                    </a>
                  </h3>

                  <p className="team-card-one__designation">
                    Blockchain Adoption Associate
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div
                className="team-card-one wow fadeInUp"
                data-wow-duration="1500ms"
                data-wow-delay="500ms"
              >
                <div className="team-card-one__image">
                  <img src="assets/images/team/team-1-6.jpg" alt="" />
                  <div className="team-card-one__social">
                    <ul className="team-card-one__social__links">
                      <li>
                        <Link to="#">
                          <i className="fab fa-twitter"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-facebook"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-pinterest-p"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-instagram"></i>
                        </Link>
                      </li>
                    </ul>
                    <div className="team-card-one__social__icon">
                      <i className="fa fa-share-alt"></i>
                    </div>
                  </div>
                </div>
                <div className="team-card-one__content">
                  <h3 className="team-card-one__title">
                    <a href="https://www.linkedin.com/in/badar-madni-58418a135/		">
                      Badar Madni
                    </a>
                  </h3>

                  <p className="team-card-one__designation">
                    React Developer (Dubai) Eve
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div
                className="team-card-one wow fadeInUp"
                data-wow-duration="1500ms"
                data-wow-delay="500ms"
              >
                <div className="team-card-one__image">
                  <img src="assets/images/team/team-1-6.jpg" alt="" />
                  <div className="team-card-one__social">
                    <ul className="team-card-one__social__links">
                      <li>
                        <Link to="#">
                          <i className="fab fa-twitter"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-facebook"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-pinterest-p"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-instagram"></i>
                        </Link>
                      </li>
                    </ul>
                    <div className="team-card-one__social__icon">
                      <i className="fa fa-share-alt"></i>
                    </div>
                  </div>
                </div>
                <div className="team-card-one__content">
                  <h3 className="team-card-one__title">
                    <a href="https://www.linkedin.com/in/sumitkumarblockchain/	">
                      Sumit Kumar -{" "}
                    </a>
                  </h3>

                  <p className="team-card-one__designation">
                    Corporate Alliance and Partnerships
                  </p>
                </div>
              </div>
            </div>
            {/* <div className="col-lg-4 col-md-6 col-sm-12">
						<div className="team-card-one wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="500ms">
							<div className="team-card-one__image">
								<img src="assets/images/team/team-1-6.jpg" alt="" />
								<div className="team-card-one__social">
									<ul className="team-card-one__social__links">
										<li><Link to="#"><i className="fab fa-twitter"></i></Link></li>
										<li><Link to="#"><i className="fab fa-facebook"></i></Link></li>
										<li><Link to="#"><i className="fab fa-pinterest-p"></i></Link></li>
										<li><Link to="#"><i className="fab fa-instagram"></i></Link></li>
									</ul>
									<div className="team-card-one__social__icon">
										<i className="fa fa-share-alt"></i>
									</div>
								</div>
							</div>
							<div className="team-card-one__content">
								<h3 className="team-card-one__title"><Link to="#">Christine Eve</Link></h3>
								
								<p className="team-card-one__designation">IT Expert</p>
							</div>
						</div>
					</div> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Team;
