import "./App.css";
import "./assets/vendors/bootstrap/css/bootstrap.min.css";
import "./assets/vendors/fontawesome/css/all.min.css";
import "./assets/vendors/jarallax/jarallax.css";
import "./assets/vendors/animate/animate.min.css";
import "./assets/vendors/owl-carousel/assets/owl.carousel.min.css";
import "./assets/vendors/owl-carousel/assets/owl.theme.default.min.css";
import "./assets/vendors/cretech-icons/style.css";
import "./assets/vendors/youtube-popup/youtube-popup.css";
import "./assets/css/cretech.scss";

import { Route, Routes, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

import About from "./components/About/Main";
import SparkGem from "./components/SparkGem/Main";
import SmartProducts from "./components/SmartProducts/Main";
import AboutUs from "./components/AboutUs";
import AllServices from "./components/Services/AllServices";
import Asset from "./components/Blog/BlogDetails/Asset";
import Bass from "./components/Services/Bass";
import BigData from "./components/Services/BigData";
import Blockchain from "./components/Blog/BlogDetails/Blockchain";
import Blogs from "./components/Blog/Blogs/Main";
import CBDC from "./components/Project/ProjectDetails/CBDC";
import Cdbc from "./components/Blog/BlogDetails/Cdbc";
import Charity from "./components/Project/ProjectDetails/Charity";
import Contact from "./components/Contact/Main";
import CookiePolicy from "./components/CookiePolicy";
import Cyber from "./components/Services/Cyber";
import CyberSecurity from "./components/Services/CyberSecurity/Main";
import DataAnalytics from "./components/Services/DataAnalytics/Main";
import Developers from "./components/Developers";
import Fntasy from "./components/Project/ProjectDetails/Fntasy";
import Footer from "./components/Footer/Main";
import Home from "./components/Home/Main";
import Home2 from "./components/Home2/Main";
import Home3 from "./components/Home3/Main";
import Hrfied from "./components/Project/ProjectDetails/Hrfied";
import ITManagement from "./components/Services/ITManagement/Main";
import InfrastructurePlan from "./components/Services/InfrastructurePlan/Main";
import MoneyTransfer from "./components/Project/ProjectDetails/MoneyTransfer";
import NFTMarketplace from "./components/Project/ProjectDetails/NFTMarketplace";
import Navbar from "./components/Navbar/Main";
import OurParners from "./components/Partners";
import PrivacyPolicy from "./components/PrivacyPolicy";
import ProjectDetails from "./components/Project/ProjectDetails/Main";
import Projects from "./components/Project/Projects/Main";
import PublicProject from "./components/Project/ProjectDetails/PublicProjects";
import QATesting from "./components/Services/QATesting/Main";
import RealEstate from "./components/Project/ProjectDetails/RealEstate";
import Rewards from "./components/Rewards";
import { BrowserRouter as Router } from "react-router-dom";
import SDI from "./components/Project/ProjectDetails/SDI";
import SeMS from "./components/Project/ProjectDetails/SeMS";
import Service1 from "./components/Services/Services1/Main";
import Service2 from "./components/Services/Services2/Main";
import TC from "./components/TC";
import Team from "./components/Team/Main";
import { ThemeProvider } from "./utils/useTheme";
import TokenCreationTool from "./components/Project/ProjectDetails/TokenCreationTool";
import Wallet from "./components/Project/ProjectDetails/Wallet";
import Web3 from "./components/Services/Web3";
import Web3blog from "./components/Blog/BlogDetails/Web3";
import FutureUnits from "./components/FutureUnits";
import PaymentGateway from "./components/Project/ProjectDetails/PaymentGateway";
import AIDLT from "./components/Project/ProjectDetails/AIDLT";
import SparkGemProject from "./components/Project/ProjectDetails/SparkGem";
import PHQDC from "./components/PHQDC/Main";
import PHQUSD from "./components/PHQUSD/Main";
import IPFSRecorder from "./components/Project/ProjectDetails/IPFSRecorder";

function App() {
  return (
    <Router>
      <ThemeProvider>
        <Routing />
      </ThemeProvider>
    </Router>
  );
}

export default App;

const Routing = () => {
  const [homepage, setHomepage] = useState(false);
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/home-02" || location.pathname === "/home-03") {
      setHomepage(false);
    } else {
      setHomepage(true);
    }
  }, [location]);

  return (
    <>
      {homepage && <Navbar />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home-02" element={<Home2 />} />
        <Route path="/home-03" element={<Home3 />} />
        <Route path="/about" element={<About />} />
        <Route path="/team" element={<Team />} />
        <Route path="/service-01" element={<Service1 />} />
        <Route path="/service-02" element={<Service2 />} />
        <Route path="/cyber-security" element={<Cyber />} />
        <Route path="/it-management" element={<ITManagement />} />
        <Route path="/qa-testing" element={<QATesting />} />
        <Route path="/infrastructure-plan" element={<InfrastructurePlan />} />
        <Route path="/data-analytics" element={<DataAnalytics />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/project-details" element={<ProjectDetails />} />
        <Route path="/blog" element={<Blogs />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/asset_tokenization" element={<RealEstate />} />
        <Route path="/token_creation_tool" element={<TokenCreationTool />} />
        <Route path="/central_bank_digital_currency" element={<CBDC />} />
        <Route path="/nft-market-place" element={<NFTMarketplace />} />
        <Route path="/secure-digital-identity" element={<SDI />} />
        <Route path="/secured-email-system" element={<SeMS />} />
        <Route path="/cryptocurrency-wallet" element={<Wallet />} />
        <Route path="/big-data" element={<BigData />} />
        <Route path="/public-projects" element={<PublicProject />} />
        <Route path="/artificial_intelligence" element={<CyberSecurity />} />
        <Route path="/bass" element={<Bass />} />
        <Route path="/web3" element={<Web3 />} />
        <Route path="/charity" element={<Charity />} />
        <Route path="/money-transfer" element={<MoneyTransfer />} />
        <Route path="/fntasy" element={<Fntasy />} />
        <Route path="/hrfied" element={<Hrfied />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/cookie-policy" element={<CookiePolicy />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/all-services" element={<AllServices />} />
        <Route path="/terms-and-conditions" element={<TC />} />
        <Route path="/our-partners" element={<OurParners />} />
        <Route path="/rewards" element={<Rewards />} />
        <Route path="/developers" element={<Developers />} />
        <Route path="/asset-blog-details" element={<Asset />} />
        <Route path="/web3-blog-details" element={<Web3blog />} />
        <Route path="/blockchain-blog-details" element={<Blockchain />} />
        <Route path="/cdbc-blog-details" element={<Cdbc />} />
        <Route path="/payment-gateway" element={<PaymentGateway />} />
        <Route path="/spark-gem" element={<SparkGem />} />
        <Route path="/smart-products" element={<SmartProducts />} />
        <Route path="/future-units" element={<FutureUnits />} />
        <Route path="/spark-gem-project" element={<SparkGemProject />} />
        <Route path="/aidlt" element={<AIDLT />} />
        <Route path="/phqdc" element={<PHQDC />} />
        <Route path="/phqusd" element={<PHQUSD />} />
        <Route path="/ipfs-recorder" element={<IPFSRecorder />} />
      </Routes>
      <Footer />
    </>
  );
};
