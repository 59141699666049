import BlogSidebar from "../BlogSidebar/Main";
import Breadcumb from "../../Breadcumb/Main";
import { Link } from "react-router-dom";
import web31 from "../../../assets/images/blog/web31.jpg";
const Web3 = () => {
  return (
    <>
      <Breadcumb Title="Blog Details" Breadcumb="BLOG" />
      <section className="section-padding--bottom section-padding--top">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="blog-details__image">
                <img src={web31} alt="" />
              </div>
              <div className="blog-card-one__meta">
                <div className="blog-card-one__date">
                  <i className="fa fa-calendar-alt" aria-hidden="true"></i>
                  Feb 07, 2023
                </div>
                <Link to="#" className="blog-card-one__category">
                  PHQTI
                </Link>
              </div>
              <h3 className="blog-card-one__title blog-details__title">
                Web3 the future technology requirement
              </h3>
              <div className="blog-details__content">
                <p>
                  Web 3 is the next generation of the internet, leveraging
                  advanced technologies to offer users unprecedented data
                  security and completely free transactions using PHQ
                  blockchain.
                </p>
                <p>
                  Users of Web 3 can expect to interact with ultra-fast
                  transaction clearing, eliminating the need to pay gas fees
                  (Only on PHQ Blockchain) and experience the wide range of
                  applications enabled by artificial intelligence, machine
                  learning, deep learning, and computer Vision.
                </p>
                <p>
                  In addition to providing a secure platform for online
                  activities, Web 3 is designed to facilitate transactions in a
                  variety of fields. This platform also enables users to
                  securely store and transfer data, allowing individuals and
                  organizations to manage their digital assets on the internet
                  with an unprecedented level of security.
                </p>

                <p>
                  Web 3 is one of the most revolutionary technologies to emerge
                  in recent years, as its data security, free transactions, and
                  AI-enabled applications have the potential to revolutionize
                  the way people interact with the internet. Companies and
                  individuals alike stand to benefit from this technology, as
                  they can now make use of innovative data protection measures
                  and conduct transactions without having to worry about large
                  fees.
                </p>

                <p>
                  Overall, Web 3 is a revolutionary technology that has the
                  potential to revolutionize the way we interact with the
                  internet, providing unprecedented data security and free
                  transactions. With the use of artificial intelligence, machine
                  learning, deep learning, and computer vision, Web 3 stands to
                  make the online world much more secure and efficient in the
                  years to come.
                </p>
                <p>Is Web3 really the future?</p>
              </div>
              <div className="blog-details__meta">
                <div className="blog-details__tags">
                  <span>Tags</span>
                  <Link to="#">WEB3</Link>
                  <Link to="#">AI</Link>
                  <Link to="#">MACHINE LEARNING</Link>
                  <Link to="#">DEEP LEARNING</Link>
                  <Link to="#">COMPUTER VISION</Link>
                </div>
                <ul className="blog-details__share">
                  <li>
                    <Link to="#">
                      <i className="fab fa-twitter"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="fab fa-facebook"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="fab fa-pinterest-p"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="fab fa-instagram"></i>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="author-one">
                <div className="author-one__image">
                  <img src="assets/images/blog/author-1-1.jpg" alt="" />
                </div>
                <div className="author-one__content">
                  <h3 className="author-one__title">PHQTI</h3>
                  <p className="author-one__text"></p>
                </div>
              </div>
              <div className="comments-one">
                <h3 className="comments-one__title">2 Comments</h3>
                <div className="comments-one__item">
                  <div className="comments-one__item__image">
                    <img src="assets/images/blog/comment-1-1.png" alt="" />
                  </div>
                  <div className="comments-one__item__content">
                    <h3 className="comments-one__item__title">Kevin Martin</h3>

                    <p className="comments-one__item__text">
                      It has survived not only five centuries, but also the leap
                      into electronic typesetting simply fee text aunchanged. It
                      was popularised in the sheets containing lorem ipsum is
                      simply free text.
                    </p>

                    <Link to="#" className="thm-btn comments-one__item__btn">
                      <span>Reply</span>
                    </Link>
                  </div>
                </div>
                <div className="comments-one__item">
                  <div className="comments-one__item__image">
                    <img src="assets/images/blog/comment-1-2.png" alt="" />
                  </div>
                  <div className="comments-one__item__content">
                    <h3 className="comments-one__item__title">Sarah Albert</h3>

                    <p className="comments-one__item__text">
                      It has survived not only five centuries, but also the leap
                      into electronic typesetting simply fee text aunchanged. It
                      was popularised in the sheets containing lorem ipsum is
                      simply free text.
                    </p>

                    <Link to="#" className="thm-btn comments-one__item__btn">
                      <span>Reply</span>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="comment-form">
                <h3 className="comment-form__title">Leave a Comment</h3>
                <form
                  action="assets/inc/sendemail.php"
                  className="contact-one__form contact-form-validated comment-form__form"
                >
                  <div className="row ">
                    <div className="col-lg-6 col-md-12">
                      <input type="text" placeholder="Your name" name="name" />
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <input
                        type="email"
                        placeholder="Email address"
                        name="email"
                      />
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <textarea
                        name="message"
                        placeholder="Write message"
                      ></textarea>
                    </div>
                    <div className="col-md-12">
                      <button
                        className="thm-btn contact-one__btn"
                        type="submit"
                      >
                        <span>Submit comment</span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="sidebar">
                <div className="sidebar__item sidebar__item--search">
                  <form action="#" className="sidebar__search">
                    <label for="search" className="sr-only">
                      search here
                    </label>
                    <input type="text" placeholder="Search Here" />
                    <button
                      type="submit"
                      aria-label="search submit"
                      className="thm-btn"
                    >
                      <span>
                        <i className="icon-magnifying-glass"></i>
                      </span>
                    </button>
                  </form>
                </div>
                <div className="sidebar__item sidebar__item--posts">
                  <h3 className="sidebar__title">Latest Posts</h3>
                  <ul className="sidebar__posts">
                    <li>
                      <div className="sidebar__posts__image">
                        <img src="assets/images/blog/blog-s-1-1.png" alt="" />
                      </div>
                      <div className="sidebar__posts__content">
                        <div className="sidebar__posts__date">
                          <i className="fa fa-comments"></i>2 Comments
                        </div>
                        <h3 className="sidebar__posts__title">
                          <Link to="/blog-details">
                            Tech experiences that connect us
                          </Link>
                        </h3>
                      </div>
                    </li>
                    <li>
                      <div className="sidebar__posts__image">
                        <img src="assets/images/blog/blog-s-1-2.png" alt="" />
                      </div>
                      <div className="sidebar__posts__content">
                        <div className="sidebar__posts__date">
                          <i className="fa fa-comments"></i>2 Comments
                        </div>
                        <h3 className="sidebar__posts__title">
                          <Link to="/blog-details">
                            Tech experiences that connect us
                          </Link>
                        </h3>
                      </div>
                    </li>
                    <li>
                      <div className="sidebar__posts__image">
                        <img src="assets/images/blog/blog-s-1-3.png" alt="" />
                      </div>
                      <div className="sidebar__posts__content">
                        <div className="sidebar__posts__date">
                          <i className="fa fa-comments"></i>2 Comments
                        </div>
                        <h3 className="sidebar__posts__title">
                          <Link to="/blog-details">
                            Tech experiences that connect us
                          </Link>
                        </h3>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="sidebar__item sidebar__item--category">
                  <h3 className="sidebar__title">Categories</h3>
                  <ul className="sidebar__category">
                    <li>
                      <Link to="/bass">Bass</Link>
                    </li>
                    <li>
                      <Link to="/cyber-security">Cyber Security</Link>
                    </li>
                    <li>
                      <Link to="/it-management">IT Management</Link>
                    </li>
                    <li>
                      <Link to="/qa-testing">QA & Testing</Link>
                    </li>
                    <li>
                      <Link to="/infrastructure-plan">Big Data</Link>
                    </li>
                    <li>
                      <Link to="/web3">Web3</Link>
                    </li>
                    <li>
                      <Link to="/data-analytics">Data Analytics</Link>
                    </li>
                  </ul>
                </div>
                <div className="sidebar__item sidebar__item--tags">
                  <h3 className="sidebar__title">Tags</h3>
                  <div className="sidebar__tags">
                    <Link to="#">WEB3</Link>
                    <Link to="#">AI</Link>
                    <Link to="#">MACHINE LEARNING</Link>
                    <Link to="#">DEEP LEARNING</Link>
                    <Link to="#">COMPUTER VISION</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Web3;
