import BG from "../../assets/images/background/contact-one-bg-1-1.png";
import Breadcumb from "../Breadcumb/Main";
import Cright from "../../assets/images/CRight.jpg";
import { Link } from "react-router-dom";
import Map from "../../assets/images/Map.jpg";
const Contact = () => {
  return (
    <>
      <Breadcumb Title="Contact" Breadcumb="CONTACT" />
      <section className="contact-one section-padding--top section-padding--bottom">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <form
                action="assets/inc/sendemail.php"
                className="contact-one__form contact-form-validated"
              >
                <div className="section-title">
                  <p className="section-title__text">Contact us</p>
                  <h2 className="section-title__title">
                    Connect For Free Consultation
                  </h2>
                </div>
                <div className="row ">
                  <div className="col-lg-6 col-md-12">
                    <input type="text" placeholder="Your name" name="name" />
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <input
                      type="email"
                      placeholder="Email address"
                      name="email"
                    />
                  </div>
                  <div className="col-lg-12 col-md-12">
                    <textarea
                      name="message"
                      placeholder="Write message"
                    ></textarea>
                  </div>
                  <div className="col-md-12">
                    <button className="thm-btn contact-one__btn" type="submit">
                      <span>send a message</span>
                    </button>
                  </div>
                </div>
              </form>
              <div className="result"></div>
            </div>
            <div className="col-lg-4">
              <div
                className="contact-one__info wow fadeInRight"
                data-wow-duration="1500ms"
                style={{ backgroundImage: `url(${BG})` }}
              >
                <div className="contact-one__item">
                  <h3 className="contact-one__item__title">Address</h3>
                  <p className="contact-one__item__text">
                    PHQ Technologies Inc. <br />
                    Delaware, USA
                  </p>
                </div>
                <div className="contact-one__item">
                  <h3 className="contact-one__item__title">Whatsapp</h3>
                  <p className="contact-one__item__text">
                    <a
                      href="https://api.whatsapp.com/send?phone=+919873221469&text=Hi,"
                      target="_blank"
                    >
                      {/* <i className="sidebar__cta__icon icon-phone-ringing"></i> */}
                      +91 9873221469
                    </a>
                  </p>
                </div>
                <div className="contact-one__item">
                  <h3 className="contact-one__item__title">Email</h3>
                  <p className="contact-one__item__text">
                    <Link to="mailto:needhelp@company.com">chain@PHQ.com</Link>
                  </p>
                </div>
                <div className="contact-one__item">
                  <ul className="contact-one__social">
                    <li>
                      <Link to="#">
                        <i className="fab fa-twitter"></i>
                      </Link>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/company/phq-blockchain-technologies/"
                        target="_blank"
                      >
                        <i className="fab fa-linkedin"></i>
                      </a>
                    </li>
                    {/* <li><Link to="#"><i className="fab fa-pinterest-p"></i></Link></li> */}
                    <li>
                      <Link to="#">
                        <i className="fab fa-instagram"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="google-map google-map--contact">
        <img className="google-map__one" src={Map} allowFullScreen />
      </section>
    </>
  );
};

export default Contact;
