import Breadcumb from "../components/Breadcumb/Main";

const Partners = () => {
  return (
    <>
      <Breadcumb Title="Our Partners" Breadcumb="Partners" />
      <section className="project-details section-padding--bottom section-padding--top">
        <div className="container">
          <h3 className="project-details__title">
            <center>This Section Will be Updated Shortly</center>
          </h3>
        </div>
      </section>
    </>
  );
};

export default Partners;
