import Breadcumb from "./Breadcumb/Main";

const FutureUnits = () => {
  return (
    <>
      <Breadcumb Title="Future Units" Breadcumb="Future Units" />
      <section className="project-details section-padding--bottom section-padding--top">
        <div className="container">
          <h3 className="project-details__title">
            <center>This Section Will be Updated Shortly</center>
          </h3>
        </div>
      </section>
    </>
  );
};

export default FutureUnits;
