import Breadcumb from "../Breadcumb/Main";
import { Link } from "react-router-dom";

const SmartProducts = () => {
  return (
    <>
      <Breadcumb Title="Smart Products" Breadcumb="Smart Products" />
      <section className="section-padding--bottom section-padding--top">
        <div className="container">
          <div className="section-title text-center">
            <p className="section-title__text">SMART PRODUCTS</p>
            <h2 className="section-title__title">PHQ Range of Products</h2>
          </div>
          <div className="row gutter-y-30">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div
                className="team-card-one wow fadeInUp"
                data-wow-duration="1500ms"
                data-wow-delay="000ms"
              >
                <div
                  style={{
                    height: "350px",
                  }}
                  className="team-card-one__image"
                >
                  <img
                    style={{
                      height: "100%",
                      width: "100%",
                    }}
                    src="assets/images/team/team-1-1.jpg"
                    alt=""
                  />
                  <div className="team-card-one__social">
                    <ul className="team-card-one__social__links">
                      <li>
                        <Link to="#">
                          <i className="fab fa-twitter"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-facebook"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-pinterest-p"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-instagram"></i>
                        </Link>
                      </li>
                    </ul>
                    <div className="team-card-one__social__icon">
                      <i className="fa fa-share-alt"></i>
                    </div>
                  </div>
                </div>
                <div className="team-card-one__content">
                  <h3 className="team-card-one__title">
                    <a href="#" target="_blank">
                      PHQ Spark GEM
                    </a>
                  </h3>

                  <Link to="">
                    {" "}
                    <p className="team-card-one__designation">Link</p>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div
                className="team-card-one wow fadeInUp"
                data-wow-duration="1500ms"
                data-wow-delay="000ms"
              >
                <div
                  style={{
                    height: "350px",
                  }}
                  className="team-card-one__image"
                >
                  <img
                    style={{
                      height: "100%",
                      width: "100%",
                    }}
                    src="assets/images/team/team-1-1.jpg"
                    alt=""
                  />
                  <div className="team-card-one__social">
                    <ul className="team-card-one__social__links">
                      <li>
                        <Link to="#">
                          <i className="fab fa-twitter"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-facebook"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-pinterest-p"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-instagram"></i>
                        </Link>
                      </li>
                    </ul>
                    <div className="team-card-one__social__icon">
                      <i className="fa fa-share-alt"></i>
                    </div>
                  </div>
                </div>
                <div className="team-card-one__content">
                  <h3 className="team-card-one__title">
                    <a href="#" target="_blank">
                      PHQ P Recorder
                    </a>
                  </h3>

                  <Link to="">
                    {" "}
                    <p className="team-card-one__designation">Link</p>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div
                className="team-card-one wow fadeInUp"
                data-wow-duration="1500ms"
                data-wow-delay="000ms"
              >
                <div
                  style={{
                    height: "350px",
                  }}
                  className="team-card-one__image"
                >
                  <img
                    style={{
                      height: "100%",
                      width: "100%",
                    }}
                    src="assets/images/team/team-1-1.jpg"
                    alt=""
                  />
                  <div className="team-card-one__social">
                    <ul className="team-card-one__social__links">
                      <li>
                        <Link to="#">
                          <i className="fab fa-twitter"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-facebook"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-pinterest-p"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-instagram"></i>
                        </Link>
                      </li>
                    </ul>
                    <div className="team-card-one__social__icon">
                      <i className="fa fa-share-alt"></i>
                    </div>
                  </div>
                </div>
                <div className="team-card-one__content">
                  <h3 className="team-card-one__title">
                    <a href="#" target="_blank">
                      PHQ P Tablet
                    </a>
                  </h3>

                  <Link to="">
                    {" "}
                    <p className="team-card-one__designation">Link</p>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div
                className="team-card-one wow fadeInUp"
                data-wow-duration="1500ms"
                data-wow-delay="000ms"
              >
                <div
                  style={{
                    height: "350px",
                  }}
                  className="team-card-one__image"
                >
                  <img
                    style={{
                      height: "100%",
                      width: "100%",
                    }}
                    src="assets/images/team/team-1-1.jpg"
                    alt=""
                  />
                  <div className="team-card-one__social">
                    <ul className="team-card-one__social__links">
                      <li>
                        <Link to="#">
                          <i className="fab fa-twitter"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-facebook"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-pinterest-p"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-instagram"></i>
                        </Link>
                      </li>
                    </ul>
                    <div className="team-card-one__social__icon">
                      <i className="fa fa-share-alt"></i>
                    </div>
                  </div>
                </div>
                <div className="team-card-one__content">
                  <h3 className="team-card-one__title">
                    <a href="#" target="_blank">
                      PHQ P Phone
                    </a>
                  </h3>

                  <Link to="">
                    {" "}
                    <p className="team-card-one__designation">Link</p>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div
                className="team-card-one wow fadeInUp"
                data-wow-duration="1500ms"
                data-wow-delay="000ms"
              >
                <div
                  style={{
                    height: "350px",
                  }}
                  className="team-card-one__image"
                >
                  <img
                    style={{
                      height: "100%",
                      width: "100%",
                    }}
                    src="assets/images/team/team-1-1.jpg"
                    alt=""
                  />
                  <div className="team-card-one__social">
                    <ul className="team-card-one__social__links">
                      <li>
                        <Link to="#">
                          <i className="fab fa-twitter"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-facebook"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-pinterest-p"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-instagram"></i>
                        </Link>
                      </li>
                    </ul>
                    <div className="team-card-one__social__icon">
                      <i className="fa fa-share-alt"></i>
                    </div>
                  </div>
                </div>
                <div className="team-card-one__content">
                  <h3 className="team-card-one__title">
                    <a href="#" target="_blank">
                      PHQ P Watch
                    </a>
                  </h3>

                  <Link to="">
                    {" "}
                    <p className="team-card-one__designation">Link</p>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div
                className="team-card-one wow fadeInUp"
                data-wow-duration="1500ms"
                data-wow-delay="000ms"
              >
                <div
                  style={{
                    height: "350px",
                  }}
                  className="team-card-one__image"
                >
                  <img
                    style={{
                      height: "100%",
                      width: "100%",
                    }}
                    src="assets/images/team/team-1-1.jpg"
                    alt=""
                  />
                  <div className="team-card-one__social">
                    <ul className="team-card-one__social__links">
                      <li>
                        <Link to="#">
                          <i className="fab fa-twitter"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-facebook"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-pinterest-p"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-instagram"></i>
                        </Link>
                      </li>
                    </ul>
                    <div className="team-card-one__social__icon">
                      <i className="fa fa-share-alt"></i>
                    </div>
                  </div>
                </div>
                <div className="team-card-one__content">
                  <h3 className="team-card-one__title">
                    <a href="#" target="_blank">
                      PHQ P Human Shield
                    </a>
                  </h3>

                  <Link to="">
                    {" "}
                    <p className="team-card-one__designation">Link</p>
                  </Link>
                </div>
              </div>
            </div>

            {/* <div className="col-lg-4 col-md-6 col-sm-12">
						<div className="team-card-one wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="500ms">
							<div className="team-card-one__image">
								<img src="assets/images/team/team-1-6.jpg" alt="" />
								<div className="team-card-one__social">
									<ul className="team-card-one__social__links">
										<li><Link to="#"><i className="fab fa-twitter"></i></Link></li>
										<li><Link to="#"><i className="fab fa-facebook"></i></Link></li>
										<li><Link to="#"><i className="fab fa-pinterest-p"></i></Link></li>
										<li><Link to="#"><i className="fab fa-instagram"></i></Link></li>
									</ul>
									<div className="team-card-one__social__icon">
										<i className="fa fa-share-alt"></i>
									</div>
								</div>
							</div>
							<div className="team-card-one__content">
								<h3 className="team-card-one__title"><Link to="#">Christine Eve</Link></h3>
								
								<p className="team-card-one__designation">IT Expert</p>
							</div>
						</div>
					</div> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default SmartProducts;
