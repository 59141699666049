import Bass1 from "../../assets/images/services/Bass1.jpg";
import Bass2 from "../../assets/images/services/Bass2.jpg";
import Bass3 from "../../assets/images/services/Bass3.jpg";
import Blockchain from "../../assets/Docs/Blockchain.pdf";
import Breadcumb from "../Breadcumb/Main";
import { Link } from "react-router-dom";
import WP from "../../assets/images/Wp.gif";
import { useState } from "react";

const Bass = () => {
  const [clicked, setClicked] = useState(0);
  const faqs = [
    {
      question: "Why Do Organizations Need Blockchain as a Service (BaaS)?",
      answer: (
        <div>
          Over the years, organizations, as well as individuals, are willing to
          use blockchain technology benefits. Due to technical issues and
          operating costs related to the development, configuration, operation,
          and infrastructure maintenance, it seems a significant constraint.
          Blockchain as a service is considered a viable solution to overcome
          these. At present, several industries are incorporating BaaS solutions
          for payments & transactions, supply chain management, Smart Cards, and
          many more. <br />
          <br />
          Anyone from start-ups to giant multinational corporations can use
          Blockchain-as-a-service for tasks, such as;
          <br />
          Improving stocktaking <br />
          Securing Confidential Customer Records
          <br /> Recording Property Ownership <br />
          Helping to launch their Initial Coin Offering (ICO)/IDO <br />
          Develop Hitech Projects <br />
          <br />
          Letting blockchain infrastructure in the shape of BaaS allows
          businesses to develop the skills required to operate blockchain
          infrastructure instantly. As a development in the broad blockchain
          ecosystem, BaaS is emerging blockchain adoption across enterprises.{" "}
        </div>
      ),
    },
    {
      question: "How Does the BaaS Model Work?",
      answer:
        "When an organization signs up for PHQ BaaS, we agree to set up all the necessary Blockchain technology and infrastructure for a service fee defined in the contract agreement. We will also deploy the essentials and maintain Blockchain-connected nodes on behalf of the customer.",
    },
    {
      question: "Cost of Self-Hosted Blockchains vs Cost of BaaS Offering",
      answer:
        "The cost for BaaS varies on several factors, but a Blockchain application hosted on-premises is far costlier than BaaS offered on the cloud. The cost of developing and implementing a single Blockchain smart contract under this model can run up to several hundred thousand dollars. So, BaaS is far more economical.",
    },
    {
      question: "What are the benefits of PHQ BaaS",
      answer:
        "At present, PHQ BaaS is a unique way to streamline the client’s digital transformation process. It can help your business take a competitive advantage without investing in research, planning, and infrastructure. So, what are you waiting for? Just make your business processes more efficient, robust, and secure with our BaaS framework.",
    },
  ];
  return (
    <>
      <Breadcumb Title="Blockchain as a service (BaaS)" Breadcumb="SERVICES" />
      <section className="section-padding--bottom section-padding--top service-details--page">
        <div className="container">
          <div className="row ">
            <div className="col-lg-8">
              <div className="service-details__image">
                <img src={Bass2} alt="" />
              </div>
              <h3 className="service-details__title">
                Blockchain as a service (BaaS)
              </h3>
              <div className="service-details__content">
                <p>
                  BaaS is a managed blockchain platform allowing users to build
                  applications/ digital services on a distributed network while
                  the Baas provider supplies infrastructure and
                  blockchain-building tools. As blockchain application
                  development continues to expand to uses beyond
                  cryptocurrencies, blockchain s a service becomes more in
                  demand as a hosting option. BaaS, lets businesses get
                  applications up and running with minimal hassle. This allows
                  higher agility and quicker blockchain adoption.
                </p>
                <h3 className="service-details__title">
                  Basic Features of PHQ BaaS solution:
                </h3>
                <p>
                  Platform architecture management, Modular concept,
                  preconfigured networks, and infrastructure, Easy setup
                  workflow, Middleware for monitoring &amp; development for app
                  building, Auditable transaction record, Built-in connections
                  to needed services, Professional consultation
                </p>
              </div>
              <div className="row gutter-y-30 service-details__box-wrapper">
                <div className="col-md-6 col-sm-12">
                  <div className="service-details__box">
                    <i className="service-details__box__icon icon-consulting"></i>
                    <div className="service-details__box__content">
                      <h3 className="service-details__box__title">
                        <a href={Blockchain} target="_blank">
                          Blockchain{" "}
                        </a>
                      </h3>
                      <p className="service-details__box__text">
                        Faster Solutions
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <div className="service-details__box">
                    <i className="service-details__box__icon icon-chip"></i>
                    <div className="service-details__box__content">
                      <h3 className="service-details__box__title">
                        <Link to="/all-services">IT Specialist</Link>
                      </h3>
                      <p className="service-details__box__text">
                        Smarter Solutions
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row gutter-y-30">
                <div className="col-md-6 col-sm-12">
                  <img
                    src={Bass3}
                    className="service-details__sub-image"
                    alt=""
                  />
                </div>
                <div className="col-md-6 col-sm-12">
                  <div className="service-details__caption">
                    <h3 className="service-details__caption__title">
                      Our Benefits
                    </h3>
                    <p className="service-details__caption__text">
                      We have Baas solutions for almost every kind of project.
                    </p>
                    <ul className="service-details__caption__list">
                      <li>
                        <i className="fa fa-check-circle"></i>
                        Fastest blockchain integration
                      </li>
                      <li>
                        <i className="fa fa-check-circle"></i>
                        Easily configurable tools
                      </li>
                      <li>
                        <i className="fa fa-check-circle"></i>
                        User-friendly interfaces and APIs
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div
                    className="accrodion-grp service-details__accrodion"
                    data-grp-name="service-details__accrodion-1"
                  >
                    {faqs.map((item, index) => (
                      <div
                        className={`accrodion wow fadeInUp ${
                          index === clicked && "active"
                        }`}
                        key={index}
                        data-wow-delay="0ms"
                      >
                        <div className="accrodion-title">
                          <h4 onClick={() => setClicked(index)}>
                            {item.question}
                            <span className="accrodion-icon"></span>
                          </h4>
                        </div>
                        {index === clicked && (
                          <div className="accrodion-content">
                            <div className="inner">
                              <p>{item.answer}</p>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 sidebar-column">
              <div className="sidebar">
                <div className="sidebar__item sidebar__item--category">
                  <h3 className="sidebar__title">Categories</h3>
                  <ul className="sidebar__category">
                    {/* <li><Link to="/service-01">Bass</Link></li> */}
                    <li>
                      <Link to="/artificial_intelligence">AI</Link>
                    </li>
                    <li>
                      <Link to="/cyber-security">Cyber Security</Link>
                    </li>
                    <li>
                      <Link to="/it-management">IT Management</Link>
                    </li>
                    <li>
                      <Link to="/qa-testing">QA & Testing</Link>
                    </li>
                    <li>
                      <Link to="/big-data">Big Data</Link>
                    </li>
                    <li>
                      <Link to="/web3">Web3</Link>
                    </li>
                    <li>
                      <Link to="/data-analytics">Data Analytics</Link>
                    </li>
                  </ul>
                </div>
                <div className="sidebar__item sidebar__item--cta">
                  <div className="sidebar__cta">
                    <a
                      href="https://api.whatsapp.com/send?phone=+919873221469&text=Hi,"
                      target="_blank"
                      className=""
                      style={{ minHeight: "12vh" }}
                    >
                      <img
                        src={WP}
                        style={{ height: "10vh", marginRight: "10px" }}
                      />
                      {/* <i className="sidebar__cta__icon icon-phone-ringing"></i> */}
                    </a>
                    <h3 className="sidebar__cta__title">
                      Have Tech Problems? Contact Us
                    </h3>
                    <p className="sidebar__cta__text">
                      Whatsapp Anytime <br /> +91 9873221469{" "}
                    </p>
                  </div>
                </div>
                <div className="sidebar__item sidebar__item--btn">
                  <Link to="#" className="thm-btn sidebar__btn dark__button">
                    <span>Download our flyers</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Bass;
