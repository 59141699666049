import AT from "../../../assets/images/At.jpg";
import Breadcumb from "../../Breadcumb/Main";
import CDBCI from "../../../assets/images/CDBCI.jpg";
import CharityI from "../../../assets/images/CharityI.jpg";
import DI from "../../../assets/images/SecureD.jpg";
import FNT from "../../../assets/images/Fnt.jpg";
import HRFIEDI from "../../../assets/images/HRFIEDI.jpg";
import { Link } from "react-router-dom";
import MoneyI from "../../../assets/images/MoneyI.jpg";
import NFTI from "../../../assets/images/NftI.jpg";
import OwlCarousel from "react-owl-carousel";
import Real2 from "../../../assets/images/projects/Real2.jpg";
import Sec4 from "../../../assets/images/Sec4.jpg";
import SecureI from "../../../assets/images/SecureI.jpg";
import TC from "../../../assets/images/TokenI.jpg";
import W from "../../../assets/images/W.jpg";

const RealEstate = () => {
  const options = {
    loop: true,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    smartSpeed: 700,
    items: 1,
    margin: 0,
    nav: false,
    dots: false,
    responsive: {
      0: {
        items: 1,
        margin: 0,
      },
      576: {
        items: 2,
        margin: 30,
      },
      992: {
        items: 3,
        margin: 30,
      },
    },
  };
  return (
    <>
      <Breadcumb Title="Asset Fractionalization" Breadcumb="PROJECT" />
      <section className="project-details section-padding--bottom section-padding--top">
        <div className="container">
          <div className="project-details__image">
            <img src={Real2} alt="" />
          </div>
          <div className="project-details__info">
            <div className="project-details__info__item">
              <h4 className="project-details__info__title">Client:</h4>
              <p className="project-details__info__text">PHQTI</p>
            </div>

            <div className="project-details__info__item">
              <h4 className="project-details__info__title">Category:</h4>
              <p className="project-details__info__text">
                <Link to="#">PHQAST</Link> <Link to="#"></Link>
              </p>
            </div>
            <div className="project-details__info__item">
              <h4 className="project-details__info__title">Year:</h4>
              <p className="project-details__info__text">2023</p>
            </div>
            <div className="project-details__info__item">
              <h4 className="project-details__info__title">Value:</h4>
              <p className="project-details__info__text">USD 47,500</p>
            </div>
            <div className="project-details__info__item">
              <div className="project-details__info__social">
                <Link to="#">
                  <i className="fab fa-twitter"></i>
                </Link>
                <Link to="#">
                  <i className="fab fa-facebook"></i>
                </Link>
                <Link to="#">
                  <i className="fab fa-instagram"></i>
                </Link>
              </div>
            </div>
          </div>
          <h3 className="project-details__title">Asset Fractionalization</h3>
          <div className="project-details__content">
            <p>
              The integration of blockchain technology into real estate has
              triggered a complete overhaul of the real estate domain. It
              has facilitated digital real estate ownership, opening up the
              space to individuals previously left out due to insufficient funds
              and covering up issues like the lack of trust and transparency as
              each and every transaction is recorded on an immutable blockchain,
              where the recorded data can neither be altered not be changed with
              no single entity controlling the entire eco-system. Real estate
              has been quite an illiquid asset compared to gold and company
              stocks, entailing significant capital commitments and long
              transaction processes. Buyers can now execute transactions
              immutably on the PHQ blockchain using tokenized real estate. The
              tokenization of real estate refers to the process of fragmenting
              an asset into digital tokens that represent the underlying
              property with all its rights and obligations. Buyers get a
              fraction of the property.{" "}
            </p>
            <p>
              Real estate tokenization works similarly to the crowdfunding
              process, where an asset is partitioned into small fractions with
              the help of a blockchain architecture, triggered by the action in
              the front end through APIs connected to the blockchain. Anyone who
              buys or owns a token owns a part of the real estate. They are
              entitled to the underlying asset and the associated profits and
              losses. The buyer gets a block hash and transaction id after
              making the payments for the fractional token.
            </p>
            <br />
            <p
              class=""
              style={{
                marginTop: "30px",
                marginBottom: "15px",
                color: "black",
                fontSize: "20px",
              }}
            >
              BENEFITS OF PHQ TOKENIZATION SYSTEM::
            </p>
            <ul className="project-details__list pl">
              <li>
                <i className="fa fa-check-circle"></i>
                Any kind of real estate can be tokenized whether it is
                commercial, residential, or trophy
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                One could liquidate their trophy asset through tokenization
                while raising funds for projects
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                All transactions will be recorded on the PHQ blockchain
              </li>

              <li>
                <i className="fa fa-check-circle"></i>
                There is zero gas fee for any kind of transaction on the PHQ
                blockchain
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                Easy onboarding for sellers and buyers
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                Faster liquidity and in safe hands
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                One of the world’s best tech team
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                One of the world’s best tech team
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                The benefits are paperless working, better liquidity, decrease
                in counterparty risk
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                Easily scalable and adaptable
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                RET Project developed on the world’s fastest blockchain
                technology (The PHQ Blockchain)
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="project-details section-padding--bottom footer-widget__newsletter mc-form">
        <center>
          <a href="#" target="_blank" className="thm-btn about-four__btn">
            <span>Whitepaper</span>
          </a>
        </center>
      </section>
      <div className="project-nav">
        <div className="container">
          <div className="project-nav__inner">
            <Link to="/spark-gem-project">
              <i className="icon-left-arrow"></i>
              Previous
            </Link>
            <Link to="/payment-gateway">
              Next
              <i className="icon-right-arrow"></i>
            </Link>
          </div>
        </div>
      </div>

      <section className="section-padding--top section-padding--bottom">
        <div className="container">
          <div className="section-title text-center">
            <p className="section-title__text">Other PHQ Products</p>
            <h2 className="section-title__title">
              Check What We Have <br /> Developed?
            </h2>
          </div>

          <OwlCarousel className="thm-owl__carousel" {...options}>
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={SecureI} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text">PHQ.SEMS.V1.2023</p>
                    <h3 className="project-card-one__title">
                      <Link to="/secured-email-system">
                        Secured Email System
                      </Link>
                    </h3>
                    <Link
                      to="/secured-email-system"
                      className="project-card-one__more"
                    >
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={TC} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text">PHQ.TCT.V1.2023</p>
                    <h3 className="project-card-one__title">
                      <Link to="/token_creation_tool">Token Creation Tool</Link>
                    </h3>
                    <Link
                      to="/token_creation_tool"
                      className="project-card-one__more"
                    >
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={FNT} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text">PHQ.FNT.V1.2023</p>
                    <h3 className="project-card-one__title">
                      <Link to="/fntasy">Fntasy Gaming</Link>
                    </h3>
                    <Link to="/fntasy" className="project-card-one__more">
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={DI} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text">PHQ.SDI.V1.2023</p>
                    <h3 className="project-card-one__title">
                      <Link to="/secure-digital-identity">
                        Secured Digital Identity
                      </Link>
                    </h3>
                    <Link
                      to="/secure-digital-identity"
                      className="project-card-one__more"
                    >
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="item">
							<div className="project-card-one">
								<div className="project-card-one__image">
									<img src={AT} alt="" />
								</div>
								<div className="project-card-one__content">
									<div className="project-card-one__content__inner">
										<p className="project-card-one__text">PHQ.ATS.V1.2023</p>
										<h3 className="project-card-one__title"><Link to="/asset_tokenization">Asset Tokenization System</Link></h3>
										<Link to="/asset_tokenization" className="project-card-one__more">
											<i className="fa fa-angle-right"></i>
										</Link>
									</div>
								</div>
							</div>
						</div> */}
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={HRFIEDI} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text"> PHQ.HRF.V1.2023</p>
                    <h3 className="project-card-one__title">
                      <Link to="/hrfied">Hrfied</Link>
                    </h3>
                    <Link to="/hrfied" className="project-card-one__more">
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={CDBCI} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text"> PHQ.CDBC.V1.2023</p>
                    <h3 className="project-card-one__title">
                      <Link to="/central_bank_digital_currency">
                        Central Bank Digital Currency
                      </Link>
                    </h3>
                    <Link
                      to="/central_bank_digital_currency"
                      className="project-card-one__more"
                    >
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={W} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text">
                      {" "}
                      PHQ.WALLET.V1.2023
                    </p>
                    <h3 className="project-card-one__title">
                      <Link to="/cryptocurrency-wallet">
                        Cryptocurrency Wallet
                      </Link>
                    </h3>
                    <Link
                      to="/cryptocurrency-wallet"
                      className="project-card-one__more"
                    >
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={MoneyI} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text"> PHQ.MTRN.V1.2023</p>
                    <h3 className="project-card-one__title">
                      <Link to="/money-transfer">Money Transfer</Link>
                    </h3>
                    <Link
                      to="/money-transfer"
                      className="project-card-one__more"
                    >
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={CharityI} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text">
                      {" "}
                      PHQ.Charity.V1.2023
                    </p>
                    <h3 className="project-card-one__title">
                      <Link to="/charity">Charity</Link>
                    </h3>
                    <Link to="/charity" className="project-card-one__more">
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={NFTI} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text"> PHQ.NFT.V1.2023</p>
                    <h3 className="project-card-one__title">
                      <Link to="/nft-market-place">NFT Marketplace</Link>
                    </h3>
                    <Link
                      to="/nft-market-place"
                      className="project-card-one__more"
                    >
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </OwlCarousel>
        </div>
      </section>
    </>
  );
};

export default RealEstate;
