import { createContext, useContext, useLayoutEffect, useState } from "react";

const ThemeContext = createContext();

export const useTheme = () => {
  return useContext(ThemeContext);
};

export const ThemeProvider = ({ children }) => {
  // Your existing hook logic here
  const [currentTheme, setCurrentTheme] = useState(() => {
    const themeFromLocalStorage = localStorage.getItem("theme");
    return (
      themeFromLocalStorage || "light"
      // (window.matchMedia("(prefers-color-scheme: dark)").matches
      //   ? "dark"
      //   : "light")
    );
  });

  useLayoutEffect(() => {
    document.documentElement.setAttribute("data-theme", currentTheme);
    localStorage.setItem("theme", currentTheme);
    if (currentTheme === "dark") {
      document.body.classList.add("dark-theme");
    } else {
      document.body.classList.remove("dark-theme");
    }
  }, [currentTheme]);

  const toggleTheme = () => {
    setCurrentTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
    if (currentTheme === "dark") {
      document.body.classList.add("dark-theme");
    } else {
      document.body.classList.remove("dark-theme");
    }
  };

  const contextValue = [currentTheme, toggleTheme];

  return (
    <ThemeContext.Provider value={contextValue}>
      {children}
    </ThemeContext.Provider>
  );
};
