import Breadcumb from "../components/Breadcumb/Main";

const AboutUs = () => {
  return (
    <>
      <Breadcumb Title="About Us" Breadcumb="ABOUT US" />
      <section className="project-details section-padding--bottom section-padding--top">
        <div className="container">
          <h3 className="project-details__title">1. Brief History:</h3>
          <div className="project-details__content">
            <p>
              PHQ was started back in 2017 with a team striving to provide
              technology solutions to industries and one-off consumers alike. We
              identified several flaws and drawbacks in the classic centralized
              distribution of technology and services. We studied the market
              risks associated with a centralized architecture and recognized
              the potential of a decentralized future. Just within the scope of
              blockchains themselves, the power consumption of the technology
              meant that it was not scalable in an environmentally friendly
              manner, nor could it keep up with the demand of the market thanks
              to the speed constraints of the then-available solutions. Over a
              span of about 3 years, our team equipped itself with market
              insights and a vision to provide fast, eco- friendly, and scalable
              blockchain-based solutions to several market needs.
            </p>

            <br />
            <h3 className="project-details__title">2. The Blockchain:</h3>
            <p>
              The PHQ blockchain was then built over a course of another 2 years
              and our team’s tireless efforts finally conceived the world’s
              fastest and only blockchain to have no gas fee at all. By March
              2022, our blockchain was fully operable and was ready for final,
              extensive testing and proved to handle even the most extreme cases
              of blockchain use. On August 31, 2022, PHQ Technologies
              Incorporation (General Corporation) was created in the USA to
              provide blockchain solutions and state-of-the-art services. The
              first block, called the Genesis Block, was added to the final and
              ready-to-use version of the blockchain on September 4, 2022.
            </p>

            <br />
            <h3 className="project-details__title">3. Solutions:</h3>
            <p>
              PHQ Technologies Incorporation explores and provides modern,
              unique, and revolutionary solutions to real market problems in a
              scalable manner. Our blockchain-backed solutions are at the
              forefront of innovation thanks to our continuous and rigorous
              R&amp;D and our motivated team. We also provide several other IT
              services including but not limited to, Blockchain consultancy and
              development, decentralized application development (DApps), cyber
              security, Big Data and analytics, AI-based solutions, cross-chain
              integrations, PHQ cloud services for our blockchain users, and web
              development.
            </p>

            <br />
            <h3 className="project-details__title">4. Projects:</h3>
            <p>
              PHQ undertakes projects of the future that solve problems of the
              present. We have deployed and are currently developing several
              projects both in-house and in collaboration with partners. Some of
              our most highlighted projects are:
            </p>
            <br />
            <ul>
              <li>CBDC</li>
              <li>Secure e-mail system</li>
              <li>
                Asset tokenization of real estate, real gold, bonds (Fractional)
              </li>
              <li>Security tokens</li>
              <li>Public, Private and Hybrid blockchains</li>
              <li>Token creation with no smart contract</li>
              <li>Cross-border payment</li>
              <li>Multi-chain wallet</li>
              <li>HRfied</li>
              <li>Secure digital identity</li>
              <li>Decentralized Exchange</li>
              <li>Launchpad</li>
              <li>Blockchain-based messenger</li>
              <li>Freelance platform</li>
              <li>KYC</li>
              <li>Medical records</li>
              <li>Zero gas fee NFT marketplace</li>
              <li>Auctions</li>
              <li>E-commerce</li>
            </ul>
            <br />
            <h3 className="project-details__title">5. PHQ services:</h3>

            <p>
              Blockchain development (Blockchain development and consultancy).
              We provide Blockchain consultancy, development, and cross-chain
              integrations for private, public as well as hybrid blockchains.
              All our blockchains are powered by state-of-the-art algorithms
              with no compromise on speed or security. We can provide blockchain
              solutions on any scale, for small businesses, large corporations,
              and the entire world:
            </p>
            <br />
            <ul>
              <li>Blockchain as a service</li>
              <li>DApps (decentralized application development)</li>
              <li>Cyber Security (High-tech cyber security solutions)</li>
              <li>Big Data (Big Data solutions and analytics)</li>
              <li>AI solutions – (Artificial intelligence-based projects)</li>
              <li>Data analytics</li>
              <li>Web3 solutions</li>
              <li>Software testing </li>
            </ul>
          </div>
        </div>{" "}
      </section>
    </>
  );
};

export default AboutUs;
