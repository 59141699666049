import { Suspense, useState } from "react";

import AI2 from "../../../assets/images/services/AI2.jpg";
import AI3 from "../../../assets/images/services/AI3.jpg";
import Blockchain from "../../../assets/Docs/Blockchain.pdf";
import Breadcumb from "../../Breadcumb/Main";
import { Link } from "react-router-dom";
import ServiceSidebar from "../ServiceSidebar/Main";
const CyberSecurity = () => {
  const [clicked, setClicked] = useState(0);
  const faqs = [
    {
      question: "Which projects will be developed by PHQ?   ",
      answer:
        "Price predictions, recommendations, chat/banking bots, virtual assistants, facial Emotion Recognition/detection, online Assignment plagiarism checker, personality prediction, heart disease/ other disease prediction, Differentiate the music genre from an audio file, Image reconstruction by using an occluded scene Identify human emotions through pictures. ",
    },
    {
      question: "What are the main AI technologies used by PHQ? ",
      answer:
        "NL generation, speech recognition, virtual agents, ML, AI hardware, decision management, DL, biometrics, robotics, text analysis, cyber defense, compliance, knowledge worker aid, emotion/image recognition, and marketing automation.",
    },
    {
      question: "Can artificial intelligence compete with human intelligence? ",
      answer:
        "People will always be faster to adjust than computers because that's what humans are optimized to do.",
    },
    {
      question: "What are the advantages of Natural Language Processing?",
      answer:
        "NLP is a way for computers to analyze, understand and derive meaning from human language in a smart and useful way.",
    },
    {
      question: "What is the future of artificial intelligence?",
      answer:
        "Automated transport, taking over dangerous jobs, robots working with humans, improved elderly care, cyborg (organic/bio-mechanic) organisms, environment monitoring, and response to climate change goals.",
    },
  ];

  return (
    <>
      <Suspense
        fallback={
          <div class="preloader">
            <div class="preloader__circle"></div>
          </div>
        }
      >
        <Breadcumb Title="Artificial Intelligence (AI)" Breadcumb="SERVICES" />
        <section className="section-padding--bottom section-padding--top service-details--page">
          <div className="container">
            <div className="row ">
              <div className="col-lg-8">
                <div className="service-details__image">
                  <img src={AI2} alt="" />
                </div>
                <h3 className="service-details__title">
                  Artificial Intelligence (AI)
                </h3>
                <div className="service-details__content">
                  <p>
                    AI refers to the simulation of human intelligence in
                    machines that are programmed to think like humans and mimic
                    their actions and also be applied to any machine that
                    exhibits traits associated with a human mind. The ideal
                    characteristic of AI is its ability to rationalize and take
                    actions that have the best chance of achieving a specific
                    goal. A subset of AI is machine learning, which refers to
                    the concept that computer programs can automatically learn
                    from and adapt to new data without being assisted by
                    humans. 
                  </p>
                  <h4>AI can be categorized into four types</h4>
                  <span>
                    Reactive AI uses algorithms to optimize outputs based on a
                    set of inputs. Limited memory AI can adapt to the past and
                    update itself based on new observations or data.
                  </span>
                  <br />
                  <span>
                    Theory-of-mind AI is fully-adaptive and has an extensive
                    ability to learn and retain past experiences.
                  </span>
                  <br />
                  <span>
                    Self-aware AI, as the name suggests, become sentient and
                    aware of their own existence.
                  </span>
                </div>
                <div className="row gutter-y-30 service-details__box-wrapper">
                  <div className="col-md-6 col-sm-12">
                    <div className="service-details__box">
                      <i className="service-details__box__icon icon-consulting"></i>
                      <div className="service-details__box__content">
                        <h3 className="service-details__box__title">
                          <a href={Blockchain} target="_blank">
                            Blockchain{" "}
                          </a>
                        </h3>
                        <p className="service-details__box__text">
                          Faster Solutions
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <div className="service-details__box">
                      <i className="service-details__box__icon icon-chip"></i>
                      <div className="service-details__box__content">
                        <h3 className="service-details__box__title">
                          <Link to="/all-services">IT Specialist</Link>
                        </h3>
                        <p className="service-details__box__text">
                          Smarter Solutions
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row gutter-y-30">
                  <div className="col-md-6 col-sm-12">
                    <img
                      src={AI3}
                      className="service-details__sub-image"
                      alt=""
                    />
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <div className="service-details__caption">
                      <h3 className="service-details__caption__title">
                        Our Benefits
                      </h3>
                      <p className="service-details__caption__text">
                        We have AI experts capable of handling any kind of
                        complex project.
                      </p>
                      <ul className="service-details__caption__list">
                        <li>
                          <i className="fa fa-check-circle"></i>
                          Machine / Deep Learning Engineer
                        </li>
                        <li>
                          <i className="fa fa-check-circle"></i>
                          Robotic / Data / Research Scientist
                        </li>
                        <li>
                          <i className="fa fa-check-circle"></i>
                          Business Intelligence Developer
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div
                      className="accrodion-grp service-details__accrodion"
                      data-grp-name="service-details__accrodion-1"
                    >
                      {faqs.map((item, index) => (
                        <div
                          className={`accrodion wow fadeInUp ${
                            index === clicked && "active"
                          }`}
                          key={index}
                          data-wow-delay="0ms"
                        >
                          <div className="accrodion-title">
                            <h4 onClick={() => setClicked(index)}>
                              {item.question}
                              <span className="accrodion-icon"></span>
                            </h4>
                          </div>
                          {index === clicked && (
                            <div className="accrodion-content">
                              <div className="inner">
                                <p>{item.answer}</p>
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 sidebar-column">
                <ServiceSidebar />
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
};

export default CyberSecurity;
