import Asset from "../../../assets/images/blog/asset.jpg";
import Breadcumb from "../../Breadcumb/Main";
import { Link } from "react-router-dom";
import NFT from "../../../assets/images/blog/Blockchain.jpg";
import cdbc from "../../../assets/images/blog/cdbc.jpg";
import web3 from "../../../assets/images/blog/web3.jpg";
const Blogs = () => {
  return (
    <>
      <Breadcumb Title="Blog" Breadcumb="BLOG" />
      <section className="section-padding--top section-padding--bottom">
        <div className="container">
          <div className="row gutter-y-30">
            <div
              className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp"
              data-wow-duration="1500ms"
              data-wow-delay="000ms"
            >
              <div className="blog-card-one">
                <div className="blog-card-one__image">
                  <img src={Asset} alt="" />
                  <Link to="/blog-details"></Link>
                </div>
                <div className="blog-card-one__content">
                  <div className="blog-card-one__meta">
                    <div className="blog-card-one__date">
                      <i className="fa fa-calendar-alt" aria-hidden="true"></i>
                      Feb 14, 2023
                    </div>
                    <Link to="/blog" className="blog-card-one__category">
                      TOKENIZATION
                    </Link>
                  </div>
                  <h3 className="blog-card-one__title">
                    <Link to="/asset-blog-details">
                      Asset tokenization and fractional investment
                    </Link>
                  </h3>
                  <Link
                    to="/asset-blog-details"
                    className="blog-card-one__more"
                  >
                    Read More
                    <i className="fa fa-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp"
              data-wow-duration="1500ms"
              data-wow-delay="100ms"
            >
              <div className="blog-card-one">
                <div className="blog-card-one__image">
                  <img src={NFT} alt="" />
                  <Link to="/nft-blog-details"></Link>
                </div>
                <div className="blog-card-one__content">
                  <div className="blog-card-one__meta">
                    <div className="blog-card-one__date">
                      <i className="fa fa-calendar-alt" aria-hidden="true"></i>
                      Feb 11, 2023
                    </div>
                    <Link to="/blog" className="blog-card-one__category">
                      BLOCKCHAIN
                    </Link>
                  </div>
                  <h3 className="blog-card-one__title">
                    <Link to="/blockchain-blog-details">
                      Blockchain and why use PHQ Blockchain?
                    </Link>
                  </h3>
                  <Link
                    to="/blockchain-blog-details"
                    className="blog-card-one__more"
                  >
                    Read More
                    <i className="fa fa-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp"
              data-wow-duration="1500ms"
              data-wow-delay="200ms"
            >
              <div className="blog-card-one">
                <div className="blog-card-one__image">
                  <img src={web3} alt="" />
                  <Link to="/blog-details"></Link>
                </div>
                <div className="blog-card-one__content">
                  <div className="blog-card-one__meta">
                    <div className="blog-card-one__date">
                      <i className="fa fa-calendar-alt" aria-hidden="true"></i>
                      Feb 07, 2023
                    </div>
                    <Link to="/blog" className="blog-card-one__category">
                      Web3
                    </Link>
                  </div>
                  <h3 className="blog-card-one__title">
                    <Link to="/web3-blog-details">
                      Web3 the future technology requirement
                    </Link>
                  </h3>
                  <Link to="/web3-blog-details" className="blog-card-one__more">
                    Read More
                    <i className="fa fa-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp"
              data-wow-duration="1500ms"
              data-wow-delay="300ms"
            >
              <div className="blog-card-one">
                <div className="blog-card-one__image">
                  <img src={cdbc} alt="" />
                  <Link to="/cdbc-blog-details"></Link>
                </div>
                <div className="blog-card-one__content">
                  <div className="blog-card-one__meta">
                    <div className="blog-card-one__date">
                      <i className="fa fa-calendar-alt" aria-hidden="true"></i>
                      Feb 01, 2023
                    </div>
                    <Link to="/blog" className="blog-card-one__category">
                      CDBC
                    </Link>
                  </div>
                  <h3 className="blog-card-one__title">
                    <Link to="/cdbc-blog-details">Blockchain for CBDC </Link>
                  </h3>
                  <Link to="/cdbc-blog-details" className="blog-card-one__more">
                    Read More
                    <i className="fa fa-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </div>
            {/* <div className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-duration="1500ms"
                            data-wow-delay="400ms">
                            <div className="blog-card-one">
                                <div className="blog-card-one__image">
                                    <img src="assets/images/blog/blog-1-5.jpg" alt="" />
                                    <Link to="/blog-details"></Link>
                                </div>
                                <div className="blog-card-one__content">
                                    <div className="blog-card-one__meta">
                                        <div className="blog-card-one__date">
                                            <i className="fa fa-calendar-alt" aria-hidden="true"></i>
                                            July, 25, 2022
                                        </div>
                                        <Link to="/blog" className="blog-card-one__category">Graphic</Link>
                                    </div>
                                    <h3 className="blog-card-one__title"><Link to="/blog-details">Capitalize on low hanging
                                        fruit to identify a ballpark </Link></h3>
                                    <Link to="/blog-details" className="blog-card-one__more">
                                        Read More
                                        <i className="fa fa-arrow-right"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-duration="1500ms"
                            data-wow-delay="500ms">
                            <div className="blog-card-one">
                                <div className="blog-card-one__image">
                                    <img src="assets/images/blog/blog-1-6.jpg" alt="" />
                                    <Link to="/blog-details"></Link>
                                </div>
                                <div className="blog-card-one__content">
                                    <div className="blog-card-one__meta">
                                        <div className="blog-card-one__date">
                                            <i className="fa fa-calendar-alt" aria-hidden="true"></i>
                                            July, 25, 2022
                                        </div>
                                        <Link to="/blog" className="blog-card-one__category">SEO</Link>
                                    </div>
                                    <h3 className="blog-card-one__title"><Link to="/blog-details">User generated content in
                                        real-time will have multiple </Link></h3>
                                    <Link to="/blog-details" className="blog-card-one__more">
                                        Read More
                                        <i className="fa fa-arrow-right"></i>
                                    </Link>
                                </div>
                            </div>
                        </div> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Blogs;
