import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

import Blockchain from "../../assets/Docs/Blockchain.pdf";
import Logo from "../../assets/images/PHQ.png";
import Nodes from "../../assets/Docs/Nodes.pdf";
import WP from "../../assets/images/Wp.gif";
import Whitepaper from "../../assets/Docs/Whitepaper.pdf";
import { useTheme } from "../../utils/useTheme";

const Footer = () => {
  const location = useLocation();
  const [footerPadding, setfooterPadding] = useState(false);
  useEffect(() => {
    if (location.pathname === "/" || location.pathname === "/service-01") {
      setfooterPadding(true);
    } else {
      setfooterPadding(false);
    }
  }, [location]);

  return (
    <>
      <div
        className={`footer-main section-padding--bottom ${
          footerPadding ? "footer-main--top-padding" : "section-padding--top"
        }`}
      >
        <div className="container">
          <div className="row gutter-y-30">
            <div className="col-lg-2 col-md-6 col-sm-12">
              <div className="footer-widget footer-widget--about">
                <Link to="/" className="footer-widget__logo">
                  <img src={Logo} width="" height="50" alt="" />
                </Link>
                <p className="footer-widget__text">
                  We work with a passion for taking challenges and creating new
                  ones in the DLT industry
                </p>
                <ul className="footer-widget__social">
                  <li>
                    <Link to="#">
                      <i className="fab fa-twitter"></i>
                    </Link>
                  </li>
                  {/* <li><Link to="#"><i className="fab fa-facebook-f"></i></Link></li> */}
                  <li>
                    <a
                      href="https://www.linkedin.com/company/phq-blockchain-technologies/"
                      target="_blank"
                    >
                      <i className="fab fa-linkedin"></i>
                    </a>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="fab fa-instagram"></i>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-12">
              <div className="footer-widget footer-widget--about">
                <h3 className="footer-widget__title">Documentation</h3>
                <ul className="footer-widget__links">
                  <li>
                    <a href={Blockchain} target="_blank">
                      AI-DLT
                    </a>
                  </li>
                  <li>
                    {" "}
                    <a href={Whitepaper} target="_blank">
                      Business paper
                    </a>
                  </li>
                  <li>
                    {" "}
                    <a href={Nodes} target="_blank" style={{ color: "" }}>
                      Nodes
                    </a>
                  </li>
                  <li>
                    {" "}
                    <Link to="/rewards">Rewards</Link>
                  </li>
                  <li>
                    {" "}
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                  <li>
                    {" "}
                    <Link to="/cookie-policy">Cookie Policy</Link>
                  </li>
                  <li>
                    {" "}
                    <Link to="/terms-and-conditions">Terms & Conditions </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="footer-widget footer-widget--links">
                <h3 className="footer-widget__title">Services</h3>
                <ul className="footer-widget__links">
                  <li>
                    <Link to="/bass">Bass</Link>
                  </li>
                  <li>
                    <Link to="/artificial_intelligence">AI</Link>
                  </li>
                  <li>
                    <Link to="/cyber-security">Cyber Security</Link>
                  </li>
                  <li>
                    <Link to="/it-management">IT Management</Link>
                  </li>
                  <li>
                    <Link to="/qa-testing">QA & Testing</Link>
                  </li>
                  <li>
                    <Link to="/big-data">Big Data</Link>
                  </li>
                  <li>
                    <Link to="/web3">Web3</Link>
                  </li>
                  <li>
                    <Link to="/data-analytics">Data Analytics</Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-2 col-md-6 col-sm-12">
              <div className="footer-widget footer-widget--newsletter">
                <h3 className="footer-widget__title">Subscribe</h3>
                <p className="footer-widget__text">Sign up for updates</p>

                <form
                  action="#"
                  className="footer-widget__newsletter mc-form"
                  data-url="ADD_YOUR_MAILCHIMP_FORM_URL_HERE"
                >
                  <input
                    type="email"
                    name="EMAIL"
                    placeholder="Enter Your Email"
                  />
                  <button className="thm-btn footer-widget__newsletter__btn">
                    <span>Register</span>
                  </button>
                </form>
                <div className="mc-form__response"></div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="footer-widget footer-widget--contact">
                <h3 className="footer-widget__title">Contact</h3>
                {/* <ul className="footer-widget__contact"> */}
                <ul className="footer-widget__links">
                  <li>
                    <Link to="/contact">Contact Details</Link>
                  </li>

                  {/* <li style={{ paddingLeft: "0px" }}>
                    <a
                      href="https://api.whatsapp.com/send?phone=+919873221469&text=Hi,"
                      target="_blank"
                      className=""
                      style={{ minHeight: "12vh" }}
                    >
                      <img
                        src={WP}
                        style={{ height: "2.5vh", marginRight: "10px" }}
                      />
                      +91 9873221469
                    </a>
                  </li>
                  <li>
                    <i className="fa fa-envelope"></i>
                    <Link to="mailto:needhelp@company.com">chain@PHQ.com</Link>
                  </li> */}
                  {/* <li>
                    <i className="fa fa-map-marker-alt"></i>
                    PHQ Technologies Inc. <br /> Delaware, USA
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <p>
            &copy; 1995-2024 All Copyright{" "}
            <span className="dynamic-year"></span> by PHQ
          </p>
        </div>
      </div>
    </>
  );
};

export default Footer;
