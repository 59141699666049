import { Link } from "react-router-dom";
import WP from "../../../assets/images/Wp.gif";

const ServiceSidebar = () => {
  return (
    <div className="sidebar">
      <div className="sidebar__item sidebar__item--category">
        <h3 className="sidebar__title">Categories</h3>
        <ul className="sidebar__category">
          <li>
            <Link to="/bass">Bass</Link>
          </li>
          <li>
            <Link to="/cyber-security">Cyber Security</Link>
          </li>
          <li>
            <Link to="/it-management">IT Management</Link>
          </li>
          <li>
            <Link to="/qa-testing">QA & Testing</Link>
          </li>
          <li>
            <Link to="/infrastructure-plan">Big Data</Link>
          </li>
          <li>
            <Link to="/web3">Web3</Link>
          </li>
          <li>
            <Link to="/data-analytics">Data Analytics</Link>
          </li>
        </ul>
      </div>
      <div className="sidebar__item sidebar__item--cta">
        <div className="sidebar__cta">
          <a
            href="https://api.whatsapp.com/send?phone=+919873221469&text=Hi,"
            target="_blank"
            className=""
            style={{ minHeight: "12vh" }}
          >
            <img src={WP} style={{ height: "10vh", marginRight: "10px" }} />
            {/* <i className="sidebar__cta__icon icon-phone-ringing"></i> */}
          </a>
          <h3 className="sidebar__cta__title">
            Have Tech Problems? Contact Us
          </h3>
          <p className="sidebar__cta__text">
            Whatsapp Anytime <br /> +91 9873221469{" "}
          </p>
        </div>
      </div>
      <div className="sidebar__item sidebar__item--btn">
        <Link to="#" className="thm-btn sidebar__btn dark__button">
          <span>Download our flyers</span>
        </Link>
      </div>
    </div>
  );
};

export default ServiceSidebar;
