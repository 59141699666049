import BlogSidebar from "../BlogSidebar/Main";
import Breadcumb from "../../Breadcumb/Main";
import Cdbc1 from "../../../assets/images/blog/cdbc1.jpg";
import { Link } from "react-router-dom";

const Cdbc = () => {
  return (
    <>
      <Breadcumb Title="Blog Details" Breadcumb="BLOG" />
      <section className="section-padding--bottom section-padding--top">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="blog-details__image">
                <img src={Cdbc1} alt="" />
              </div>
              <div className="blog-card-one__meta">
                <div className="blog-card-one__date">
                  <i className="fa fa-calendar-alt" aria-hidden="true"></i>
                  Feb 14, 2023
                </div>
                <Link to="#" className="blog-card-one__category">
                  PHQTI
                </Link>
              </div>
              <h3 className="blog-card-one__title blog-details__title">
                Blockchain for CBDC
              </h3>
              <div className="blog-details__content">
                <p>
                  Central Bank Digital Currencies (CBDCs) are digital currencies
                  issued by central banks and backed by the reserves of a
                  government. CBDCs generally have the same properties as
                  traditional fiat money, meaning they can be used to securely
                  store and transfer value. But unlike other types of digital
                  currencies, such as Bitcoin, CBDCs are not subject to the same
                  market fluctuations.
                </p>
                <p>
                  The minting process for CBDCs is similar to that of
                  traditional fiat money: it is overseen by a central authority
                  and can be created in large quantities as needed. CBDCs can
                  also be transferred from one user to another, much like
                  traditional forms of money.
                </p>
                <p>
                  CBDCs minted on PHQ Blockchain are associated with
                  &quot;zero-gas fees,&quot; meaning there are no fees
                  associated with creating, minting, storing, and transferring
                  digital currency. This is a major advantage for both banks and
                  businesses, as it makes the currency more accessible to use.
                </p>
                <p>
                  Unlike other digital currencies, CBDCs are not mined. Mining
                  is a process that requires a significant amount of computer
                  power and energy to create and verify new coins. By removing
                  this process, the government can provide a more secure form of
                  currency that is not subject to the same risks as other
                  digital currencies.
                </p>
                <p>
                  In sum, CBDCs provide a way for governments to create a
                  digital, secure currency that can be used by all users. The
                  process of minting and transferring the currency is overseen
                  by a centralized authority, and it is generally free of
                  transaction fees. Additionally, the absence of a mining
                  process helps ensure that the currency is secure and not
                  subject to the same market fluctuations as other digital
                  currencies.
                </p>
                <p>CBDCs will replace Crypto Currency!</p>
              </div>
              <div className="blog-details__meta">
                <div className="blog-details__tags">
                  <span>Tags</span>
                  <Link to="#">CBDC</Link>
                  <Link to="#">CRYPTOCURRENCY</Link>
                  <Link to="#">GOVERNMENT</Link>
                  <Link to="#">FIAT MONEY</Link>
                </div>
                <ul className="blog-details__share">
                  <li>
                    <Link to="#">
                      <i className="fab fa-twitter"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="fab fa-facebook"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="fab fa-pinterest-p"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="fab fa-instagram"></i>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="author-one">
                <div className="author-one__image">
                  <img src="assets/images/blog/author-1-1.jpg" alt="" />
                </div>
                <div className="author-one__content">
                  <h3 className="author-one__title">PHQTI </h3>
                  <p className="author-one__text"></p>
                </div>
              </div>
              <div className="comments-one">
                <h3 className="comments-one__title">2 Comments</h3>
                <div className="comments-one__item">
                  <div className="comments-one__item__image">
                    <img src="assets/images/blog/comment-1-1.png" alt="" />
                  </div>
                  <div className="comments-one__item__content">
                    <h3 className="comments-one__item__title">Kevin Martin</h3>

                    <p className="comments-one__item__text">
                      It has survived not only five centuries, but also the leap
                      into electronic typesetting simply fee text aunchanged. It
                      was popularised in the sheets containing lorem ipsum is
                      simply free text.
                    </p>

                    <Link to="#" className="thm-btn comments-one__item__btn">
                      <span>Reply</span>
                    </Link>
                  </div>
                </div>
                <div className="comments-one__item">
                  <div className="comments-one__item__image">
                    <img src="assets/images/blog/comment-1-2.png" alt="" />
                  </div>
                  <div className="comments-one__item__content">
                    <h3 className="comments-one__item__title">Sarah Albert</h3>

                    <p className="comments-one__item__text">
                      It has survived not only five centuries, but also the leap
                      into electronic typesetting simply fee text aunchanged. It
                      was popularised in the sheets containing lorem ipsum is
                      simply free text.
                    </p>

                    <Link to="#" className="thm-btn comments-one__item__btn">
                      <span>Reply</span>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="comment-form">
                <h3 className="comment-form__title">Leave a Comment</h3>
                <form
                  action="assets/inc/sendemail.php"
                  className="contact-one__form contact-form-validated comment-form__form"
                >
                  <div className="row ">
                    <div className="col-lg-6 col-md-12">
                      <input type="text" placeholder="Your name" name="name" />
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <input
                        type="email"
                        placeholder="Email address"
                        name="email"
                      />
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <textarea
                        name="message"
                        placeholder="Write message"
                      ></textarea>
                    </div>
                    <div className="col-md-12">
                      <button
                        className="thm-btn contact-one__btn"
                        type="submit"
                      >
                        <span>Submit comment</span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="sidebar">
                <div className="sidebar__item sidebar__item--search">
                  <form action="#" className="sidebar__search">
                    <label for="search" className="sr-only">
                      search here
                    </label>
                    <input type="text" placeholder="Search Here" />
                    <button
                      type="submit"
                      aria-label="search submit"
                      className="thm-btn"
                    >
                      <span>
                        <i className="icon-magnifying-glass"></i>
                      </span>
                    </button>
                  </form>
                </div>
                <div className="sidebar__item sidebar__item--posts">
                  <h3 className="sidebar__title">Latest Posts</h3>
                  <ul className="sidebar__posts">
                    <li>
                      <div className="sidebar__posts__image">
                        <img src="assets/images/blog/blog-s-1-1.png" alt="" />
                      </div>
                      <div className="sidebar__posts__content">
                        <div className="sidebar__posts__date">
                          <i className="fa fa-comments"></i>2 Comments
                        </div>
                        <h3 className="sidebar__posts__title">
                          <Link to="/blog-details">
                            Tech experiences that connect us
                          </Link>
                        </h3>
                      </div>
                    </li>
                    <li>
                      <div className="sidebar__posts__image">
                        <img src="assets/images/blog/blog-s-1-2.png" alt="" />
                      </div>
                      <div className="sidebar__posts__content">
                        <div className="sidebar__posts__date">
                          <i className="fa fa-comments"></i>2 Comments
                        </div>
                        <h3 className="sidebar__posts__title">
                          <Link to="/blog-details">
                            Tech experiences that connect us
                          </Link>
                        </h3>
                      </div>
                    </li>
                    <li>
                      <div className="sidebar__posts__image">
                        <img src="assets/images/blog/blog-s-1-3.png" alt="" />
                      </div>
                      <div className="sidebar__posts__content">
                        <div className="sidebar__posts__date">
                          <i className="fa fa-comments"></i>2 Comments
                        </div>
                        <h3 className="sidebar__posts__title">
                          <Link to="/blog-details">
                            Tech experiences that connect us
                          </Link>
                        </h3>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="sidebar__item sidebar__item--category">
                  <h3 className="sidebar__title">Categories</h3>
                  <ul className="sidebar__category">
                    <li>
                      <Link to="/bass">Bass</Link>
                    </li>
                    <li>
                      <Link to="/cyber-security">Cyber Security</Link>
                    </li>
                    <li>
                      <Link to="/it-management">IT Management</Link>
                    </li>
                    <li>
                      <Link to="/qa-testing">QA & Testing</Link>
                    </li>
                    <li>
                      <Link to="/infrastructure-plan">Big Data</Link>
                    </li>
                    <li>
                      <Link to="/web3">Web3</Link>
                    </li>
                    <li>
                      <Link to="/data-analytics">Data Analytics</Link>
                    </li>
                  </ul>
                </div>
                <div className="sidebar__item sidebar__item--tags">
                  <h3 className="sidebar__title">Tags</h3>
                  <div className="sidebar__tags">
                    <Link to="#">TOKENIZATION</Link>
                    <Link to="#">REAL</Link>
                    <Link to="#">ESTATE</Link>
                    <Link to="#">GOLD</Link>
                    <Link to="#">BONDS</Link>
                    <Link to="#">RARE</Link>
                    <Link to="#">ANTIQUE</Link>
                    <Link to="#">METAL</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Cdbc;
