import AT from "../../../assets/images/At.jpg";
import Breadcumb from "../../Breadcumb/Main";
import CDBC2 from "../../../assets/images/projects/CDBC2.jpg";
import CDBCI from "../../../assets/images/CDBCI.jpg";
import CharityI from "../../../assets/images/CharityI.jpg";
import DI from "../../../assets/images/SecureD.jpg";
import FNT from "../../../assets/images/Fnt.jpg";
import HRFIEDI from "../../../assets/images/HRFIEDI.jpg";
import { Link } from "react-router-dom";
import MoneyI from "../../../assets/images/MoneyI.jpg";
import NFTI from "../../../assets/images/NftI.jpg";
import OwlCarousel from "react-owl-carousel";
import Sec4 from "../../../assets/images/Sec4.jpg";
import SecureI from "../../../assets/images/SecureI.jpg";
import TC from "../../../assets/images/TokenI.jpg";
import W from "../../../assets/images/W.jpg";

const CBDC = () => {
  const options = {
    loop: true,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    smartSpeed: 700,
    items: 1,
    margin: 0,
    nav: false,
    dots: false,
    responsive: {
      0: {
        items: 1,
        margin: 0,
      },
      576: {
        items: 2,
        margin: 30,
      },
      992: {
        items: 3,
        margin: 30,
      },
    },
  };
  return (
    <>
      <Breadcumb Title="Central Bank Digital Currency" Breadcumb="PROJECT" />
      <section className="project-details section-padding--bottom section-padding--top">
        <div className="container">
          <div className="project-details__image">
            <img src={CDBC2} alt="" />
          </div>
          <div className="project-details__info">
            <div className="project-details__info__item">
              <h4 className="project-details__info__title">Client:</h4>
              <p className="project-details__info__text">PHQTI</p>
            </div>

            <div className="project-details__info__item">
              <h4 className="project-details__info__title">Category:</h4>
              <p className="project-details__info__text">
                <Link to="#">PHQCBDC</Link>
                <Link to="#"></Link>
              </p>
            </div>
            <div className="project-details__info__item">
              <h4 className="project-details__info__title">Year:</h4>
              <p className="project-details__info__text">2022</p>
            </div>
            <div className="project-details__info__item">
              <h4 className="project-details__info__title">Value:</h4>
              <p className="project-details__info__text">USD 87,5000</p>
            </div>
            <div className="project-details__info__item">
              <div className="project-details__info__social">
                <Link to="#">
                  <i className="fab fa-twitter"></i>
                </Link>
                <Link to="#">
                  <i className="fab fa-facebook"></i>
                </Link>
                <Link to="#">
                  <i className="fab fa-instagram"></i>
                </Link>
              </div>
            </div>
          </div>
          <h3 className="project-details__title">
            Central Bank Digital Currency (CBDC)
          </h3>
          <div className="project-details__content">
            <p>
              CBDCs are digital coins/tokens, similar to cryptocurrencies, but
              issued by the country’s central banks. They are pegged to the
              value of that country&#39;s fiat currency. Many countries are
              involved in the development of CBDCs, and some have even
              implemented it either through pilot projects or prototypes, on a
              very small scale. Many countries are researching ways to
              transition from paper to digital currencies. CBDCs promote
              financial inclusion and simplify the implementation of monetary
              and fiscal policy. As a centralized form of currency, they may not
              anonymize transactions as some cryptocurrencies do. Many countries
              are exploring how CBDCs will affect their economies, existing
              financial networks, and stability. The main goal of CBDCs is to
              provide businesses and consumers with privacy, transferability,
              convenience, accessibility, and financial security. CBDCs could
              also decrease the maintenance cost of a complex financial system,
              reduce cross-border transaction costs, and provide those who
              currently use alternative money transfer methods with lower-cost
              options. A CBDC also provides a country&#39;s central bank with
              the means to implement monetary policies to provide stability,
              control growth, and influence inflation in a foolproof manner.{" "}
            </p>
            <br />
            <h4 className="project-details__title" style={{ fontSize: "30px" }}>
              PHQ commercial scale CBDC solution on PHQ Smart Chain
            </h4>

            <p class="">
              An ERP Bridge (ERPB) will be created to connect the various
              government, money regulatory authorities, approving boards,
              CENTRAL BANK governors, other entities, etc.
            </p>

            <p>
              ERPB will act as a connecting agent between authorities to call
              meetings, attend meetings, propose, approve, accept, decline,
              suggest, or give decisions on the minting and smooth flowing of
              Digital Currency from central banks up to the last remote village.
            </p>
            <span>
              Single but highly secured platform for easy usage. The process on
              ERPB goes like this;
            </span>
            <br />
            <span>When is DC to be minted?</span>
            <br />
            <span>How much DC is to be minted?</span>
            <br />
            <span>Total quantity to be minted?</span>
            <br />
            <span>
              PHQ Smart chain (PHQSC) can mint unlimited DC @ any point in time.
            </span>
            <br />
            <span>
              After minting CENTRAL BANK will transfer the DC to their main
              wallet.
            </span>
            <br />
            <span>
              This wallet will have a &quot;24 words Secured Pass Phrase&quot;
              &amp; “64 Character Private Key&quot; with other ultra-security
              features. The same will be required for any/all kinds of future
              transactions.
            </span>

            <p style={{ marginTop: "15px" }}>
              PHQ can even do masking of the wallet to avoid any kind of
              fishing/BOT pinning attacks on CENTRAL BANK or other wallets. Once
              DC is created minting/burning/pausing/expanding can be done
              anytime through CENTRAL BANK’s main wallet. Special case
              &quot;Limited Edition&quot; of INR 10 million /1 million/100 K
              denomination DCs can also be minted and circulated separately on
              the parallel blockchain. Maybe on special occasions.
            </p>
            <p>
              PHQSC system has the capability to ensure and perform which
              denomination of DC will be burnable, mintable, pausable,
              expandable, further mintable, etc. PHQDC has the capability of
              having 25 kinds of features while creating DCs. No blockchain in
              the world can accommodate this kind of feature.
            </p>
            <p
              class=""
              style={{
                marginTop: "30px",
                marginBottom: "15px",
                color: "black",
                fontSize: "20px",
              }}
            >
              BENEFITS OF PHQ CBDC COMMERCIAL SCALE SOLUTION:
            </p>

            <ul className="project-details__list">
              <li>
                <i className="fa fa-check-circle"></i>
                Countries can launch their CBDC projects on the PHQ blockchain
                instantly
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                They can create, mint, store and transfer their CBDCs
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                PHQ has a complete commercial-scale solution so no piloting or
                prototypes
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                World’s fastest CBDC creation and transferring
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                All transactions will be recorded on the PHQ blockchain
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                There is zero gas fee for any kind of transaction on the PHQ
                blockchain
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                Easy onboarding for banks and financial institutions
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                One of the world’s best techies
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="project-details section-padding--bottom footer-widget__newsletter mc-form">
        <center>
          <a href="#" target="_blank" className="thm-btn about-four__btn">
            <span>Whitepaper</span>
          </a>
        </center>
      </section>
      <div className="project-nav">
        <div className="container">
          <div className="project-nav__inner">
            <Link to="/hrfied">
              <i className="icon-left-arrow"></i>
              Previous
            </Link>
            <Link to="/cryptocurrency-wallet">
              Next
              <i className="icon-right-arrow"></i>
            </Link>
          </div>
        </div>
      </div>

      <section className="section-padding--top section-padding--bottom">
        <div className="container">
          <div className="section-title text-center">
            <p className="section-title__text">Other PHQ Products</p>
            <h2 className="section-title__title">
              Check What We Have <br /> Developed?
            </h2>
          </div>

          <OwlCarousel className="thm-owl__carousel" {...options}>
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={SecureI} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text">PHQ.SEMS.V1.2023</p>
                    <h3 className="project-card-one__title">
                      <Link to="/secured-email-system">
                        Secured Email System
                      </Link>
                    </h3>
                    <Link
                      to="/secured-email-system"
                      className="project-card-one__more"
                    >
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={TC} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text">PHQ.TCT.V1.2023</p>
                    <h3 className="project-card-one__title">
                      <Link to="/token_creation_tool">Token Creation Tool</Link>
                    </h3>
                    <Link
                      to="/token_creation_tool"
                      className="project-card-one__more"
                    >
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={FNT} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text">PHQ.FNT.V1.2023</p>
                    <h3 className="project-card-one__title">
                      <Link to="/fntasy">Fntasy Gaming</Link>
                    </h3>
                    <Link to="/fntasy" className="project-card-one__more">
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={DI} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text">PHQ.SDI.V1.2023</p>
                    <h3 className="project-card-one__title">
                      <Link to="/secure-digital-identity">
                        Secured Digital Identity
                      </Link>
                    </h3>
                    <Link
                      to="/secure-digital-identity"
                      className="project-card-one__more"
                    >
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={AT} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text">PHQ.ATS.V1.2023</p>
                    <h3 className="project-card-one__title">
                      <Link to="/asset_tokenization">
                        Asset Tokenization System
                      </Link>
                    </h3>
                    <Link
                      to="/asset_tokenization"
                      className="project-card-one__more"
                    >
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={HRFIEDI} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text"> PHQ.HRF.V1.2023</p>
                    <h3 className="project-card-one__title">
                      <Link to="/hrfied">Hrfied</Link>
                    </h3>
                    <Link to="/hrfied" className="project-card-one__more">
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="item">
							<div className="project-card-one">
								<div className="project-card-one__image">
									<img src={CDBCI} alt="" />
								</div>
								<div className="project-card-one__content">
									<div className="project-card-one__content__inner">
										<p className="project-card-one__text"> PHQ.CDBC.V1.2023</p>
										<h3 className="project-card-one__title"><Link to="/central_bank_digital_currency">
Central Bank Digital Currency</Link></h3>
										<Link to="/central_bank_digital_currency" className="project-card-one__more">
											<i className="fa fa-angle-right"></i>
										</Link>
									</div>
								</div>
							</div>
						</div> */}
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={W} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text">
                      {" "}
                      PHQ.WALLET.V1.2023
                    </p>
                    <h3 className="project-card-one__title">
                      <Link to="/cryptocurrency-wallet">
                        Cryptocurrency Wallet
                      </Link>
                    </h3>
                    <Link
                      to="/cryptocurrency-wallet"
                      className="project-card-one__more"
                    >
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={MoneyI} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text"> PHQ.MTRN.V1.2023</p>
                    <h3 className="project-card-one__title">
                      <Link to="/money-transfer">Money Transfer</Link>
                    </h3>
                    <Link
                      to="/money-transfer"
                      className="project-card-one__more"
                    >
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={CharityI} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text">
                      {" "}
                      PHQ.Charity.V1.2023
                    </p>
                    <h3 className="project-card-one__title">
                      <Link to="/charity">Charity</Link>
                    </h3>
                    <Link to="/charity" className="project-card-one__more">
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={NFTI} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text"> PHQ.NFT.V1.2023</p>
                    <h3 className="project-card-one__title">
                      <Link to="/nft-market-place">NFT Marketplace</Link>
                    </h3>
                    <Link
                      to="/nft-market-place"
                      className="project-card-one__more"
                    >
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </OwlCarousel>
        </div>
      </section>
    </>
  );
};

export default CBDC;
