// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jarallax {
    position: relative;
    z-index: 0;
}
.jarallax > .jarallax-img {
    position: absolute;
    object-fit: cover;
    /* support for plugin https://github.com/bfred-it/object-fit-images */
    font-family: 'object-fit: cover;';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}`, "",{"version":3,"sources":["webpack://./src/assets/vendors/jarallax/jarallax.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,UAAU;AACd;AACA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,qEAAqE;IACrE,iCAAiC;IACjC,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,WAAW;AACf","sourcesContent":[".jarallax {\n    position: relative;\n    z-index: 0;\n}\n.jarallax > .jarallax-img {\n    position: absolute;\n    object-fit: cover;\n    /* support for plugin https://github.com/bfred-it/object-fit-images */\n    font-family: 'object-fit: cover;';\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    z-index: -1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
