import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

import Logo from "../../assets/images/PHQL.png";
import LogoL from "../../assets/images/PHQ.png";
import WOW from "wowjs";
import WP from "../../assets/images/Wp.gif";
import { useTheme } from "../../utils/useTheme";

const Navbar = () => {
  const [currentTheme, toggleTheme] = useTheme();
  const location = useLocation();
  const path = location.pathname;
  const [sticky, setSticky] = useState(false);
  const [search, setSearch] = useState(false);
  const [mobile, setmobile] = useState(false);

  const [menu, setmenu] = useState({});
  const [home, setHome] = useState(false);
  const [header, setheader] = useState(false);
  const [pages, setpages] = useState(false);
  const [page, setpage] = useState(false);
  const [blockchain, setblockchain] = useState(false);
  const [service, setservice] = useState(false);
  const [project, setproject] = useState(false);
  const [blog, setblog] = useState(false);
  const [product, setproduct] = useState(false);
  const activeMenu = () => {
    if (path === "/" || path === "/home-02" || path === "/home-03") {
      setmenu({ home: true });
    } else if (path === "/about" || path === "/team") {
      setmenu({ pages: true });
    } else if (
      path === "/service-01" ||
      path === "/service-02" ||
      path === "qa-testing" ||
      path === "/it-management" ||
      path === "cyber-security" ||
      path === "/it-consultant" ||
      path === "/infrastructure-plan"
    ) {
      setmenu({ services: true });
    } else if (path === "/projects" || path === "/project-details") {
      setmenu({ project: true });
    } else if (path === "/blog" || path === "/blog-details") {
      setmenu({ blog: true });
    } else if (path === "/contact") {
      setmenu({ contact: true });
    } else {
      setmenu({ home: true });
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);

  useEffect(() => {
    window.scroll(0, 0);
    new WOW.WOW({
      live: false,
    }).init();
    activeMenu();
  }, [path]);

  const isSticky = () => {
    const scrollTop = window.scrollY;
    scrollTop >= 141 ? setSticky(true) : setSticky(false);
  };
  return (
    <>
      <div className="topbar">
        {/* <div className="container-fluid">
                    <p className="topbar__text">Welcome to IT Solutions & Services HTML Template</p>
                    <ul className="topbar__info">
                        <li>
                            <i className="fa fa-envelope"></i>
                            <Link to="mailto:needhelp@company.com">needhelp@company.com</Link>
                        </li>
                        <li>
                            <i className="fa fa-map-marker"></i>
                            60 Golden Broklyn Street, New York
                        </li>
                    </ul>
                    <ul className="topbar__social">
                        <li><Link to="#"><i className="fab fa-twitter"></i></Link></li>
                        <li><Link to="#"><i className="fab fa-facebook"></i></Link></li>
                        <li><Link to="#"><i className="fab fa-pinterest-p"></i></Link></li>
                        <li><Link to="#"><i className="fab fa-instagram"></i></Link></li>
                    </ul>
                </div> */}
      </div>
      <nav
        className={`main-menu sticky-header ${
          sticky && "sticky-header--cloned sticky-fixed"
        }`}
      >
        <div className="container-fluid">
          <div className="main-menu__logo">
            <Link to="/">
              <img
                src={currentTheme === "dark" ? LogoL : Logo}
                width=""
                height="50"
                alt=""
              />
            </Link>
          </div>

          <ul className="main-menu__list">
            <li className={`menu-item-has-children ${menu.home && "current"}`}>
              <Link to="/">Home</Link>
            </li>
            <li className={`menu-item-has-children ${menu.pages && "current"}`}>
              <Link to="#">About</Link>
              <ul>
                <li>
                  <Link to="/about-us">About PHQ</Link>
                </li>
                <li>
                  <Link to="/team">Team</Link>
                </li>
                <li>
                  <Link to="/our-partners">Partners</Link>
                </li>
              </ul>
            </li>
            {/* <li
              className={`menu-item-has-children ${menu.services && "current"}`}
            >
              <Link to="/all-services">Services</Link>
              <ul>
                <li>
                  <Link to="/bass">BASS</Link>
                </li>
                <li>
                  <Link to="/artificial_intelligence">AI</Link>
                </li>
                <li>
                  <Link to="/cyber-security">Cyber Security</Link>
                </li>
                <li>
                  <Link to="/it-management">IT Management</Link>
                </li>
                <li>
                  <Link to="/qa-testing">QA & Testing</Link>
                </li>
                <li>
                  <Link to="/big-data">Big Data</Link>
                </li>
                <li>
                  <Link to="/web3">Web3</Link>
                </li>
                <li>
                  <Link to="/data-analytics">Data Analytics</Link>
                </li>
              </ul>
            </li> */}
            <li
              className={`menu-item-has-children ${menu.project && "current"}`}
            >
              <Link to="/projects">Offerings</Link>
              <ul>
                <li>
                  <Link to="#">Launch in 2024</Link>
                  <ul>
                    <li>
                      <Link to="/aidlt">AI-DLT</Link>
                    </li>
                    <li>
                      <Link to="/spark-gem-project">Spark GEM</Link>
                    </li>
                    <li>
                      <Link to="/asset_tokenization">
                        Asset Fractionalization
                      </Link>
                    </li>
                    <li>
                      <Link to="/payment-gateway">Payment Gateway</Link>
                    </li>
                    <li>
                      <Link to="/fntasy">Fntasy</Link>
                    </li>
                    <li>
                      <Link to="/ipfs-recorder">IPFS Recorder</Link>
                    </li>

                    {/* <li>
                      <Link to="/nft-market-place">NFT Marketplace</Link>
                    </li> */}
                  </ul>
                </li>
                <li>
                  <Link to="#">Launch in 2025</Link>
                  <ul>
                    <li>
                      <Link to="/secured-email-system">Secured Email</Link>
                    </li>
                    <li>
                      <Link to="/token_creation_tool">
                        Currency creation tool
                      </Link>
                    </li>
                    <li>
                      <Link to="/secure-digital-identity">
                        Digital Identity
                      </Link>
                    </li>

                    <li>
                      <Link to="/hrfied">Hrfied</Link>
                    </li>
                    <li>
                      <Link to="/central_bank_digital_currency">CBDC</Link>
                    </li>
                    <li>
                      <Link to="/cryptocurrency-wallet">Wallet</Link>
                    </li>

                    {/* <li>
                      <Link to="/nft-market-place">NFT Marketplace</Link>
                    </li> */}
                  </ul>
                </li>
                <li>
                  <Link to="#">Launch in 2026</Link>
                  <ul>
                    <li>
                      <Link to="/money-transfer">Money transfer</Link>
                    </li>
                    <li>
                      <Link to="/charity">Charity</Link>
                    </li>
                    <li>
                      <Link to="/public-projects">
                        Public Projects Creation Tool
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="#">PHQ Services</Link>
                  <ul>
                    <li>
                      <Link to="/bass">BASS</Link>
                    </li>
                    <li>
                      <Link to="/artificial_intelligence">AI</Link>
                    </li>
                    <li>
                      <Link to="/cyber-security">Cyber Security</Link>
                    </li>
                    <li>
                      <Link to="/it-management">IT Management</Link>
                    </li>
                    <li>
                      <Link to="/qa-testing">QA & Testing</Link>
                    </li>
                    <li>
                      <Link to="/big-data">Big Data</Link>
                    </li>
                    <li>
                      <Link to="/web3">Web3</Link>
                    </li>
                    <li>
                      <Link to="/data-analytics">Data Analytics</Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li className={``}>
              <Link to="#">AI-DLT</Link>
              <ul>
                <li>
                  <a href="https://explorer.phq.com/" target="_blank">
                    Explorer
                  </a>
                </li>
                <li>
                  <a href="https://wallet.phq.com/" target="_blank">
                    Wallet
                  </a>
                </li>
                <li>
                  <a href="/developers">For Developers</a>
                </li>
              </ul>
            </li>
            <li className={`menu-item-has-children ${menu.pages && "current"}`}>
              <Link to="#">Currencies</Link>
              <ul>
                <li>
                  <Link to="/phqdc">PHQDC</Link>
                </li>
                <li>
                  <Link to="/phqusd">PHQUSD</Link>
                </li>
              </ul>
            </li>
            <li className={`menu-item-has-children ${menu.pages && "current"}`}>
              <Link to="#">Devices</Link>
              <ul>
                <li>
                  <Link to="/spark-gem">SPARK GEM</Link>
                </li>
                <li>
                  <Link to="/smart-products">Smart Products</Link>
                </li>
                <li>
                  <Link to="/future-units">Future Units</Link>
                </li>
              </ul>
            </li>
            {/* <li className={`menu-item-has-children ${menu.blog && "current"}`}>
              <Link to="/spark-gem">SPARK.GEM</Link>
            </li> */}
            <li className={`menu-item-has-children ${menu.blog && "current"}`}>
              <Link to="/blog">Blog</Link>
            </li>
            {/* <li
              className={`menu-item-has-children ${menu.contact && "current"}`}
            >
              <Link to="/contact">Contact</Link>
            </li> */}
          </ul>
          <div className="menu__item__theme__btn__wrapper">
            <button className="menu__item__theme__btn" onClick={toggleTheme}>
              {currentTheme !== "dark" ? (
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 24 24"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path d="M12 3a9 9 0 109 9c0-.46-.04-.92-.1-1.36a5.389 5.389 0 01-4.4 2.26 5.403 5.403 0 01-3.14-9.8c-.44-.06-.9-.1-1.36-.1z"></path>
                </svg>
              ) : (
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 16 16"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M8 12a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z"></path>
                </svg>
              )}
            </button>
          </div>

          <div className="main-menu__right">
            <Link
              to="#"
              className="mobile-nav__toggler"
              onClick={() => setmobile(true)}
            >
              <span></span>
              <span></span>
              <span></span>
            </Link>

            <Link
              to="#"
              onClick={() => setSearch(true)}
              className="search-toggler"
            >
              <i className="icon-magnifying-glass"></i>
            </Link>
            <a
              href="https://api.whatsapp.com/send?phone=+919873221469&text=Hi,"
              target="_blank"
              className="main-menu__cta"
              style={{ minHeight: "12vh" }}
            >
              <img src={WP} style={{ height: "6vh", marginRight: "10px" }} />
              <span className="main-menu__cta__text">
                <b>+91 9873221469</b>
                Whatsapp
              </span>
            </a>
          </div>
        </div>
      </nav>

      <div className={`search-popup ${search && "active"}`}>
        <div
          className="search-popup__overlay search-toggler"
          onClick={() => setSearch(false)}
        ></div>
        <div className="search-popup__content">
          <form action="#">
            <label htmlFor="search" className="sr-only">
              search here
            </label>
            <input type="text" id="search" placeholder="Search Here..." />
            <button
              type="submit"
              aria-label="search submit"
              className="thm-btn"
            >
              <span>
                <i className="icon-magnifying-glass"></i>
              </span>
            </button>
          </form>
        </div>
      </div>
      <div className={`mobile-nav__wrapper ${mobile && "expanded"}`}>
        <div
          className="mobile-nav__overlay mobile-nav__toggler"
          onClick={() => setmobile(false)}
        ></div>
        <div className="mobile-nav__content">
          <Link
            to="#"
            onClick={() => setmobile(false)}
            className="mobile-nav__close mobile-nav__toggler"
          >
            <span></span>
            <span></span>
          </Link>

          <div className="logo-box">
            <Link
              to="/"
              aria-label="logo image"
              onClick={() => setmobile(false)}
            >
              <img src={LogoL} width="" height="33" alt="Cretech" />
            </Link>
          </div>
          <div className="mobile-nav__container">
            <ul className="mobile-menu__list">
              <li>
                {" "}
                <Link to="/" onClick={() => setmobile(false)}>
                  {" "}
                  Home
                </Link>
              </li>
              <li className="menu-item-has-children current">
                <Link to="/" className={header ? "expanded" : ""}>
                  About
                  <button
                    aria-label="dropdown toggler"
                    onClick={() => setheader(!header)}
                    className={header ? "expanded" : ""}
                  >
                    <i className="fa fa-angle-down"></i>
                  </button>
                </Link>
                {header && (
                  <ul style={{ display: "block" }}>
                    <li>
                      <Link to="/about-us">About PHQ</Link>
                    </li>
                    <li>
                      <Link to="/team">Team</Link>
                    </li>
                    <li>
                      <Link to="/our-partners">Partners</Link>
                    </li>
                  </ul>
                )}
              </li>
              <li
                className={`menu-item-has-children ${
                  menu.services ? "current" : ""
                }`}
              >
                <Link to="/all-services" className={service ? "expanded" : ""}>
                  Services
                  <button
                    aria-label="dropdown toggler"
                    onClick={() => setservice(!service)}
                    className={service ? "expanded" : ""}
                  >
                    <i className="fa fa-angle-down"></i>
                  </button>
                </Link>
                {service && (
                  <ul style={{ display: "block" }}>
                    <li>
                      <Link to="/bass" onClick={() => setmobile(false)}>
                        BASS{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/artificial_intelligence"
                        onClick={() => setmobile(false)}
                      >
                        AI{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/cyber-security"
                        onClick={() => setmobile(false)}
                      >
                        Cyber Security
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/it-management"
                        onClick={() => setmobile(false)}
                      >
                        IT Management
                      </Link>
                    </li>
                    <li>
                      <Link to="/qa-testing" onClick={() => setmobile(false)}>
                        QA &amp; Testing
                      </Link>
                    </li>
                    <li>
                      <Link to="/big-data" onClick={() => setmobile(false)}>
                        Big Data
                      </Link>
                    </li>
                    <li>
                      <Link to="/web3" onClick={() => setmobile(false)}>
                        Web3
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/data-analytics"
                        onClick={() => setmobile(false)}
                      >
                        Data Analytics
                      </Link>
                    </li>
                  </ul>
                )}
              </li>
              <li
                className={`menu-item-has-children ${
                  menu.pages ? "current" : ""
                }`}
              >
                <Link to="/projects" className={pages ? "expanded" : ""}>
                  Projects
                  <button
                    aria-label="dropdown toggler"
                    onClick={() => setpages(!pages)}
                    className={pages ? "expanded" : ""}
                  >
                    <i className="fa fa-angle-down"></i>
                  </button>
                </Link>
                {pages && (
                  <ul style={{ display: "block" }}>
                    <li
                      className={`menu-item-has-children ${
                        menu.services ? "current" : ""
                      }`}
                    >
                      <Link to="#" className={product ? "expanded" : ""}>
                        In-House Projects
                        <button
                          aria-label="dropdown toggler"
                          onClick={() => setproduct(!product)}
                          className={product ? "expanded" : ""}
                        >
                          <i className="fa fa-angle-down"></i>
                        </button>
                      </Link>
                      {product && (
                        <ul style={{ display: "block" }}>
                          <li>
                            <Link
                              to="/secured-email-system"
                              onClick={() => setmobile(false)}
                            >
                              Secured Email
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/token_creation_tool"
                              onClick={() => setmobile(false)}
                            >
                              Token creation tool
                            </Link>
                          </li>
                          <li>
                            <Link to="/fntasy" onClick={() => setmobile(false)}>
                              Fntasy
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/secure-digital-identity"
                              onClick={() => setmobile(false)}
                            >
                              Digital Identity
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/asset_tokenization"
                              onClick={() => setmobile(false)}
                            >
                              Asset Tokenization
                            </Link>
                          </li>
                          <li>
                            <Link to="/hrfied" onClick={() => setmobile(false)}>
                              Hrfied
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/central_bank_digital_currency"
                              onClick={() => setmobile(false)}
                            >
                              CBDC
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/cryptocurrency-wallet"
                              onClick={() => setmobile(false)}
                            >
                              Wallet
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/money-transfer"
                              onClick={() => setmobile(false)}
                            >
                              Money transfer
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/charity"
                              onClick={() => setmobile(false)}
                            >
                              Charity
                            </Link>
                          </li>
                        </ul>
                      )}
                    </li>
                    <li>
                      <Link
                        to="/public-projects"
                        onClick={() => setmobile(false)}
                      >
                        Public Projects
                      </Link>
                    </li>
                  </ul>
                )}
              </li>

              <li
                className={`menu-item-has-children ${
                  menu.blockchain ? "current" : ""
                }`}
              >
                <Link to="#" className={blockchain ? "expanded" : ""}>
                  Blockchain
                  <button
                    aria-label="dropdown toggler"
                    onClick={() => setblockchain(!blockchain)}
                    className={blockchain ? "expanded" : ""}
                  >
                    <i className="fa fa-angle-down"></i>
                  </button>
                </Link>
                {blockchain && (
                  <ul style={{ display: "block" }}>
                    <li>
                      <a href="https://explorer.phq.com/" target="_blank">
                        Explorer
                      </a>
                    </li>
                    <li>
                      <a href="https://wallet.phq.com/" target="_blank">
                        Wallet
                      </a>
                    </li>
                    <li>
                      <a href="/developers">Developers</a>
                    </li>
                  </ul>
                )}
              </li>

              <li>
                {" "}
                <Link to="/blog" onClick={() => setmobile(false)}>
                  {" "}
                  Blog
                </Link>
              </li>
              <li>
                <Link to="/contact" onClick={() => setmobile(false)}>
                  Contact
                </Link>
              </li>
            </ul>
          </div>

          <ul className="mobile-nav__contact list-unstyled">
            <li style={{ paddingLeft: "0px" }}>
              <a
                href="https://api.whatsapp.com/send?phone=+919873221469&text=Hi,"
                target="_blank"
                className=""
                style={{ minHeight: "12vh" }}
              >
                <img
                  src={WP}
                  style={{ height: "2.5vh", marginRight: "10px" }}
                />
                +91 9873221469
              </a>
            </li>
            <li>
              <i className="fa fa-envelope"></i>
              <a href="mailto:needhelp@company.com">chain@PHQ.com</a>
            </li>
            <li>
              <i className="fa fa-map-marker-alt"></i>
              PHQ Technologies Inc. <br /> Delaware, USA
            </li>
          </ul>
          <ul className="mobile-nav__social">
            <li>
              <Link to="/">
                <i className="fab fa-twitter"></i>
              </Link>
            </li>
            {/* {/ <li><Link to="/"><i className="fab fa-facebook-f"></i></Link></li> /} */}
            <li>
              <a
                href="https://www.linkedin.com/company/phq-blockchain-technologies/"
                target="_blank"
              >
                <i className="fab fa-linkedin"></i>
              </a>
            </li>
            <li>
              <Link to="/">
                <i className="fab fa-instagram"></i>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Navbar;
