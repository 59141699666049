import Blockchain from "../../assets/Docs/Blockchain.pdf";
import Breadcumb from "../Breadcumb/Main";
import { Link } from "react-router-dom";
import WEB3 from "../../assets/images/services/Web3.jpg";
import WP from "../../assets/images/Wp.gif";
import Web2 from "../../assets/images/services/Web2.jpg";
import { useState } from "react";

const Web3 = () => {
  const [clicked, setClicked] = useState(0);
  const faqs = [
    {
      question: "What is the necessity of Web3?",
      answer: (
        <div>
          <p>
            Web 2.0 served as the foundation for expanding user-generated
            content alongside e-commerce applications on a massive scale.
            However, Web 2.0 has been maligned with concerns regarding data
            privacy and the protection of users’ digital identities. Big tech
            companies impose control over the content of users and use it for
            heavy monetary gains. Therefore, a decentralized version of the web
            with users in control of their data is an obvious necessity, and
            where web3 was introduced.
          </p>
        </div>
      ),
    },
    {
      question: "Can Web3 bring complete decentralization?",
      answer: (
        <div>
          <p>
            The primary objective of web 3 focuses on enabling a decentralized
            internet. Web3 can achieve the desired goals by combining three
            notable elements such as distributed ledger technologies (DLTs),
            cryptocurrencies, NFTs, and Decentralized Autonomous Organizations
            (DAOs).
          </p>{" "}
          <br />
          <p>
            DLTs such as blockchain networks could safeguard against the need to
            depend on centralized servers for storing data and running
            applications.
          </p>{" "}
          <br />
          <p>
            Cryptocurrencies and NFTs provide the ideal scope for ownership and
            payment in the world of web3. The next important highlight of web3,
            which can guarantee Complete decentralization, refers to DAOs. They
            are community-driven governance entities based on blockchain
            technology for the transparent encoding of rules.
          </p>
        </div>
      ),
    },

    {
      question: "What are the major use cases of Web3?",
      answer: (
        <div>
          <p>Advanced gaming</p>

          <p>Social media</p>
          <p>Advanced NFT cases</p>
          <p>Privacy &amp; data management</p>
          <p>Metaverse and meta spaces</p>
          <p>Nex generation dApps</p>
          <p>Virtual real estate</p>
          <p>Remote workplaces</p>
          <p>Decentralized finance</p>
        </div>
      ),
    },
    {
      question: "What is Creator Economy?",
      answer: (
        <div>
          <p>
            The creator economy is one of the best web3 use cases because it
            allows monetization opportunities for people spending a major
            portion of their time online in various digital spaces. NFTs provide
            many new opportunities to creators for monetization while moving
            past the conventional gatekeepers. Therefore, the creator economy
            use case of web3 can offer a favourable route for creators to sell
            their work directly.
          </p>
        </div>
      ),
    },
    {
      question: "Privacy and Digital Infrastructure?",
      answer: (
        <div>
          <p>
            The web3 use cases of privacy and digital infrastructure
            improvements could also improve regulatory compliance. Privacy
            layers can help users to overcome their apprehensions about sharing
            personal information with an application or service on blockchain.
            Therefore, web3 will obviously play a crucial role in easing the
            path to regulatory compliance in blockchain networks without risks.
          </p>
        </div>
      ),
    },
  ];
  return (
    <>
      <Breadcumb Title="Web3 Projects" Breadcumb="SERVICES" />
      <section className="section-padding--bottom section-padding--top service-details--page">
        <div className="container">
          <div className="row ">
            <div className="col-lg-8">
              <div className="service-details__image">
                <img src={Web2} alt="" />
              </div>
              <h3 className="service-details__title">Web3 Pojects</h3>
              <div className="service-details__content">
                <p>
                  Web3 projects rely on the fundamentals and principles of
                  blockchain technology. They are decentralized and
                  permissionless. They don’t require trust from the user or
                  developer. They don’t steal value but instead, they reward for
                  participation, development, and engagement. Projects include
                  categories of apps and concepts like layer-2 scaling
                  solutions, Defi tools, stable coins, NFT marketplaces,
                  play-to- earn games, or interoperability protocols.
                </p>
                <h3 className="service-details__title">Idea behind web3</h3>
                <p>
                  Web3 is the general term for the set of technologies that
                  integrate decentralization, blockchain, and
                  cryptocurrency-based finance to provide the next generation of
                  web technology. The goal of Web3 is to enhance data security,
                  scalability, and privacy for users. PHQ can provide a custom
                  solution to bring your company to the future.
                </p>
              </div>
              <div className="row gutter-y-30 service-details__box-wrapper">
                <div className="col-md-6 col-sm-12">
                  <div className="service-details__box">
                    <i className="service-details__box__icon icon-consulting"></i>
                    <div className="service-details__box__content">
                      <h3 className="service-details__box__title">
                        <a href={Blockchain} target="_blank">
                          Blockchain{" "}
                        </a>
                      </h3>
                      <p className="service-details__box__text">
                        Faster Solutions
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <div className="service-details__box">
                    <i className="service-details__box__icon icon-chip"></i>
                    <div className="service-details__box__content">
                      <h3 className="service-details__box__title">
                        <Link to="/all-services">IT Specialist</Link>
                      </h3>
                      <p className="service-details__box__text">
                        Smarter Solutions
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row gutter-y-30">
                <div className="col-md-6 col-sm-12">
                  <img
                    src={WEB3}
                    className="service-details__sub-image"
                    alt=""
                  />
                </div>
                <div className="col-md-6 col-sm-12">
                  <div className="service-details__caption">
                    <h3 className="service-details__caption__title">
                      Our Benefits
                    </h3>
                    <p className="service-details__caption__text">
                      We can do integration of any kind of web3 project.
                    </p>
                    <ul className="service-details__caption__list">
                      <li>
                        <i className="fa fa-check-circle"></i>
                        Web AR/VR Frameworks
                      </li>
                      <li>
                        <i className="fa fa-check-circle"></i>
                        Defi, DApps, gaming, digital assets & tokens
                      </li>
                      <li>
                        <i className="fa fa-check-circle"></i>
                        Public blockchain ledger
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div
                    className="accrodion-grp service-details__accrodion"
                    data-grp-name="service-details__accrodion-1"
                  >
                    {faqs.map((item, index) => (
                      <div
                        className={`accrodion wow fadeInUp ${
                          index === clicked && "active"
                        }`}
                        key={index}
                        data-wow-delay="0ms"
                      >
                        <div className="accrodion-title">
                          <h4 onClick={() => setClicked(index)}>
                            {item.question}
                            <span className="accrodion-icon"></span>
                          </h4>
                        </div>
                        {index === clicked && (
                          <div className="accrodion-content">
                            <div className="inner">
                              <p>{item.answer}</p>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 sidebar-column">
              <div className="sidebar">
                <div className="sidebar__item sidebar__item--category">
                  <h3 className="sidebar__title">Categories</h3>
                  <ul className="sidebar__category">
                    <li>
                      <Link to="/bass">Bass</Link>
                    </li>
                    <li>
                      <Link to="/artificial_intelligence">AI</Link>
                    </li>
                    <li>
                      <Link to="/cyber-security">Cyber Security</Link>
                    </li>
                    <li>
                      <Link to="/it-management">IT Management</Link>
                    </li>
                    <li>
                      <Link to="/qa-testing">QA & Testing</Link>
                    </li>
                    <li>
                      <Link to="/big-data">Big Data</Link>
                    </li>
                    {/* <li><Link to="/it-consultant">Web3</Link></li> */}
                    <li>
                      <Link to="/data-analytics">Data Analytics</Link>
                    </li>
                  </ul>
                </div>
                <div className="sidebar__item sidebar__item--cta">
                  <div className="sidebar__cta">
                    <a
                      href="https://api.whatsapp.com/send?phone=+919873221469&text=Hi,"
                      target="_blank"
                      className=""
                      style={{ minHeight: "12vh" }}
                    >
                      <img
                        src={WP}
                        style={{ height: "10vh", marginRight: "10px" }}
                      />
                      {/* <i className="sidebar__cta__icon icon-phone-ringing"></i> */}
                    </a>
                    <h3 className="sidebar__cta__title">
                      Have Tech Problems? Contact Us
                    </h3>
                    <p className="sidebar__cta__text">
                      Whatsapp Anytime <br /> +91 9873221469{" "}
                    </p>
                  </div>
                </div>
                <div className="sidebar__item sidebar__item--btn">
                  <Link to="#" className="thm-btn sidebar__btn dark__button">
                    <span>Download our flyers</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Web3;
