import Blockchain from "../../assets/Docs/Blockchain.pdf";
import Breadcumb from "../Breadcumb/Main";
import Data2 from "../../assets/images/services/Data2.jpg";
import Data3 from "../../assets/images/services/Data3.jpg";
import { Link } from "react-router-dom";
import WP from "../../assets/images/Wp.gif";
import { useState } from "react";

const BigData = () => {
  const [clicked, setClicked] = useState(0);
  const faqs = [
    {
      question: "How big data Works?",
      answer:
        "Big data can be categorized as unstructured or structured.Structured data consists of information already managed by the organization in databases and spreadsheets; it is frequently numeric in nature. Unstructured data is information that is unorganized and does not fall into a predetermined model or format. It includes data gathered from social media sources, which help institutions gather information on customer needs.",
    },
    {
      question: "How is big data is collected?",
      answer:
        "Big data can be collected from publicly shared comments on social networks and websites, voluntarily gathered from personal electronics and apps, through questionnaires, product purchases, and electronic check-ins. The presence of sensors and other inputs in smart devices allows for data to be gathered across a broad spectrum of situations and circumstances.",
    },
    {
      question: "Where is big data stored?",
      answer:
        "Big data is most often stored in computer databases and is analyzed using software specifically designed to handle large, complex data sets. Many software-as-a-service (SaaS) companies specialize in managing this type of complex data.",
    },
    {
      question: "Where is big data used?",
      answer:
        "Our data analysts look at the relationship between different types of data, such as demographic data and purchase history,to determine whether a correlation exists. Such assessments may be done in-house or externally by a third party that focuses on processing big data into digestible formats. Businesses often use the assessment of big data by such experts to turn it into actionable information.",
    },
  ];
  return (
    <>
      <Breadcumb Title="Big data" Breadcumb="SERVICES" />
      <section className="section-padding--bottom section-padding--top service-details--page">
        <div className="container">
          <div className="row ">
            <div className="col-lg-8">
              <div className="service-details__image">
                <img src={Data2} alt="" />
              </div>
              <h3 className="service-details__title"> Big data</h3>
              <div className="service-details__content">
                <p>
                  Big data is a combination of structured, semi-structured, and
                  unstructured data collected by organizations that can be mined
                  for information and used in machine
                  learning projects, predictive modeling, and other advanced
                  analytics applications. Systems that process and store big
                  data have become a common component of data
                  management architectures in organizations, combined with tools
                  that support big data analytics uses. Big data is often
                  characterized by the three V&#39;s (Large volumes, wide
                  variety, and velocity)
                </p>
                <h4>Importance of Big data</h4>
                <p>
                  Companies use big data in their systems to improve operations,
                  provide better customer service, and create personalized
                  marketing campaigns for increasing revenues and profits.
                  Businesses that use it effectively hold a potential
                  competitive advantage over those that don&#39;t because
                  they&#39;re able to make faster and more informed business
                  decisions. Big data provides valuable insights into customers
                  that companies can use to refine their marketing, advertising,
                  and promotions in order to increase customer engagement and
                  conversion rates. Both historical and real-time data can be
                  analyzed to assess the evolving preferences of consumers or
                  corporate buyers, enabling businesses to become more
                  responsive to customer wants and needs.
                </p>
              </div>
              <div className="row gutter-y-30 service-details__box-wrapper">
                <div className="col-md-6 col-sm-12">
                  <div className="service-details__box">
                    <i className="service-details__box__icon icon-consulting"></i>
                    <div className="service-details__box__content">
                      <h3 className="service-details__box__title">
                        <a href={Blockchain} target="_blank">
                          Blockchain{" "}
                        </a>
                      </h3>
                      <p className="service-details__box__text">
                        Faster Solutions
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <div className="service-details__box">
                    <i className="service-details__box__icon icon-chip"></i>
                    <div className="service-details__box__content">
                      <h3 className="service-details__box__title">
                        <Link to="/all-services">IT Specialist</Link>
                      </h3>
                      <p className="service-details__box__text">
                        Smarter Solutions
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row gutter-y-30">
                <div className="col-md-6 col-sm-12">
                  <img
                    src={Data3}
                    className="service-details__sub-image"
                    alt=""
                  />
                </div>
                <div className="col-md-6 col-sm-12">
                  <div className="service-details__caption">
                    <h3 className="service-details__caption__title">
                      Our Benefits
                    </h3>
                    <p className="service-details__caption__text">
                      PHQ is confident in managing any Hitech Big data project.
                    </p>
                    <ul className="service-details__caption__list">
                      <li>
                        <i className="fa fa-check-circle"></i>
                        Transaction processing systems
                      </li>
                      <li>
                        <i className="fa fa-check-circle"></i>
                        Customer’s ultra-sized database
                      </li>
                      <li>
                        <i className="fa fa-check-circle"></i>
                        Real-time analysis
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div
                    className="accrodion-grp service-details__accrodion"
                    data-grp-name="service-details__accrodion-1"
                  >
                    {faqs.map((item, index) => (
                      <div
                        className={`accrodion wow fadeInUp ${
                          index === clicked && "active"
                        }`}
                        key={index}
                        data-wow-delay="0ms"
                      >
                        <div className="accrodion-title">
                          <h4 onClick={() => setClicked(index)}>
                            {item.question}
                            <span className="accrodion-icon"></span>
                          </h4>
                        </div>
                        {index === clicked && (
                          <div className="accrodion-content">
                            <div className="inner">
                              <p>{item.answer}</p>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 sidebar-column">
              <div className="sidebar">
                <div className="sidebar__item sidebar__item--category">
                  <h3 className="sidebar__title">Categories</h3>
                  <ul className="sidebar__category">
                    <li>
                      <Link to="/bass">Bass</Link>
                    </li>
                    <li>
                      <Link to="/artificial_intelligence">AI</Link>
                    </li>
                    <li>
                      <Link to="/cyber-security">Cyber Security</Link>
                    </li>
                    <li>
                      <Link to="/it-management">IT Management</Link>
                    </li>
                    <li>
                      <Link to="/qa-testing">QA & Testing</Link>
                    </li>
                    {/* <li><Link to="/infrastructure-plan">Big Data</Link></li> */}
                    <li>
                      <Link to="/web3">Web3</Link>
                    </li>
                    <li>
                      <Link to="/data-analytics">Data Analytics</Link>
                    </li>
                  </ul>
                </div>
                <div className="sidebar__item sidebar__item--cta">
                  <div className="sidebar__cta">
                    <a
                      href="https://api.whatsapp.com/send?phone=+919873221469&text=Hi,"
                      target="_blank"
                      className=""
                      style={{ minHeight: "12vh" }}
                    >
                      <img
                        src={WP}
                        style={{ height: "10vh", marginRight: "10px" }}
                      />
                      {/* <i className="sidebar__cta__icon icon-phone-ringing"></i> */}
                    </a>
                    <h3 className="sidebar__cta__title">
                      Have Tech Problems? Contact Us
                    </h3>
                    <p className="sidebar__cta__text">
                      Whatsapp Anytime <br /> +91 9873221469{" "}
                    </p>
                  </div>
                </div>
                <div className="sidebar__item sidebar__item--btn">
                  <Link to="#" className="thm-btn sidebar__btn dark__button">
                    <span>Download our flyers</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BigData;
