import AT from "../../../assets/images/At.jpg";
import Breadcumb from "../../Breadcumb/Main";
import CDBCI from "../../../assets/images/CDBCI.jpg";
import CharityI from "../../../assets/images/CharityI.jpg";
import DI from "../../../assets/images/SecureD.jpg";
import Email2 from "../../../assets/images/projects/Email2.jpg";
import FNT from "../../../assets/images/Fnt.jpg";
import HRFIEDI from "../../../assets/images/HRFIEDI.jpg";
import { Link } from "react-router-dom";
import MoneyI from "../../../assets/images/MoneyI.jpg";
import NFTI from "../../../assets/images/NftI.jpg";
import OwlCarousel from "react-owl-carousel";
import Sec4 from "../../../assets/images/Sec4.jpg";
import SecureI from "../../../assets/images/SecureI.jpg";
import TC from "../../../assets/images/TokenI.jpg";
import W from "../../../assets/images/W.jpg";

const SeMS = () => {
  const options = {
    loop: true,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    smartSpeed: 700,
    items: 1,
    margin: 0,
    nav: false,
    dots: false,
    responsive: {
      0: {
        items: 1,
        margin: 0,
      },
      576: {
        items: 2,
        margin: 30,
      },
      992: {
        items: 3,
        margin: 30,
      },
    },
  };
  return (
    <>
      <Breadcumb Title="Secured Email system" Breadcumb="PROJECT" />
      <section className="project-details section-padding--bottom section-padding--top">
        <div className="container">
          {/* <div className="project-details__image">
            <img src={Email2} alt="" />
          </div> */}
          <div className="project-details__info">
            <div className="project-details__info__item">
              <h4 className="project-details__info__title">Client:</h4>
              <p className="project-details__info__text">PHQTI</p>
            </div>

            <div className="project-details__info__item">
              <h4 className="project-details__info__title">Category:</h4>
              <p className="project-details__info__text">
                <Link to="#">SeMS</Link>
                <Link to="#"></Link>
              </p>
            </div>
            <div className="project-details__info__item">
              <h4 className="project-details__info__title">Year:</h4>
              <p className="project-details__info__text">2023</p>
            </div>
            <div className="project-details__info__item">
              <h4 className="project-details__info__title">Value:</h4>
              <p className="project-details__info__text">USD 250,000</p>
            </div>
            <div className="project-details__info__item">
              <div className="project-details__info__social">
                <Link to="#">
                  <i className="fab fa-twitter"></i>
                </Link>
                <Link to="#">
                  <i className="fab fa-facebook"></i>
                </Link>
                <Link to="#">
                  <i className="fab fa-instagram"></i>
                </Link>
              </div>
            </div>
          </div>
          <h3 className="project-details__title">
            Secured Email system (SeMS)
          </h3>
          <div className="project-details__content">
            <p>
              PHQ SEMS is a single-point platform to create and send
              super-protected emails (across the globe). Signup using biometrics
              (face recognition, thumb impression, eye scan) and password. Users
              do not have to remember complex passwords or any other details.
              Complete emailing through biometrics. Emails once sent from any
              device cannot be read/extracted while in transit as they are
              highly encrypted (Ciphertext) on the PHQ blockchain. Hacking
              emails while in transit is almost impossible. However, if there is
              any kind of cyber/malware attack on the system, hackers will only
              get a highly encrypted (Ciphertext) that can never/ever be
              cracked. Takes fewer milliseconds for emails to reach from sending
              to receiving. No gas fees for any email sent via the PHQ SeMS
              system. Fastest email creation and sending. Most user-friendly
              emailing software/tool in the world. 3-5 times encrypted from
              sending to receiving. Users can choose a subscription based on
              their requirements.
            </p>
            <br />
            <h4 className="project-details__title" style={{ fontSize: "30px" }}>
              How secured emails are sent
            </h4>
            <p>
              Choose a username, for example; david@PHQ, badar@PHQ, jack@PHQ,
              anne@PHQ, ramsay@PHQ, etc, and enter a password (alphanumeric,
              biometric, voice, video). After entering the secured username and
              password, PHQSC will generate a secured private key and
              passphrase. Keep the private key and passphrase in a safe place.
              The same will be required for future usage. Enter username and
              password (alphanumeric, biometric, voice, video). After login in,
              you will be directed to a dashboard. Choose subscription plans as
              per the need to enter this locker, you will need your private key
              and passphrase already generated by the blockchain. Use the
              services of creating a new secured email. Options of sending
              emails to multiple users in one go. Enter different users one by
              one or use the list to send mass emails. Option to edit before
              sending. Options to discard before sending. No alternation or
              reversal after ending the secured email is ever allowed or
              possible. Hash is generated and seen on the sender’s screen. This
              hash can be checked on PHQ blockchain explorer for verification.
              What happens after sending; The complete message is encrypted in
              the blockchain.
            </p>

            <p class="pl" style={{ paddingTop: "1%", paddingBottom: "1%" }}>
              Security of the email:
            </p>
            <p class="pl">
              Emails can’t be seen/read/extracted by anyone as they are fully
              encrypted and decrypted on the blockchain and stored in the
              blockchain and not in the database.
            </p>
            <p
              class=""
              style={{
                marginTop: "30px",
                marginBottom: "15px",
                color: "black",
                fontSize: "20px",
              }}
            >
              BENEFITS OF PHQ SECURE EMAIL SYSTEM
            </p>

            <ul className="project-details__list">
              <li>
                <i className="fa fa-check-circle"></i>
                No other email verification or OTP is required for sign in
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                Easily adaptable and user friendly
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                Built on the world’s fastest blockchain technology
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                Email sending in milliseconds (almost live kind of system)
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                Emails once sent cannot be called back
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                Encryption and decryption of emails on blockchain and not on
                database like others
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                Instant email sending around the globe with zero gas fees on the
                PHQ blockchain
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                Dual security wall to enter into the dashboard for sending and
                receiving emails
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                Hacking of emails in transit is next to impossible
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                Highly secured system with worlds; best security protocols used
                in the coding
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="project-details section-padding--bottom footer-widget__newsletter mc-form">
        <center>
          <a href="#" target="_blank" className="thm-btn about-four__btn">
            <span>Whitepaper</span>
          </a>
        </center>
      </section>
      <div className="project-nav">
        <div className="container">
          <div className="project-nav__inner">
            <Link to="/nft-market-place">
              {/* <i className="icon-left-arrow"></i>
              Previous */}
              &nbsp;
            </Link>

            <Link to="/token_creation_tool">
              Next
              <i className="icon-right-arrow"></i>
            </Link>
          </div>
        </div>
      </div>

      <section className="section-padding--top section-padding--bottom">
        <div className="container">
          <div className="section-title text-center">
            <p className="section-title__text">Other PHQ Products</p>
            <h2 className="section-title__title">
              Check What We Have <br /> Developed?
            </h2>
          </div>

          <OwlCarousel className="thm-owl__carousel" {...options}>
            {/* <div className="item">
							<div className="project-card-one">
								<div className="project-card-one__image">
									<img src={SecureI} alt="" />
								</div>
								<div className="project-card-one__content">
									<div className="project-card-one__content__inner">
										<p className="project-card-one__text">PHQ.SEMS.V1.2023</p>
										<h3 className="project-card-one__title"><Link to="/secured-email-system">Secured Email System</Link></h3>
										<Link to="/secured-email-system" className="project-card-one__more">
											<i className="fa fa-angle-right"></i>
										</Link>
									</div>
								</div>
							</div>
						</div> */}
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={TC} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text">PHQ.TCT.V1.2023</p>
                    <h3 className="project-card-one__title">
                      <Link to="/token_creation_tool">Token Creation Tool</Link>
                    </h3>
                    <Link
                      to="/token_creation_tool"
                      className="project-card-one__more"
                    >
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={FNT} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text">PHQ.FNT.V1.2023</p>
                    <h3 className="project-card-one__title">
                      <Link to="/fntasy">Fntasy Gaming</Link>
                    </h3>
                    <Link to="/fntasy" className="project-card-one__more">
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={DI} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text">PHQ.SDI.V1.2023</p>
                    <h3 className="project-card-one__title">
                      <Link to="/secure-digital-identity">
                        Secured Digital Identity
                      </Link>
                    </h3>
                    <Link
                      to="/secure-digital-identity"
                      className="project-card-one__more"
                    >
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={AT} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text">PHQ.ATS.V1.2023</p>
                    <h3 className="project-card-one__title">
                      <Link to="/asset_tokenization">
                        Asset Tokenization System
                      </Link>
                    </h3>
                    <Link
                      to="/asset_tokenization"
                      className="project-card-one__more"
                    >
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={HRFIEDI} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text"> PHQ.HRF.V1.2023</p>
                    <h3 className="project-card-one__title">
                      <Link to="/hrfied">Hrfied</Link>
                    </h3>
                    <Link to="/hrfied" className="project-card-one__more">
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={CDBCI} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text"> PHQ.CDBC.V1.2023</p>
                    <h3 className="project-card-one__title">
                      <Link to="/central_bank_digital_currency">
                        Central Bank Digital Currency
                      </Link>
                    </h3>
                    <Link
                      to="/central_bank_digital_currency"
                      className="project-card-one__more"
                    >
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={W} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text">
                      {" "}
                      PHQ.WALLET.V1.2023
                    </p>
                    <h3 className="project-card-one__title">
                      <Link to="/cryptocurrency-wallet">
                        Cryptocurrency Wallet
                      </Link>
                    </h3>
                    <Link
                      to="/cryptocurrency-wallet"
                      className="project-card-one__more"
                    >
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={MoneyI} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text"> PHQ.MTRN.V1.2023</p>
                    <h3 className="project-card-one__title">
                      <Link to="/money-transfer">Money Transfer</Link>
                    </h3>
                    <Link
                      to="/money-transfer"
                      className="project-card-one__more"
                    >
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={CharityI} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text">
                      {" "}
                      PHQ.Charity.V1.2023
                    </p>
                    <h3 className="project-card-one__title">
                      <Link to="/charity">Charity</Link>
                    </h3>
                    <Link to="/charity" className="project-card-one__more">
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={NFTI} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text"> PHQ.NFT.V1.2023</p>
                    <h3 className="project-card-one__title">
                      <Link to="/nft-market-place">NFT Marketplace</Link>
                    </h3>
                    <Link
                      to="/nft-market-place"
                      className="project-card-one__more"
                    >
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </OwlCarousel>
        </div>
      </section>
    </>
  );
};

export default SeMS;
