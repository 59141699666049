import Breadcumb from "../components/Breadcumb/Main";
import { Link } from "react-router-dom";

const CookiePolicy = () => {
  return (
    <>
      <Breadcumb Title="Cookie Policy" Breadcumb="PRIVACY POLICY" />
      <section className="project-details section-padding--bottom section-padding--top">
        <div className="container">
          <p></p>

          <h3 className="project-details__title">Cookie Policy for PHQ.com</h3>
          <p>
            This is the Cookie Policy for PHQ.com, accessible from
            https://PHQ.com
          </p>
          <div className="project-details__content">
            <p>
              What Is Cookies: As is common practice with almost all
              professional websites this site uses cookies, which are tiny files
              that are downloaded to your computer, to improve your experience.
              This page describes what information they gather, how we use it,
              and why we sometimes need to store these cookies. We will also
              share how you can prevent these cookies from being stored however
              this may downgrade or 'break' certain elements of the site’s
              functionality.
            </p>

            <p>
              How We Use Cookies: We use cookies for a variety of reasons
              detailed below. Unfortunately, in most cases, there are no
              industry standard options for disabling cookies without completely
              disabling the functionality and features they add to this site. It
              is recommended that you leave on all cookies if you are not sure
              whether you need them or not in the case, they are used to provide
              a service that you use.
            </p>

            <p>
              Disabling Cookies: You can prevent the setting of cookies by
              adjusting the settings on your browser (see your browser’s Help
              for how to do this). Be aware that disabling cookies will affect
              the functionality of this and many other websites that you visit.
              Disabling cookies will usually result in also disabling certain
              functionality and features of this site. Therefore, it is
              recommended that you do not disable cookies.{" "}
            </p>
            <h4
              className="project-details__title"
              style={{ fontSize: "30px", marginTop: "30px" }}
            >
              The Cookies We Set
            </h4>
            <ul>
              <li>
                Account-related cookies: If you create an account with us then
                we will use cookies for the management of the signup process and
                general administration. These cookies will usually be deleted
                when you log out however in some cases, they may remain
                afterward to remember your site preferences when logged out.
              </li>
              <br />
              <li>
                Login-related cookies: We use cookies when you are logged in so
                that we can remember this fact. This prevents you from having to
                log in every single time you visit a new page. These cookies are
                typically removed or cleared when you log out to ensure that you
                can only access restricted features and areas when logged in.
              </li>
              <br />
              <li>
                Orders processing related cookies: This site offers e-commerce
                or payment facilities and some cookies are essential to ensure
                that your order is remembered between pages so that we can
                process it properly.
              </li>
              <br />
              <li>
                Forms-related cookies: When you submit data to through a form
                such as those found on contact pages or comment forms cookies
                may be set to remember your user details for future
                correspondence.
              </li>
            </ul>
            <h4
              className="project-details__title"
              style={{ fontSize: "30px", marginTop: "30px" }}
            >
              Third-Party Cookies
            </h4>
            <p>
              In some special cases, we also use cookies provided by trusted
              third parties. The following section details which third-party
              cookies you might encounter through this site.
            </p>
            <ul>
              <li>
                This site uses Google Analytics which is one of the most
                widespread and trusted analytics solutions on the web for
                helping us to understand how you use the site and ways that we
                can improve your experience. These cookies may track things such
                as how long you spend on the site and the pages that you visit
                so we can continue to produce engaging content.
                <br />
                <br />
                For more information on Google Analytics cookies, see the
                official Google Analytics page.
              </li>
              <br />
              <li>
                Third-party analytics are used to track and measure the usage of
                this site so that we can continue to produce engaging content.
                These cookies may track things such as how long you spend on the
                site or pages you visit which helps us to understand how we can
                improve the site for you.
              </li>
              <br />
              <li>
                From time to time, we test new features and make subtle changes
                to the way that the site is delivered. When we are still testing
                new features, these cookies may be used to ensure that you
                receive a consistent experience whilst on the site whilst ensure
                we understand which optimizations our users appreciate the most.
              </li>
              <br />
              <li>
                As we sell products it's important for us to understand
                statistics about how many of the visitors to our site actually
                make a purchase and as such this is the kind of data that these
                cookies will track. This is important to you as it means that we
                can accurately make business predictions that allow us to
                monitor our advertising and product costs to ensure the best
                possible price.
              </li>
              <br />
              <li>
                The Google AdSense service we use to serve to advertise uses a
                DoubleClick cookie to serve more relevant ads across the web and
                limit the number of times that a given ad is shown to you.
                <br />
                <br />
                For more information on Google AdSense see the official Google
                AdSense privacy FAQ.
              </li>
              <br />
              <li>
                We use adverts to offset the costs of running this site and
                provide funding for further development. The behavioural
                advertising cookies used by this site are designed to ensure
                that we provide you with the most relevant adverts where
                possible by anonymously tracking your interests and presenting
                similar things that may be of interest.
              </li>

              <br />
              <li>
                Several partners advertise on our behalf and affiliate tracking
                cookies simply allow us to see if our customers have come to the
                site through one of our partner sites so that we can credit them
                appropriately and where applicable allow our affiliate partners
                to provide any bonus that they may provide you for making a
                purchase.
              </li>
              <br />
              <li>
                We also use social media buttons and/or plugins on this site
                that allow you to connect with your social network in various
                ways. For these to work the following social media sites
                including; (List the social networks whose features you have
                integrated with the website), will set cookies through our site
                which may be used to enhance your profile on their site or
                contribute to the data they hold for various purposes outlined
                in their respective privacy policies.
              </li>
            </ul>
            <h4
              className="project-details__title"
              style={{ fontSize: "30px", marginTop: "30px" }}
            >
              More Information
            </h4>
            <p>
              Hopefully, that has clarified things for you, and as was
              previously mentioned if there is something that you aren't sure
              whether you need or not it's usually safer to leave cookies
              enabled in case it does interact with one of the features you use
              on our site.
            </p>
            <p>
              However, if you are still looking for more information than you
              can contact us through one of our preferred contact methods:
            </p>

            <ul>
              <li>
                Email: <Link to="#">chain@PHQ.com </Link>
              </li>
              <li>
                By visiting this link:
                <Link to="#"> https://PHQ.com/cookie </Link>
              </li>
            </ul>
          </div>
        </div>{" "}
      </section>
    </>
  );
};

export default CookiePolicy;
