import BlogSidebar from "../BlogSidebar/Main";
import Breadcumb from "../../Breadcumb/Main";
import { Link } from "react-router-dom";
import blockchain1 from "../../../assets/images/blog/blockchain1.jpg";
const Blockchain = () => {
  return (
    <>
      <Breadcumb Title="Blog Details" Breadcumb="BLOG" />
      <section className="section-padding--bottom section-padding--top">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="blog-details__image">
                <img src={blockchain1} alt="" />
              </div>
              <div className="blog-card-one__meta">
                <div className="blog-card-one__date">
                  <i className="fa fa-calendar-alt" aria-hidden="true"></i>
                  Feb 11, 2023
                </div>
                <Link to="#" className="blog-card-one__category">
                  PHQTI
                </Link>
              </div>
              <h3 className="blog-card-one__title blog-details__title">
                Blockchain and why use PHQ Blockchain?
              </h3>
              <div className="blog-details__content">
                <p>
                  Blockchain technology is a revolutionary way of processing
                  transactions, verifying data, and ensuring secure transactions
                  of digital assets. It is made up of a distributed ledger of
                  records (or blocks) connected by cryptography. This technology
                  can speed up transaction clearing and drastically reduce fees
                  by eliminating intermediaries. It also ensures near-instant
                  block-adding times, as well as provides greater node
                  connectivity, rewards, and proof of staking authority.
                </p>
                <p>
                  With its decentralized nature and zero gas fees, PHQ
                  blockchain technology has the potential to reduce trust issues
                  associated with data inefficiency, privacy, and security.
                </p>
                <p>
                  At its core, blockchain technology relies on three main
                  components: a shared public ledger, a distributed consensus
                  system, and incentivized nodes. Each block in the chain holds
                  a timestamp, transaction data, and encrypted information. With
                  this data, a consensus system is established amongst the nodes
                  in the network, which is then used to securely verify and
                  approve or reject the transaction. Finally, the nodes that
                  agree to the consensus are rewarded for their work, thus
                  incentivizing them to continue to validate the transactions
                  and keep up the security of the network.
                </p>
                <p>
                  For further security, different blockchains employ different
                  consensus systems, such as proof of work or proof of stake.
                  Proof of work requires miners to solve complex mathematical
                  puzzles in order to prove their work, whereas proof of stake
                  allows holders to commit their stake and receive rewards. This
                  results in fast nodes adding times and allows transactions to
                  be completed quickly and securely.
                </p>
                <p>
                  Overall, blockchain technology is an efficient way to process
                  transactions while also providing a high level of security.
                  With its distributed consensus system, incentivized nodes, and
                  rewards, it is quickly becoming the new norm in many
                  industries ranging from finance to real estate, to healthcare,
                  etc. etc..
                </p>
                <p>
                  Furthermore, the data stored on the ledger is verifiable and
                  transparent, allowing users to track the source of data, who
                  was responsible for any changes, and any other events that
                  occurred during the life cycle of the data.
                </p>
                <p>
                  As a result, PHQ blockchain technology is invaluable when it
                  comes to reducing trust issues associated with data
                  inefficiency, privacy, and security. Additionally, blockchain
                  technology eliminates the need for third-party intermediaries,
                  as it runs on a decentralized network, reducing costs and
                  risks associated with intermediaries. Furthermore, PHQ
                  blockchain technology utilizes zero gas fees, which adds to
                  its cost-effectiveness and unique of its kind.
                </p>
                <p>Will PHQ blockchain disrupt the DLT world?</p>
              </div>
              <div className="blog-details__meta">
                <div className="blog-details__tags">
                  <span>Tags</span>
                  <Link to="#">BLOCKCHAIN</Link>
                  <Link to="#">GAS FEES</Link>
                  <Link to="#">USE-CASE</Link>
                  <Link to="#">DLT</Link>
                  <Link to="#">STORAGE</Link>
                </div>
                <ul className="blog-details__share">
                  <li>
                    <Link to="#">
                      <i className="fab fa-twitter"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="fab fa-facebook"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="fab fa-pinterest-p"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="fab fa-instagram"></i>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="author-one">
                <div className="author-one__image">
                  <img src="assets/images/blog/author-1-1.jpg" alt="" />
                </div>
                <div className="author-one__content">
                  <h3 className="author-one__title">PHQTI</h3>
                  <p className="author-one__text"></p>
                </div>
              </div>
              <div className="comments-one">
                <h3 className="comments-one__title">2 Comments</h3>
                <div className="comments-one__item">
                  <div className="comments-one__item__image">
                    <img src="assets/images/blog/comment-1-1.png" alt="" />
                  </div>
                  <div className="comments-one__item__content">
                    <h3 className="comments-one__item__title">Kevin Martin</h3>

                    <p className="comments-one__item__text">
                      It has survived not only five centuries, but also the leap
                      into electronic typesetting simply fee text aunchanged. It
                      was popularised in the sheets containing lorem ipsum is
                      simply free text.
                    </p>

                    <Link to="#" className="thm-btn comments-one__item__btn">
                      <span>Reply</span>
                    </Link>
                  </div>
                </div>
                <div className="comments-one__item">
                  <div className="comments-one__item__image">
                    <img src="assets/images/blog/comment-1-2.png" alt="" />
                  </div>
                  <div className="comments-one__item__content">
                    <h3 className="comments-one__item__title">Sarah Albert</h3>

                    <p className="comments-one__item__text">
                      It has survived not only five centuries, but also the leap
                      into electronic typesetting simply fee text aunchanged. It
                      was popularised in the sheets containing lorem ipsum is
                      simply free text.
                    </p>

                    <Link to="#" className="thm-btn comments-one__item__btn">
                      <span>Reply</span>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="comment-form">
                <h3 className="comment-form__title">Leave a Comment</h3>
                <form
                  action="assets/inc/sendemail.php"
                  className="contact-one__form contact-form-validated comment-form__form"
                >
                  <div className="row ">
                    <div className="col-lg-6 col-md-12">
                      <input type="text" placeholder="Your name" name="name" />
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <input
                        type="email"
                        placeholder="Email address"
                        name="email"
                      />
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <textarea
                        name="message"
                        placeholder="Write message"
                      ></textarea>
                    </div>
                    <div className="col-md-12">
                      <button
                        className="thm-btn contact-one__btn"
                        type="submit"
                      >
                        <span>Submit comment</span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="sidebar">
                <div className="sidebar__item sidebar__item--search">
                  <form action="#" className="sidebar__search">
                    <label for="search" className="sr-only">
                      search here
                    </label>
                    <input type="text" placeholder="Search Here" />
                    <button
                      type="submit"
                      aria-label="search submit"
                      className="thm-btn"
                    >
                      <span>
                        <i className="icon-magnifying-glass"></i>
                      </span>
                    </button>
                  </form>
                </div>
                <div className="sidebar__item sidebar__item--posts">
                  <h3 className="sidebar__title">Latest Posts</h3>
                  <ul className="sidebar__posts">
                    <li>
                      <div className="sidebar__posts__image">
                        <img src="assets/images/blog/blog-s-1-1.png" alt="" />
                      </div>
                      <div className="sidebar__posts__content">
                        <div className="sidebar__posts__date">
                          <i className="fa fa-comments"></i>2 Comments
                        </div>
                        <h3 className="sidebar__posts__title">
                          <Link to="/blog-details">
                            Tech experiences that connect us
                          </Link>
                        </h3>
                      </div>
                    </li>
                    <li>
                      <div className="sidebar__posts__image">
                        <img src="assets/images/blog/blog-s-1-2.png" alt="" />
                      </div>
                      <div className="sidebar__posts__content">
                        <div className="sidebar__posts__date">
                          <i className="fa fa-comments"></i>2 Comments
                        </div>
                        <h3 className="sidebar__posts__title">
                          <Link to="/blog-details">
                            Tech experiences that connect us
                          </Link>
                        </h3>
                      </div>
                    </li>
                    <li>
                      <div className="sidebar__posts__image">
                        <img src="assets/images/blog/blog-s-1-3.png" alt="" />
                      </div>
                      <div className="sidebar__posts__content">
                        <div className="sidebar__posts__date">
                          <i className="fa fa-comments"></i>2 Comments
                        </div>
                        <h3 className="sidebar__posts__title">
                          <Link to="/blog-details">
                            Tech experiences that connect us
                          </Link>
                        </h3>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="sidebar__item sidebar__item--category">
                  <h3 className="sidebar__title">Categories</h3>
                  <ul className="sidebar__category">
                    <li>
                      <Link to="/bass">Bass</Link>
                    </li>
                    <li>
                      <Link to="/cyber-security">Cyber Security</Link>
                    </li>
                    <li>
                      <Link to="/it-management">IT Management</Link>
                    </li>
                    <li>
                      <Link to="/qa-testing">QA & Testing</Link>
                    </li>
                    <li>
                      <Link to="/infrastructure-plan">Big Data</Link>
                    </li>
                    <li>
                      <Link to="/web3">Web3</Link>
                    </li>
                    <li>
                      <Link to="/data-analytics">Data Analytics</Link>
                    </li>
                  </ul>
                </div>
                <div className="sidebar__item sidebar__item--tags">
                  <h3 className="sidebar__title">Tags</h3>
                  <div className="sidebar__tags">
                    <Link to="#">BLOCKCHAIN</Link>
                    <Link to="#">GAS FEES</Link>
                    <Link to="#">USE-CASE</Link>
                    <Link to="#">DLT</Link>
                    <Link to="#">STORAGE</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Blockchain;
