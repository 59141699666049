import AT from "../../../assets/images/At.jpg";
import Breadcumb from "../../Breadcumb/Main";
import CDBCI from "../../../assets/images/CDBCI.jpg";
import CharityI from "../../../assets/images/CharityI.jpg";
import DI from "../../../assets/images/SecureD.jpg";
import FNT from "../../../assets/images/Fnt.jpg";
import HRFIEDI from "../../../assets/images/HRFIEDI.jpg";
import { Link } from "react-router-dom";
import MoneyI from "../../../assets/images/MoneyI.jpg";
import NFTBanner from "../../../assets/images/NftBanner.jpg";
import NFTI from "../../../assets/images/NftI.jpg";
import OwlCarousel from "react-owl-carousel";
import Sec4 from "../../../assets/images/Sec4.jpg";
import SecureI from "../../../assets/images/SecureI.jpg";
import TC from "../../../assets/images/TokenI.jpg";
import W from "../../../assets/images/W.jpg";

const NFTMarketplace = () => {
  const options = {
    loop: true,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    smartSpeed: 700,
    items: 1,
    margin: 0,
    nav: false,
    dots: false,
    responsive: {
      0: {
        items: 1,
        margin: 0,
      },
      576: {
        items: 2,
        margin: 30,
      },
      992: {
        items: 3,
        margin: 30,
      },
    },
  };
  return (
    <>
      <Breadcumb Title="NFT Marketplace" Breadcumb="PROJECT" />
      <section className="project-details section-padding--bottom section-padding--top">
        <div className="container">
          <div className="project-details__image">
            <img src={NFTBanner} alt="" />
          </div>
          <div className="project-details__info">
            <div className="project-details__info__item">
              <h4 className="project-details__info__title">Client:</h4>
              <p className="project-details__info__text">PHQTI</p>
            </div>

            <div className="project-details__info__item">
              <h4 className="project-details__info__title">Category:</h4>
              <p className="project-details__info__text">
                <Link to="#">PHQNFT</Link> <Link to="#"></Link>
              </p>
            </div>
            <div className="project-details__info__item">
              <h4 className="project-details__info__title">Date:</h4>
              <p className="project-details__info__text">2024</p>
            </div>
            <div className="project-details__info__item">
              <h4 className="project-details__info__title">Value:</h4>
              <p className="project-details__info__text">USD 180,000</p>
            </div>
            <div className="project-details__info__item">
              <div className="project-details__info__social">
                <Link to="#">
                  <i className="fab fa-twitter"></i>
                </Link>
                <Link to="#">
                  <i className="fab fa-facebook"></i>
                </Link>
                <Link to="#">
                  <i className="fab fa-instagram"></i>
                </Link>
              </div>
            </div>
          </div>
          <h3 className="project-details__title">NFT Marketplace (NFTM)</h3>
          <div className="project-details__content">
            <p>
              Non-Fungible Token or NFT marketplace is a marketplace that
              functions as a public Blockchain platform. However, this platform
              is gaining traction and driving developers and businesses to
              construct a marketplace, despite being in its nascent
              stage. Online marketplaces for digital assets are the recent talk
              of the fintech town. But, of course, you have to build your own
              marketplace if you are planning to set foot in the digital market.
              The words cryptocurrency trading and blockchain technology are no
              longer unknown, and many people trade goods on several
              marketplaces. It is a platform that makes it simple to store and
              sell NFTs. These tokens are generally available for purchase or
              auction at a set price. To use an NFT marketplace, you will need
              a crypto wallet to store and trade your best NFT tokens. Users
              have to create an account, upload digital artwork, and sell their
              work on the marketplace. In general, specialized marketplaces are
              more popular than conventional ones because they include
              everything a client would require specialized marketplaces’
              expertise in promoting online artworks and concentrating on
              specific target audiences.{" "}
            </p>
            <br />
            <h4 className="project-details__title" style={{ fontSize: "30px" }}>
              The functionality of the PHQNFT Marketplace
            </h4>

            <p class="">
              Before we understand how to build an NFT marketplace, it is
              essential to know how an NFT marketplace functions from a client’s
              perspective. PHQNFT Marketplace is a Non-Fungible Tokens exchange
              platform. You may open your store on PHQNFT Marketplace in a
              matter of minutes. PHQNFT Marketplace provides options for
              minting, selling, auctioning, and buying. Users must first log in
              by creating an account on the platform. After creating their
              account, they can download a digital wallet to store their
              NFTs. The users can list their assets by uploading goods to
              exhibit their effort.
            </p>

            <p>
              The next step is to put the products for sale on the market. Users
              have the option of bidding on a fixed price or an auction. When a
              user sells an item, a transaction is produced in the user’s wallet
              to start a private transaction contract on the PHQ blockchain. The
              platform will need to conciliate the data before adding the NFT to
              the list. The NFT marketplace deploys a blockchain internal
              contract, a type of transaction protocol already built into the
              blockchain architecture. These protocols control the connections
              between the supplier and the buyer.
            </p>

            <p>
              Furthermore, these internal protocols include NFT-specific
              identifying data. As a result, buying and selling NFTs becomes
              more accessible and convenient.
            </p>
            <p
              class=""
              style={{
                marginTop: "30px",
                marginBottom: "15px",
                color: "black",
                fontSize: "20px",
              }}
            >
              BENEFITS OF PHQNFT MARKETPLACE:
            </p>

            <ul className="project-details__list">
              <li>
                <i className="fa fa-check-circle"></i>
                Easy seller onboarding
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                KYC of all buyers, sellers, and NFT creators
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                Easy integration of wallet for storing NFTs
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                Artists can sell digital art and their hand works. E-learning
                services, NFT games, etc.
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                Virtual swapping of assets
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                Creating real estate NFTs possible
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                NFTs of famous monuments/products/antiques/signatures/autographs
                etc
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                Sellers can sell via fixed pricing or go through bidding process
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                Absolutely zero gas fee for any kind of transaction passing
                through the PHQ blockchain
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                Complete transparency on the PHQ blockchain regarding block Hash
                and transaction ID
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                Easy to liquidate your NFTs
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                High Level of Security
              </li>
              <li>
                <i className="fa fa-check-circle"></i>
                Easy and fast transfers
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="project-details section-padding--bottom footer-widget__newsletter mc-form">
        <center>
          <a href="#" target="_blank" className="thm-btn about-four__btn">
            <span>Whitepaper</span>
          </a>
        </center>
      </section>
      <div className="project-nav">
        <div className="container">
          <div className="project-nav__inner">
            <Link to="/charity">
              <i className="icon-left-arrow"></i>
              Previous
            </Link>
            <Link to="/secured-email-system">
              Next
              <i className="icon-right-arrow"></i>
            </Link>
          </div>
        </div>
      </div>

      <section className="section-padding--top section-padding--bottom">
        <div className="container">
          <div className="section-title text-center">
            <p className="section-title__text">Other PHQ Products</p>
            <h2 className="section-title__title">
              Check What We Have <br /> Developed?
            </h2>
          </div>

          <OwlCarousel className="thm-owl__carousel" {...options}>
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={SecureI} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text">PHQ.SEMS.V1.2023</p>
                    <h3 className="project-card-one__title">
                      <Link to="/secured-email-system">
                        Secured Email System
                      </Link>
                    </h3>
                    <Link
                      to="/secured-email-system"
                      className="project-card-one__more"
                    >
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={TC} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text">PHQ.TCT.V1.2023</p>
                    <h3 className="project-card-one__title">
                      <Link to="/token_creation_tool">Token Creation Tool</Link>
                    </h3>
                    <Link
                      to="/token_creation_tool"
                      className="project-card-one__more"
                    >
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={FNT} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text">PHQ.FNT.V1.2023</p>
                    <h3 className="project-card-one__title">
                      <Link to="/fntasy">Fntasy Gaming</Link>
                    </h3>
                    <Link to="/fntasy" className="project-card-one__more">
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={DI} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text">PHQ.SDI.V1.2023</p>
                    <h3 className="project-card-one__title">
                      <Link to="/secure-digital-identity">
                        Secured Digital Identity
                      </Link>
                    </h3>
                    <Link
                      to="/secure-digital-identity"
                      className="project-card-one__more"
                    >
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={AT} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text">PHQ.ATS.V1.2023</p>
                    <h3 className="project-card-one__title">
                      <Link to="/asset_tokenization">
                        Asset Tokenization System
                      </Link>
                    </h3>
                    <Link
                      to="/asset_tokenization"
                      className="project-card-one__more"
                    >
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={HRFIEDI} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text"> PHQ.HRF.V1.2023</p>
                    <h3 className="project-card-one__title">
                      <Link to="/hrfied">Hrfied</Link>
                    </h3>
                    <Link to="/hrfied" className="project-card-one__more">
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={CDBCI} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text"> PHQ.CDBC.V1.2023</p>
                    <h3 className="project-card-one__title">
                      <Link to="/central_bank_digital_currency">
                        Central Bank Digital Currency
                      </Link>
                    </h3>
                    <Link
                      to="/central_bank_digital_currency"
                      className="project-card-one__more"
                    >
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={W} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text">
                      {" "}
                      PHQ.WALLET.V1.2023
                    </p>
                    <h3 className="project-card-one__title">
                      <Link to="/cryptocurrency-wallet">
                        Cryptocurrency Wallet
                      </Link>
                    </h3>
                    <Link
                      to="/cryptocurrency-wallet"
                      className="project-card-one__more"
                    >
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={MoneyI} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text"> PHQ.MTRN.V1.2023</p>
                    <h3 className="project-card-one__title">
                      <Link to="/money-transfer">Money Transfer</Link>
                    </h3>
                    <Link
                      to="/money-transfer"
                      className="project-card-one__more"
                    >
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src={CharityI} alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text">
                      {" "}
                      PHQ.Charity.V1.2023
                    </p>
                    <h3 className="project-card-one__title">
                      <Link to="/charity">Charity</Link>
                    </h3>
                    <Link to="/charity" className="project-card-one__more">
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="item">
							<div className="project-card-one">
								<div className="project-card-one__image">
									<img src={NFTI} alt="" />
								</div>
								<div className="project-card-one__content">
									<div className="project-card-one__content__inner">
										<p className="project-card-one__text"> PHQ.NFT.V1.2023</p>
										<h3 className="project-card-one__title"><Link to="/nft-market-place">
NFT Marketplace</Link></h3>
										<Link to="/nft-market-place" className="project-card-one__more">
											<i className="fa fa-angle-right"></i>
										</Link>
									</div>
								</div>
							</div>
						</div> */}
          </OwlCarousel>
        </div>
      </section>
    </>
  );
};

export default NFTMarketplace;
