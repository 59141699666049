import Blockchain from "../../../assets/Docs/Blockchain.pdf";
import Breadcumb from "../../Breadcumb/Main";
import D2 from "../../../assets/images/services/D2.jpg";
import D3 from "../../../assets/images/services/D3.jpg";
import { Link } from "react-router-dom";
import ServiceSidebar from "../ServiceSidebar/Main";
import WP from "../../../assets/images/Wp.gif";
import { useState } from "react";
const DataAnalytics = () => {
  const [clicked, setClicked] = useState(0);
  const faqs = [
    {
      question: "What are data Analytics Techniques?",
      answer: (
        <div>
          <p>
            There are several different analytical methods and techniques data
            analysts can use to process data and extract information. Some of
            the most popular methods we use are:
          </p>
          <br />
          <p>
            Regression analysis entails analyzing the relationship between
            dependent variables to determine how a change in one may affect the
            change in another.
          </p>
          <br />
          <p>
            Factor analysis entails taking a large data set and shrinking it
            into a smaller data set. The goal of this maneuver is to attempt to
            discover hidden trends that would otherwise have been more difficult
            to see.
          </p>
          <br />
          <p>
            Cohort analysis is the process of breaking a data set into groups of
            similar data, often broken into a customer demographic. This allows
            data analysts and other users of data analytics to further dive into
            the numbers relating to a specific subset of data.
          </p>
          <br />
          <p>
            Monte Carlo simulations model the probability of different outcomes
            happening. Often used for risk mitigation and loss prevention, these
            simulations incorporate multiple values and variables and often have
            greater forecasting capabilities than other data analytics
            approaches.
          </p>
          <br />
          <p>
            Time series analysis tracks data over time and solidifies the
            relationship between the value of a data point and the occurrence of
            the data point. This data analysis technique is usually used to spot
            cyclical trends or to project financial forecasts.
          </p>
        </div>
      ),
    },
    {
      question: "What kind of tools are used in data analytics?",
      answer: (
        <div>
          <p>
            In addition to a broad range of mathematical and statistical
            approaches to crunching numbers, data analytics has rapidly evolved
            in technological capabilities. Today, our data analysts have a broad
            range of software tools to help acquire data, store information,
            process data, and report findings.
          </p>
          <br />
          <p>
            Data analytics has always had loose ties to spreadsheets and
            Microsoft Excel. Now, data analysts also often interact with raw
            programming languages to transform and manipulate
            databases. Open-source languages such as Python are often utilized.
            More specific tools for data analytics like R can be used for
            statistical analysis or graphical modelling.
          </p>
          <br />
          <p>
            Tableau and Power BI are data visualization and analysis tools to
            compile information, perform data analytics, and distribute results
            via dashboards and reports.
          </p>
        </div>
      ),
    },
    {
      question: "Who Is Using Data Analytics?",
      answer: (
        <div>
          <p>
            Data analytics has been adopted by several sectors, such as the
            travel and hospitality industry, where turnarounds can be quick.
            This industry can collect customer data and figure out where the
            problems, if any, lie and how to fix them. Healthcare is another
            sector that combines the use of high volumes of structured and
            unstructured data and data analytics can help in making quick
            decisions. Similarly, the retail industry uses copious amounts of
            data to meet the ever-changing demands of shoppers
          </p>
        </div>
      ),
    },
    {
      question: "What are the common applications of Data Analytics?",
      answer: (
        <div>
          <p>
            Transportation. One can use data analytics to solve traffic
            congestion and improve travel by improving transportation systems
            and intelligence.
          </p>

          <p>Education</p>
          <p>Internet web search results</p>
          <p>Marketing and digital advertising</p>
          <p>Logistics and delivery</p>
          <p>Security</p>
          <p>Fraud detection</p>
        </div>
      ),
    },
  ];
  return (
    <>
      <Breadcumb Title="Data Analytics" Breadcumb="SERVICES" />
      <section className="section-padding--bottom section-padding--top service-details--page">
        <div className="container">
          <div className="row ">
            <div className="col-lg-8">
              <div className="service-details__image">
                <img src={D2} alt="" />
              </div>
              <h3 className="service-details__title">Data Analytics</h3>
              <div className="service-details__content">
                <p>
                  Data analytics is the science of analyzing raw data to make
                  conclusions about that information. Many of the techniques and
                  processes of data analytics have been automated into
                  mechanical processes and algorithms that work over raw data
                  for human consumption. Data analytics help a business optimize
                  its performance, perform more efficiently, maximize profit, or
                  make more strategically-guided decisions. Data analytics
                  relies on a variety of software tools ranging from
                  spreadsheets, data visualization, reporting tools, data mining
                  programs, or open- source languages for the greatest data
                  manipulation.
                </p>
                <h3 className="service-details__title">
                  Understanding Data Analytics
                </h3>
                <p>
                  Data analytics is a broad term that encompasses many diverse
                  types of data analysis. Any type of information can be
                  subjected to data analytics techniques to get insight that can
                  be used to improve things. Data analytics techniques can
                  reveal trends and metrics that would otherwise be lost in the
                  mass of information. This information can then be used to
                  optimize processes to increase the overall efficiency of a
                  business or system. Data analytics can do much more than point
                  out bottlenecks in production. Gaming companies use data
                  analytics to set reward schedules for players that keep the
                  majority of players active in the game. Content companies use
                  many of the same data analytics to keep you clicking,
                  watching, or re-organizing content to get another view or
                  another click.
                </p>
              </div>
              <div className="row gutter-y-30 service-details__box-wrapper">
                <div className="col-md-6 col-sm-12">
                  <div className="service-details__box">
                    <i className="service-details__box__icon icon-consulting"></i>
                    <div className="service-details__box__content">
                      <h3 className="service-details__box__title">
                        <a href={Blockchain} target="_blank">
                          Blockchain{" "}
                        </a>
                      </h3>
                      <p className="service-details__box__text">
                        Faster Solutions
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <div className="service-details__box">
                    <i className="service-details__box__icon icon-chip"></i>
                    <div className="service-details__box__content">
                      <h3 className="service-details__box__title">
                        <Link to="/all-services">IT Specialist</Link>
                      </h3>
                      <p className="service-details__box__text">
                        Smarter Solutions
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row gutter-y-30">
                <div className="col-md-6 col-sm-12">
                  <img src={D3} className="service-details__sub-image" alt="" />
                </div>
                <div className="col-md-6 col-sm-12">
                  <div className="service-details__caption">
                    <h3 className="service-details__caption__title">
                      Our Benefits
                    </h3>
                    <p className="service-details__caption__text">
                      Our techforce is confident in developing any kind if data
                      analytics project.
                    </p>
                    <ul className="service-details__caption__list">
                      <li>
                        <i className="fa fa-check-circle"></i>
                        Descriptive / diagnostic analytics
                      </li>
                      <li>
                        <i className="fa fa-check-circle"></i>
                        Predictive analytics
                      </li>
                      <li>
                        <i className="fa fa-check-circle"></i>
                        Prescriptive analytics
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div
                    className="accrodion-grp service-details__accrodion"
                    data-grp-name="service-details__accrodion-1"
                  >
                    {faqs.map((item, index) => (
                      <div
                        className={`accrodion wow fadeInUp ${
                          index === clicked && "active"
                        }`}
                        key={index}
                        data-wow-delay="0ms"
                      >
                        <div className="accrodion-title">
                          <h4 onClick={() => setClicked(index)}>
                            {item.question}
                            <span className="accrodion-icon"></span>
                          </h4>
                        </div>
                        {index === clicked && (
                          <div className="accrodion-content">
                            <div className="inner">
                              <p>{item.answer}</p>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 sidebar-column">
              <div className="sidebar">
                <div className="sidebar__item sidebar__item--category">
                  <h3 className="sidebar__title">Categories</h3>
                  <ul className="sidebar__category">
                    <li>
                      <Link to="/bass">Bass</Link>
                    </li>
                    <li>
                      <Link to="/artificial_intelligence">AI</Link>
                    </li>
                    <li>
                      <Link to="/cyber-security">Cyber Security</Link>
                    </li>
                    <li>
                      <Link to="/it-management">IT Management</Link>
                    </li>
                    <li>
                      <Link to="/qa-testing">QA & Testing</Link>
                    </li>
                    <li>
                      <Link to="/big-data">Big Data</Link>
                    </li>
                    <li>
                      <Link to="/it-consultant">Web3</Link>
                    </li>
                    {/* <li><Link to="/data-analytics">Data Analytics</Link></li> */}
                  </ul>
                </div>
                <div className="sidebar__item sidebar__item--cta">
                  <div className="sidebar__cta">
                    <a
                      href="https://api.whatsapp.com/send?phone=+919873221469&text=Hi,"
                      target="_blank"
                      className=""
                      style={{ minHeight: "12vh" }}
                    >
                      <img
                        src={WP}
                        style={{ height: "10vh", marginRight: "10px" }}
                      />
                      {/* <i className="sidebar__cta__icon icon-phone-ringing"></i> */}
                    </a>
                    <h3 className="sidebar__cta__title">
                      Have Tech Problems? Contact Us
                    </h3>
                    <p className="sidebar__cta__text">
                      Whatsapp Anytime <br /> +91 9873221469{" "}
                    </p>
                  </div>
                </div>
                <div className="sidebar__item sidebar__item--btn">
                  <Link to="#" className="thm-btn sidebar__btn dark__button">
                    <span>Download our flyers</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DataAnalytics;
