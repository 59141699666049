import Blockchain from "../../../assets/Docs/Blockchain.pdf";
import Breadcumb from "../../Breadcumb/Main";
import It2 from "../../../assets/images/services/It2.jpg";
import It3 from "../../../assets/images/services/It3.jpg";
import { Link } from "react-router-dom";
import ServiceSidebar from "../ServiceSidebar/Main";
import WP from "../../../assets/images/Wp.gif";
import { useState } from "react";
const ITManagement = () => {
  const [clicked, setClicked] = useState(0);
  const faqs = [
    {
      question: "What is the role of IT management in a company?",
      answer: (
        <div>
          <p>
            IT management refers to the monitoring and administration of an
            organization's information technology systems: hardware, software
            and networks. IT management focuses on how to make information
            systems operate efficiently with maximum usage.
          </p>
        </div>
      ),
    },
    {
      question: "What are the roles and responsibilities of an IT manager?",
      answer: (
        <div>
          <p>
            Conducting routine evaluations of network and data security.
            Locating and seizing chances to enhance and update software and
            systems.
          </p>
          <p>
            Creating and implementing the organization’s IT policy and best
            practice manuals.
          </p>
          <p>
            Creating workshops and training programs for staff. Performing
            routine system audits.
          </p>
          <p>
            Running routine system operations reports and sharing them with top
            employees.
          </p>
          <p>
            Organizing and establishing deadlines for major IT projects such as
            system updates, migrations, upgrades, and outages.
          </p>
          <p>
            Managing and reporting on the IT budget’s allocation. Advising the
            IT staff.
          </p>
          <p>Looking for opportunities to train and build teamwork skills.</p>
        </div>
      ),
    },
    {
      question: "What are the characteristics of effective IT Management?",
      answer: (
        <div>
          <p>
            An IT management responsibility always includes oversight of current
            IT projects and operations. Effective IT management requires
            employing technology in novel ways to help businesses adapt to rapid
            change. Here are some of the key characteristics of effective IT
            management:
          </p>
          <br />
          <p>Data analytics</p>
          <p>Cloud computing</p>
          <p>Artificial intelligence</p>
          <p>Internet of things</p>
        </div>
      ),
    },
  ];
  return (
    <>
      <Breadcumb Title="IT Management" Breadcumb="SERVICES" />
      <section className="section-padding--bottom section-padding--top service-details--page">
        <div className="container">
          <div className="row ">
            <div className="col-lg-8">
              <div className="service-details__image">
                <img src={It2} alt="" />
              </div>
              <h3 className="service-details__title">IT management  (ITM)</h3>
              <div className="service-details__content">
                <p>
                  Information technology management or IT Management is the
                  discipline whereby all of the information technology resources
                  of a firm are managed in accordance with its needs and
                  priorities. Managing the responsibility within a company
                  entails many of the basic management functions,
                  like budgeting, staffing, change management, and organizing
                  and controlling, along with other aspects that are unique to
                  technology, like software design, network planning, tech
                  support etc
                </p>
                <h3 className="service-details__title">Purpose of ITM</h3>
                <p>
                  A primary focus of ITM is the value creation made possible by
                  technology. This requires the alignment of technology
                  and business strategies. While value creation for an
                  organization involves a network of relationships between
                  internal and external environments, technology plays an
                  important role in improving the overall value chain of an
                  organization. However, this increase requires business and
                  technology management to work as a creative, synergistic, and
                  collaborative team instead of a purely mechanistic span of
                  control.
                </p>
              </div>
              <div className="row gutter-y-30 service-details__box-wrapper">
                <div className="col-md-6 col-sm-12">
                  <div className="service-details__box">
                    <i className="service-details__box__icon icon-consulting"></i>
                    <div className="service-details__box__content">
                      <h3 className="service-details__box__title">
                        <a href={Blockchain} target="_blank">
                          Blockchain{" "}
                        </a>
                      </h3>
                      <p className="service-details__box__text">
                        Faster Solutions
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <div className="service-details__box">
                    <i className="service-details__box__icon icon-chip"></i>
                    <div className="service-details__box__content">
                      <h3 className="service-details__box__title">
                        <Link to="/all-services">IT Specialist</Link>
                      </h3>
                      <p className="service-details__box__text">
                        Smarter Solutions
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row gutter-y-30">
                <div className="col-md-6 col-sm-12">
                  <img
                    src={It3}
                    className="service-details__sub-image"
                    alt=""
                  />
                </div>
                <div className="col-md-6 col-sm-12">
                  <div className="service-details__caption">
                    <h3 className="service-details__caption__title">
                      Our Benefits
                    </h3>
                    <p className="service-details__caption__text">
                      We @PHQ execute critical IT management projects.
                    </p>
                    <ul className="service-details__caption__list">
                      <li>
                        <i className="fa fa-check-circle"></i>
                        Business &amp; IT alignment
                      </li>
                      <li>
                        <i className="fa fa-check-circle"></i>
                        IT governance &amp; financial management
                      </li>
                      <li>
                        <i className="fa fa-check-circle"></i>
                        IT service, infrastructure &amp; configuration
                        management
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div
                    className="accrodion-grp service-details__accrodion"
                    data-grp-name="service-details__accrodion-1"
                  >
                    {faqs.map((item, index) => (
                      <div
                        className={`accrodion wow fadeInUp ${
                          index === clicked && "active"
                        }`}
                        key={index}
                        data-wow-delay="0ms"
                      >
                        <div className="accrodion-title">
                          <h4 onClick={() => setClicked(index)}>
                            {item.question}
                            <span className="accrodion-icon"></span>
                          </h4>
                        </div>
                        {index === clicked && (
                          <div className="accrodion-content">
                            <div className="inner">
                              <p>{item.answer}</p>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 sidebar-column">
              <div className="sidebar">
                <div className="sidebar__item sidebar__item--category">
                  <h3 className="sidebar__title">Categories</h3>
                  <ul className="sidebar__category">
                    <li>
                      <Link to="/bass">Bass</Link>
                    </li>
                    <li>
                      <Link to="/artificial_intelligence">AI</Link>
                    </li>
                    <li>
                      <Link to="/cyber-security">Cyber Security</Link>
                    </li>
                    {/* <li><Link to="/it-management">IT Management</Link></li> */}
                    <li>
                      <Link to="/qa-testing">QA & Testing</Link>
                    </li>
                    <li>
                      <Link to="/big-data">Big Data</Link>
                    </li>
                    <li>
                      <Link to="/web3">Web3</Link>
                    </li>
                    <li>
                      <Link to="/data-analytics">Data Analytics</Link>
                    </li>
                  </ul>
                </div>
                <div className="sidebar__item sidebar__item--cta">
                  <div className="sidebar__cta">
                    <a
                      href="https://api.whatsapp.com/send?phone=+919873221469&text=Hi,"
                      target="_blank"
                      className=""
                      style={{ minHeight: "12vh" }}
                    >
                      <img
                        src={WP}
                        style={{ height: "10vh", marginRight: "10px" }}
                      />
                      {/* <i className="sidebar__cta__icon icon-phone-ringing"></i> */}
                    </a>
                    <h3 className="sidebar__cta__title">
                      Have Tech Problems? Contact Us
                    </h3>
                    <p className="sidebar__cta__text">
                      Whatsapp Anytime <br /> +91 9873221469{" "}
                    </p>
                  </div>
                </div>
                <div className="sidebar__item sidebar__item--btn">
                  <Link to="#" className="thm-btn sidebar__btn dark__button">
                    <span>Download our flyers</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ITManagement;
